import axios from "axios";
import { handleError } from "utils";
import { toast } from "react-toastify";

import { setLeaderboard } from "components/feature/leaderboard/actions";
import { setContest } from "components/feature/contest/actions";

export const SET_TOOLBAR = "toolbar:SET_TOOLBAR";

export const setToolbar = (payload) => (dispatch) => {
  dispatch({
    type: SET_TOOLBAR,
    payload,
  });
};

const buildPickemList = async (pickemList) => {
  const newPickemList = [];

  for (let pickem of pickemList) {
    const pickemRes = await axios.get(`/pickems/${pickem?.id}`);
    const pickemData = pickemRes?.data?.data;

    let sportMatchRes;
    let sportMatch;

    // if (pickemData?.data?.id) {
    //   sportMatchRes = await axios.get(`sport-match/${pickemData?.data?.id}`);
    // } else {
    //   sportMatchRes = await axios.get(
    //     `football/matches/${pickemData?.footballMatch?.id}`
    //   );
    // }

    if (pickemData?.data?.id) {
      sportMatchRes = await axios.get(
        `sport-match/${pickemData?.data?.sport?.slug ?? "custom-sport"}/${
          pickemData?.data?.id
        }`
      );
    } else {
      sportMatchRes = await axios.get(
        `football/matches/${pickemData?.footballMatch?.id}`
      );
    }

    sportMatch = sportMatchRes?.data?.data;

    pickemData.sportMatch = sportMatch;

    pickemData.usersBets = pickem?.bets;
    pickemData.activeBet = pickem?.bets?.length && pickem?.bets[0];

    const newPickem = {
      ...pickemData,
      sportMatch,
    };
    newPickemList.push(newPickem);

    if (pickem?.bets?.length > 1) {
      for (let bet of pickem?.bets) {
        if (bet?.betId !== pickemData?.activeBet?.betId) {
          const extraPickem = {
            ...newPickem,
            activeBet: bet,
          };

          newPickemList.push(extraPickem);
        }
      }
    }
  }

  return newPickemList.reverse();
};

export const getToolbar = () => async (dispatch, getState) => {
  try {
    let populatedActivePickemList = [];

    // const activePickemListRes = await axios.get(
    //   "/pickems-dates?include_status=Approved,In_play,Completed"
    // );

    const activePickemListRes = await axios.get(
      `/pickems-most-recent?sub_days=${
        process.env.REACT_APP_BETBAR_PICKEM_DAYS ?? "10"
      }&include_status=Approved,Completed,In_play`
    );

    const activePickemList = activePickemListRes.data?.data;
    const latestPickem = activePickemList[0];

    // const filteredPickems = activePickemList?.filter(
    //   (obj) => obj.start.substr(0, 10) === latestPickem?.start.substr(0, 10)
    // );

    const filteredPickems = activePickemList;

    let isPickemEmpty = false;

    if (activePickemList?.length) {
      populatedActivePickemList = await buildPickemList(filteredPickems);
    } else {
      isPickemEmpty = true;
    }

    const activePickem = populatedActivePickemList[0];

    const activeBet = populatedActivePickemList[0]?.activeBet;

    const hasPickemsAllowingMultipleBets = populatedActivePickemList.some(
      (obj) => obj.maxBetsPerUser
    );

    dispatch({
      type: SET_TOOLBAR,
      payload: {
        isPickemEmpty,
        hadBet: activeBet ? true : false,
        activeBet,
        activePickem,
        isBetsOpen: false,
        isActivePickemInPlay: activePickem?.status === "IN_PLAY",
        isActivePickemCompleted: activePickem?.status === "COMPLETED",
        isActivePickemApproved: activePickem?.status === "APPROVED",
        activePickemList: populatedActivePickemList,
      },
    });
  } catch ({ response }) {
    handleError(response);
  }
};

export const getMyBet = (activeUserBet, isShowingMyBet) => async (
  dispatch,
  getState
) => {
  dispatch(
    setLeaderboard({
      activeUserBet: isShowingMyBet ? activeUserBet : undefined,
      isShowingMyBet,
    })
  );
};
