import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { rgba } from "polished";
import { DateTime, ClubName, MatIcon } from "fiver-react-ui-components";
import { createImageCdnLink } from "utils";
import FootballMatchTimer from "./FootballMatchTimer";
import FootballMatchScoreUpdate from "./FootballMatchScoreUpdate";

const StyledWrapper = styled.div`
  width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledClub = styled.div`
  flex: 0 0 30px;
  max-width: 30px;
  width: 30px;
  height: 3rem;
  padding: 0 0 2px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
`;

const StyledClubLogo = styled.img`
  height: 26px;
  margin-bottom: 2px;
`;

const StyledClubName = styled(ClubName)`
  display: block;
  font-size: 0.75rem;
  line-height: 1.25;
  white-space: nowrap;
  overflow: hidden;
  max-width: fit-content;
`;

const StyledScore = styled.div`
  position: relative;
  flex: 0 0 56px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
`;

const StyledScoreValue = styled.div`
  font-size: 1.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledScoreValueBlock = styled.div`
  text-align: ${(p) => (p.isRight ? "left" : "right")};
`;

const StyledScoreTime = styled.div`
  font-size: 0.8rem;
  text-align: center;
  color: ${(p) => p.theme.colorLabel};
`;

const StyledScoreValueColon = styled.span`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 14px;
  margin: 0 ${(p) => (p.isCustomSeparator ? 0 : "3px")};
`;

const StyledColorCircle = styled.circle`
  fill: ${(p) => p.theme.colorText};
`;

const StyledVsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const StyledVs = styled.div`
  font-size: 1.25rem;
  color: ${(p) => p.theme.colorLabel};
  margin-bottom: 2px;
`;

const StyledVsLock = styled(MatIcon)`
  color: ${(p) => p.theme.colorLabel};
  font-size: 18px;
  margin-bottom: 4px;
`;

const StyledVsDate = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  color: ${(p) => p.theme.colorLabel};
  font-size: 0.575rem;
  margin-bottom: 2px;
  text-align: center;
  max-width: 55px;
`;

const Wrapper = ({
  hasGoalScored,
  footballMatch,
  isLocked,
  isApproved,
  isInPlay,
  isCompleted,
  onAnimationEnd,
}) => {
  // UNCOMMENT TO TEST MATCH TIMER
  // isInPlay = true;
  // isCompleted = false;
  // footballMatch.period = "SecondHalf";
  // footballMatch.homeScore = 0;
  // footballMatch.awayScore = 0;
  // footballMatch.matchTime = 2;

  const { activePickemList, activePickem } = useSelector((s) => s.toolbar);

  const hasMultipleBetOption = activePickemList?.length > 1;

  return (
    <StyledWrapper>
      <StyledClub>
        <StyledClubLogo
          src={
            footballMatch?.homeTeam?.logo ||
            createImageCdnLink(process.env.REACT_APP_IMAGE_BLANK_LOGO)
          }
        />
        <StyledClubName
          isLetters
          isNormalLettersStyle={
            process.env.REACT_APP_IS_NORMAL_CLUB_LETTERS_STYLE === "true"
          }
        >
          {footballMatch?.homeTeam?.name}
        </StyledClubName>
      </StyledClub>
      <StyledScore>
        {/* <FootballMatchScoreUpdate
          isActive={hasGoalScored}
          onAnimationEnd={onAnimationEnd}
        >
          GOAL
        </FootballMatchScoreUpdate> */}
        {isApproved && (
          <StyledVsWrapper>
            <StyledVs>vs</StyledVs>
            {hasMultipleBetOption && (
              <StyledVsDate>
                <DateTime
                  isUTC={process.env.REACT_APP_IS_UTC_DATES}
                  format={
                    activePickem?.start?.includes("00:00")
                      ? "ddd ha"
                      : "ddd h:mma"
                  }
                >
                  {activePickem?.start}
                </DateTime>
              </StyledVsDate>
            )}
          </StyledVsWrapper>
        )}
        {!isApproved && (
          <>
            <StyledScoreValue>
              <StyledScoreValueBlock>
                {footballMatch?.homeScore}
              </StyledScoreValueBlock>
              <StyledScoreValueColon
                isCustomSeparator={process.env.REACT_APP_TEXT_SCORE_SEPARATOR}
              >
                {process.env.REACT_APP_TEXT_SCORE_SEPARATOR || (
                  <>
                    <svg
                      width="4"
                      height="4"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <StyledColorCircle cx="2" cy="2" r="2" />
                    </svg>
                    <svg
                      width="4"
                      height="4"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <StyledColorCircle cx="2" cy="2" r="2" />
                    </svg>
                  </>
                )}
              </StyledScoreValueColon>
              <StyledScoreValueBlock isRight>
                {footballMatch?.awayScore}
              </StyledScoreValueBlock>
            </StyledScoreValue>
            {isInPlay || isCompleted ? (
              <StyledScoreTime>
                {footballMatch?.period === "FirstHalf" ||
                footballMatch?.period === "SecondHalf" ? (
                  <FootballMatchTimer matchTime={footballMatch?.matchTime} />
                ) : footballMatch?.period === "HalfTime" ? (
                  "HT"
                ) : footballMatch?.period === "FullTime" || isCompleted ? (
                  "FT"
                ) : null}
              </StyledScoreTime>
            ) : null}
          </>
        )}
      </StyledScore>
      <StyledClub>
        <StyledClubLogo
          src={
            footballMatch?.awayTeam?.logo ||
            createImageCdnLink(process.env.REACT_APP_IMAGE_BLANK_LOGO)
          }
        />
        <StyledClubName
          isLetters
          isNormalLettersStyle={
            process.env.REACT_APP_IS_NORMAL_CLUB_LETTERS_STYLE === "true"
          }
        >
          {footballMatch?.awayTeam?.name}
        </StyledClubName>
      </StyledClub>
    </StyledWrapper>
  );
};

export default Wrapper;
