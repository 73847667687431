import { SET_TOOLBAR } from "./actions";

const initialState = {
  isBetsOpen: undefined,
  activePickem: undefined,
  isActivePickemInPlay: undefined,
  isActivePickemApproved: undefined,
  isActivePickemCompleted: undefined,
  isContestEmpty: undefined,
  activeBet: undefined,
  hasLeaderboard: false,
  hasGoalScored: false,
  hasGoalDisallowed: false,
  hadBet: false,
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case SET_TOOLBAR:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
}
