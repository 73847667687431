import AppRoute from "./AppRoute";
import Contest from "./feature/contest/Contest";

const PicksRoute = ({ path = "/picks" }) => {
  return (
    <AppRoute
      path={path}
      topbarBurgerMenu
      topbarTitle={process.env.REACT_APP_HIDE_TOPBAR_LOGO === "true" && "Picks"}
      topbarTitleLogo={process.env.REACT_APP_HIDE_TOPBAR_LOGO !== "true"}
      bottombar
      hasToolbar
      hasAffiliateLogo
      hasAffiliatePopup
      exact
      component={Contest}
      needsPaddingTop
      isAuthRequired
      // style={{
      //   paddingBottom: isAllSelected
      //     ? process.env.REACT_APP_NAV_ICON
      //       ? "210px"
      //       : "190px"
      //     : "130px",
      // }}
    />
  );
};

export default PicksRoute;
