import React, { useEffect, useRef, useState } from "react";
import history from "router/history";
import { useSelector, useDispatch } from "react-redux";
import {
  Skeleton,
  EmptyScreen,
  KickoffFull,
  BlockButton,
  MatIcon,
} from "fiver-react-ui-components";

import LeaderboardList from "./LeaderboardList";

import LeaderboardEmpty from "./LeaderboardEmpty";

import { usePusherLeaderboard } from "hooks";

import {
  getLeaderboardTable,
  getLeaderboard,
  getActiveBet,
  setLoading,
  setLeaderboard,
  handleLeaderboardUpdated,
  handleQuestionUpdated,
} from "./actions";

import styled from "styled-components";

const StyledEmptyWrapper = styled.div`
  overflow: hidden;
  border-radius: ${(p) => p.theme.borderRadius};
  margin: 1rem 1rem 1rem;
`;

const StyledWrapper = styled.div`
  min-height: 300px;
  padding-top: ${(p) => hasAffiliateInLeaderboard(p)};
`;

const StyledButtonWrapper = styled.div`
  padding: 1.5rem;
`;

const hasAffiliateInLeaderboard = (p) => {
  if (process.env.REACT_APP_AFFILIATE_LOGO && p.isShowingToolbarLower) {
    return ""
  } else {
    return "6rem"
  }
}

const Leaderboard = () => {
  const dispatch = useDispatch();

  const pageNumber = useRef(1);

  const { leaderboard } = useSelector((state) => state);

  const {
    activePickem,
    isActivePickemInPlay,
    isActivePickemApproved,
    isBetsOpen,
    activeBet,
    hadBet,
    isShowingMyBet,
  } = useSelector((s) => s.toolbar);

  const {
    loading,
    hasMore,
    canBet,
    pickemList,
    filterByMe,
    currentMatch,
    table,
    isContestEmpty,
  } = useSelector((state) => state.leaderboard);

  usePusherLeaderboard();

  const activePickemId = activePickem?.id;
  const activeBetId = activeBet?.betId;

  const isShowingToolbarLower =
    isActivePickemApproved === false && isBetsOpen === false;

  const { isScrolledDown, loggedInUserName, loggedInUserImage } = useSelector(
    (state) => state.app
  );

  useEffect(() => {
    if (!localStorage.getItem("fiver_react_access_token")) {
      history.push("/");
    }

    if (activePickem) {
      dispatch(setLoading(true));
    }
    // if (activePickem) {
    dispatch(getLeaderboard(activePickem, activeBet));
    // }

    return () =>
      dispatch(
        setLeaderboard({
          table: undefined,
          canBet: undefined,
          activeBet: undefined,
          isPickemEmpty: undefined,
          isPreMatchLeaderboard: undefined,
        })
      );
  }, [activePickem?.id]);

  useEffect(() => {
    if (hadBet) {
      window.xtremepush(
        "tag",
        "leaderboard",
        JSON.stringify({
          AppName: process.env.REACT_APP_TITLE,
          ContestName: activePickem?.name,
        })
      );

      window.xtremepush(
        "event",
        "Leaderboard",
        JSON.stringify({
          AppName: process.env.REACT_APP_TITLE,
          ContestName: activePickem?.name,
        })
      );
    }
  }, [hadBet, activePickem]);

  const isCompleted = activePickem?.status === "COMPLETED";
  const isApproved = activePickem?.status === "APPROVED";

  const isPreMatchLeaderboard =
    isApproved ||
    (isActivePickemInPlay && !table?.length) ||
    (isActivePickemInPlay && !(activePickem?.hasLiveScoring ?? true));

  const { isLeaderboardEmpty, isPickemEmpty } = leaderboard;

  const hasBet = activeBet;

  console.log("canBet", canBet);

  console.log("About toolbar",isShowingToolbarLower);

 console.log("loading", loading)
 console.log("pickem empty", isPickemEmpty)
 console.log("table length", table?.length)
 console.log("active bet", activeBet)

  return (
    <StyledWrapper
      isShowingToolbarLower={isShowingToolbarLower}
      noTopPadding={
        loading === true ||
        isPickemEmpty === true ||
        table?.length === 0 ||
        activeBet === 0 ||
        activeBet === undefined
      }
    >
      {!loading && leaderboard && isPickemEmpty === true && canBet === false ? (
        <StyledEmptyWrapper>
          <EmptyScreen
            emptyIcon="cancel_presentation"
            emptyTitle="No Active Match"
            emptySubtitle="Next match soon"
          />
        </StyledEmptyWrapper>
      ) : null}

      {!loading && leaderboard && isLeaderboardEmpty && canBet ? (
        <StyledEmptyWrapper>
          <EmptyScreen
            emptyIcon="touch_app"
            emptyTitle={`Make Your ${
              process.env.REACT_APP_TEXT_PICKS || "Picks"
            }`}
            emptySubtitle={`Tap on ${
              process.env.REACT_APP_TEXT_PICKS || "Picks"
            } to get started`}
          />
        </StyledEmptyWrapper>
      ) : null}

      {!loading &&
      table === undefined &&
      hadBet === false &&
      isPickemEmpty === false &&
      canBet === false ? (
        <StyledEmptyWrapper>
          <EmptyScreen
            emptyIcon="block"
            emptyTitle={`No ${
              process.env.REACT_APP_HIDE_GAMBLING_REFERENCES !== "true"
                ? "bet placed"
                : `${process.env.REACT_APP_TEXT_PICKS || "picks"} made`
            }
           `}
            emptySubtitle="You can't view this leaderboard"
          />
        </StyledEmptyWrapper>
      ) : null}

      {(!loading &&
        table === undefined &&
        hadBet === true &&
        canBet === false) ||
      (isPreMatchLeaderboard === true && hadBet === true) ? (
        // (isPreMatchLeaderboard === true && hadBet === true) ? (
        <LeaderboardEmpty />
      ) : null}

      {/* {(!isLeaderboardEmpty && !isPickemEmpty) || activePickem?.id ? (
        <LeaderboardHeader />
      ) : null} */}

      {!isPreMatchLeaderboard && <LeaderboardList />}
    </StyledWrapper>
  );
};

export default Leaderboard;
