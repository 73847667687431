import React from "react";
import PropTypes from "prop-types";
import styled, { keyframes, css } from "styled-components";

const fadeIn = keyframes`
  0% {
    ${"" /* transform: translateY(10px); */}
    opacity: 0;
  }
  50% {
    ${"" /* transform: translateY(0); */}
    opacity: 1;
  }
  100% {
    ${"" /* transform: translateY(0); */}
    opacity: 1;
  }
`;

const fadeInSecond = keyframes`
  0% {
    ${"" /* transform: translateY(10px); */}
    opacity: 0;
  }
  25% {
    ${"" /* transform: translateY(10px); */}
    opacity: 0;
  }
  75% {
    ${"" /* transform: translateY(0); */}
    opacity: 1;
  }
  100% {
    ${"" /* transform: translateY(0); */}
    opacity: 1;
  }
`;

const fadeInThird = keyframes`
  0% {
    ${"" /* transform: translateY(10px); */}
    opacity: 0;
  }
  50% {
    ${"" /* transform: translateY(10px); */}
    opacity: 0;
  }
  100% {
    ${"" /* transform: translateY(0); */}
    opacity: 1;
  }
`;

const StyledWrapper = styled.div`
  ${(props) =>
    !props.isSecond &&
    !props.isThird &&
    css`
      animation: ${fadeIn};
    `};
  ${(props) =>
    props.isSecond &&
    css`
      animation: ${fadeInSecond};
    `};
  ${(props) =>
    props.isThird &&
    css`
      animation: ${fadeInThird};
    `};
  animation-duration: 1s;
`;

const FadeIn = (props) => (
  <StyledWrapper isSecond={props.isSecond} isThird={props.isThird}>
    {props.children}
  </StyledWrapper>
);

FadeIn.propTypes = {
  isSecond: PropTypes.bool,
  isThird: PropTypes.bool,
};

export default FadeIn;
