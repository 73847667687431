import history from "router/history";
import axios from "axios";
import moment from "moment";
import {
  isSafari,
  isMobileSafari,
  isIE,
  isIOS,
  isChrome,
  isAndroid,
} from "react-device-detect";

export function logBranchIo(name = "COMPLETE_REGISTRATION") {
  window.branch.logEvent(name);
}

export function logXtremePush(name, type = "tag", params) {
  window.xtremepush(type, name, params);
}

export function validateAge(birthday) {
  // it will accept two types of format yyyy-mm-dd and yyyy/mm/dd
  var optimizedBirthday = birthday.replace(/-/g, "/");

  const today = moment();

  const showFallbackDatePicker =
    (isSafari && !isMobileSafari) || isIE || isAndroid;

  const myBirthdate = moment(
    optimizedBirthday,
    showFallbackDatePicker ? "DD/MM/YYYY" : "YYYY-MM-DD"
  );
  // console.log("myBirthdate", myBirthdate);
  //
  // console.log("validateAge", today, today.diff(myBirthdate, "years"));

  const age = moment().diff(myBirthdate, "years");
  const isAdult = age >= 18;
  return isAdult;
}

export function createImageCdnLink(fileName) {
  const imageCdn =
    process.env.REACT_APP_IMAGE_CDN ||
    "https://fiver-react-app.s3.eu-west-2.amazonaws.com/assets/";
  return imageCdn + fileName;
}

export function createFlagCdnLink(country) {
  return `${process.env.REACT_APP_IMAGE_CDN}/flags/${country?.replace(
    /\s+/g,
    "-"
  )}.png`;
}

export function createLegalPage(page) {
  if (process.env.NODE_ENV === "development") {
    return `${process.env.REACT_APP_ASSETS_API_URL}${page}.html`;
  } else {
    return `${process.env.REACT_APP_IMAGE_CDN}pages/${page}.html`;
  }
}

export function imagePath(imageId, fileName) {
  return process.env.REACT_APP_PICKEM_IMAGE_CDN
    ? `${process.env.REACT_APP_PICKEM_IMAGE_CDN}/${imageId}/${fileName}`
    : `//cdn.low6-prod.com/${imageId}/${fileName}`;
}

export function getClubLogo(pickem, isAwayTeam) {
  let logo = "";
  let team;

  if (pickem && pickem.footballMatch) {
    if (isAwayTeam) {
      team = pickem.footballMatch.awayTeam;
    } else {
      team = pickem.footballMatch.homeTeam;
    }

    if (team.media.length > 0) {
      logo = imagePath(team.media[0].id, team.media[0].file_name);
    } else {
      logo = createImageCdnLink(process.env.REACT_APP_CLUB_LOGO_PLACEHOLDER);
    }
  }

  return logo;
}

export const validatePassword = (password) => {
  const re2 = /^(?=.{8,}$)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).*$/;
  return re2.test(password);
};

export const validateEmail = (email) => {
  const re = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
  return re.test(email);
};

export function ordinalSuffix(i) {
  var j = i % 10,
    k = i % 100;
  if (j === 1 && k !== 11) {
    return i + "st";
  }
  if (j === 2 && k !== 12) {
    return i + "nd";
  }
  if (j === 3 && k !== 13) {
    return i + "rd";
  }
  return i + "th";
}

const customId = "custom-id-yes";

export function handleError(response) {
  console.log("handleError", response);

  if (!response) {
    if (process.env.NODE_ENV === "development") {
      //toast.error(`Error: ${response}`, { toastId: customId });
    }
    return;
  }

  if (response) {
    const { status, message } = response;
    if (status === 401 || status === 408) {
      localStorage.removeItem("fiver_react_access_token");
      localStorage.removeItem("fiver_react_refresh_token");
      //history.location.href = "/";
      if (process.env.NODE_ENV !== "development") {
        //toast.error("Please Login Again");
      }
    } else {
      if (process.env.NODE_ENV === "development") {
        //toast.error(`Error: code ${status} message ${message}`);
      }
    }
  }
}
