import React from "react";
import styled from "styled-components";

const StyledText = styled.span`
  color: ${(props) => props.theme.colorSuccess};
`;

const SuccessText = ({ className, children }) => (
  <StyledText className={className}>{children}</StyledText>
);

export default SuccessText;
