import React from "react";
import styled from "styled-components";

const StyledWrapper = styled.div`
  padding-right: 1rem;
`;

const StyledQuestionText = styled.div`
  font-size: 1.25rem;
  line-height: 1.25;
  margin-bottom: ${(p) => (p.hasStat ? "0.75rem" : 0)};
`;

const StyledQuestionStat = styled.div`
  font-size: 0.825rem;
  font-weight: 600;
  line-height: 1.45;
  padding-right: 1rem;
  color: ${(props) => props.theme.colorLabel};
  margin-bottom: ${(p) => (p.hasStat ? "0.75rem" : 0)};
`;

const ContestPicksQuestion = ({ question, questionStat }) => {
  return (
    <StyledWrapper>
      <StyledQuestionText hasStat={questionStat}>{question}</StyledQuestionText>
      <StyledQuestionStat hasStat={questionStat}>
        {questionStat}
      </StyledQuestionStat>
    </StyledWrapper>
  );
};

export default ContestPicksQuestion;
