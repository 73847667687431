import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  Form,
  FormRow,
  FormRowMessage,
  FormTextbox,
  BlockButton,
  Card,
} from "components/shared";
import { changePin, clearPinError } from "./actions";

const ChangePin = ({ changePin, clearPinError, error }) => {
  useEffect(() => {
    return () => clearPinError();
  }, [clearPinError]);

  const [state, setState] = useState({
    oldPin: undefined,
    newPin: undefined,
    newPinConfirm: undefined,
  });

  const { oldPin, newPin, newPinConfirm } = state;
  const oldPinValid = oldPin !== "";

  const newPinValid =
    newPin === undefined || (newPin.length > 3 && newPin.length < 7);

  const newPinConfirmValid =
    newPinConfirm === undefined ||
    (newPinConfirm.length > 3 && newPinConfirm.length < 7);

  const pinsMatch = newPinConfirm === newPin;

  const formIsValid =
    oldPin &&
    newPin &&
    newPinValid &&
    newPinConfirm &&
    newPinConfirmValid &&
    pinsMatch;

  const handleTextboxChanged = (e) => {
    const target = e.target;
    setState({
      ...state,
      [target.name]: target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    changePin({ old: oldPin, new: newPin });
  };

  return (
    <Card>
      <Form>
        <FormRow isSmall>
          <FormTextbox
            isSmall
            isError={!oldPinValid}
            onChange={handleTextboxChanged}
            name="oldPin"
            type="password"
            placeholder="Enter Old Pin"
          />
        </FormRow>
        <FormRowMessage isVisible={!oldPinValid}>
          Pin must be between 4 and 6 characters
        </FormRowMessage>
        <FormRow isSmall>
          <FormTextbox
            isSmall
            onChange={handleTextboxChanged}
            name="newPin"
            type="password"
            placeholder="Enter New Pin"
          />
        </FormRow>
        <FormRowMessage isVisible={!newPinValid}>
          Pin must be between 4 and 6 characters
        </FormRowMessage>
        <FormRow isSmall>
          <FormTextbox
            isSmall
            onChange={handleTextboxChanged}
            name="newPinConfirm"
            type="password"
            placeholder="Confirm New Pin"
          />
        </FormRow>
        <FormRowMessage
          margin="0 0 1rem"
          isVisible={oldPinValid && newPin && newPinConfirm && !pinsMatch}
        >
          Pins must match
        </FormRowMessage>
        <FormRowMessage margin="0 0 1rem" isVisible={error}>
          Old pin is incorrect
        </FormRowMessage>
        <FormRow>
          <BlockButton disabled={!formIsValid} onClick={handleSubmit}>
            Change Pin
          </BlockButton>
        </FormRow>
      </Form>
    </Card>
  );
};

const mapStateToProps = (state) => ({
  error: state.auth.changePinError,
});

const mapActionsToProps = {
  changePin,
  clearPinError,
};

export default connect(mapStateToProps, mapActionsToProps)(ChangePin);
