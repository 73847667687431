import styled, { css } from "styled-components";
import { useState } from "react";
import { useSelector } from "react-redux";
import { createImageCdnLink } from "utils";
import { MatIcon } from "fiver-react-ui-components";

const visibleStyles = css`
  visibility: visible;
  opacity: 1;
`;

const StyledWrapper = styled.div`
  position: fixed;
  z-index: 10;
  left: 50%;
  bottom: 80px;
  width: 100%;
  transform: translateX(-50%);
  visibility: hidden;
  opacity: 0;
  transition: all 0.5s linear;

  @media (min-width: ${(p) => p.theme.screenWidthMd}) {
    width: 280px;
  }

  ${(p) => p.isVisible && visibleStyles};
`;

const StyledCloseButton = styled.button`
  position: absolute;
  top: -50px;
  right: 0;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  background: #0d0d0d;
  color: #f9f9f9;
  display: grid;
  padding: 0;
  place-items: center;
`;

const StyledLink = styled.a`
  position: relative;
  display: block;
  width: 100%;
  height: 80px;
`;

const StyledImageWrapper = styled.span`
  position: absolute;
  right: 1rem;
  bottom: 1rem;
`;

const StyledImage = styled.img`
  width: 250px;
`;

const Wrapper = ({ ctaUrl, imageUrl }) => {
  const { showSubmissionPopup } = useSelector((s) => s.app);
  const { activeBet } = useSelector((s) => s.toolbar);

  const [isDismissed, setDismissed] = useState(false);

  const handleCloseClicked = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setDismissed(true);
  };

  const openLink = (url) => {
    let popupType = "Activebet";

    if (showSubmissionPopup === true) {
      popupType = "PickSubmission";
    }

    window.xtremepush("tag", `AffiliatePopup.Click.${popupType}`,
      JSON.stringify({
        AppName: process.env.REACT_APP_TITLE
      }));

    window.open(url, '_blank').focus()
  }

  const ScrollingPopup = () => {
    return (
      <StyledWrapper isVisible={!isDismissed}>
        {ctaUrl ? (
          <StyledLink onClick={() => openLink(ctaUrl)}>
            <StyledImageWrapper>
              <StyledCloseButton onClick={handleCloseClicked}>
                <MatIcon>close</MatIcon>
              </StyledCloseButton>
              <StyledImage src={createImageCdnLink(imageUrl)} />
            </StyledImageWrapper>
          </StyledLink>
        ) : (
          <StyledImageWrapper>
            <StyledCloseButton onClick={handleCloseClicked}>
              <MatIcon>close</MatIcon>
            </StyledCloseButton>
            <StyledImage src={createImageCdnLink(imageUrl)} />
          </StyledImageWrapper>
        )}
      </StyledWrapper>
    )
  }

  return (
    <>
      {((activeBet && imageUrl !== undefined) || showSubmissionPopup) ? <ScrollingPopup /> : null }
    </>
  );
};

export default Wrapper;
