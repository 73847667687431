import React, { useState } from "react";
import { useSelector } from "react-redux";
import styled, { css } from "styled-components";
import { NavLink } from "react-router-dom";
import { MatIcon, BottombarBg } from "components/shared";
import { rgba, darken } from "polished";
import { createImageCdnLink } from "utils";
import BottombarBadge from "./BottombarBadge";

const StyledWrapper = styled.div`
  position: fixed;
  z-index: 5;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  pointer-events: none;

  @media (min-width: ${(props) => props.theme.screenWidthMd}) {
    left: 50%;
    width: ${(props) => props.theme.railWidth};
    margin: 0 auto;
    transform: translateX(-50%);
  }
`;

const columnStyles = css`
  flex: 0 1 auto;
  max-width: 50%;
  width: 50%;
  display: flex;
  align-items: center;
  background: ${(props) => props.theme.colorDark};
`;

const StyledColumnLeft = styled.div`
  ${columnStyles};
  margin-right: 12px;
  border-top-right-radius: 20px;
`;

const StyledColumnRight = styled.div`
  ${columnStyles};
  margin-left: 12px;
  border-top-left-radius: 20px;
`;

const sharedButtonStyles = css`
  flex: 1 1 50%;
  display: flex;
  height: 80px;
  padding: 0.875rem 0 0;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  text-align: center;
  color: ${rgba("white", 0.575)};
  transform: translateY(${(props) => (props.isScrolledDown ? "0" : "0")});
  transition: transform 0.25s;
  pointer-events: all;
  &.active {
    color: white;
  }
`;

const StyledButton = styled(NavLink)`
  ${sharedButtonStyles};
`;

const StyledDisabledButton = styled.div`
  ${sharedButtonStyles};
  opacity: 0.405;
  cursor: not-allowed;
`;

const StyledButtonText = styled.div`
  font-size: 0.675rem;
  white-space: nowrap;
`;

const StyledBgWrapper = styled.div`
  position: fixed;
  bottom: -3px;
  left: 50%;
  transform: translateX(-50%);
`;

const StyledIcon = styled.div`
  flex: 0 0 64px;
  padding: 2px;
  width: 64px;
  height: 64px;
  transform: translateY(-12px);
  border-radius: 50%;
  background: ${(p) => p.theme.colorPrimary};
`;

const StyledIconImage = styled.img`
  width: 60px;
  height: 60px;
`;

const StyledBadgeWrapper = styled.span`
  position: absolute;
  top: 8px;
  left: 50%;
  margin-left: 12px;
  transform: translateX(-50%);
`;

const BottombarIcon = () => {
  const [itemsLeft] = useState([
    { to: "/picks", text: process.env.REACT_APP_TEXT_PICKS_TO_CHOICE === 'true' ? 'Choices' : 'Picks', icon: "format_list_numbered" },
    { to: "/leaderboard", text: "Leaderboard", icon: "emoji_events" },
  ]);

  const [itemsRight] = useState([
    {
      to: "/wallet",
      text: "Wallet",
      icon: "account_balance_wallet",
      isDisabled: process.env.REACT_APP_HIDE_MONEY_REFERENCES === "true",
    },
    { to: "/profile", text: "Profile", icon: "person" },
  ]);

  const { activePickem } = useSelector((state) => state.contest);

  const isInPlay = activePickem?.status === "IN_PLAY";

  return (
    <StyledWrapper>
      <StyledBgWrapper>
        <BottombarBg />
      </StyledBgWrapper>

      <StyledColumnLeft>
        {itemsLeft.map((item, index) => (
          <StyledButton to={item.to}>
            {item.to === "/leaderboard" && isInPlay && (
              <StyledBadgeWrapper>
                <BottombarBadge />
              </StyledBadgeWrapper>
            )}
            <MatIcon margin="0 0 0.325rem">{item.icon}</MatIcon>
            <StyledButtonText>{item.text}</StyledButtonText>
          </StyledButton>
        ))}
      </StyledColumnLeft>
      <StyledIcon>
        <StyledIconImage
          src={createImageCdnLink(process.env.REACT_APP_NAV_ICON)}
        />
      </StyledIcon>
      <StyledColumnRight>
        {itemsRight.map((item, index) =>
          item.isDisabled ? (
            <StyledDisabledButton to={item.to}>
              <MatIcon margin="0 0 0.325rem">{item.icon}</MatIcon>
              <StyledButtonText>{item.text}</StyledButtonText>
            </StyledDisabledButton>
          ) : (
            <StyledButton to={item.to}>
              <MatIcon margin="0 0 0.325rem">{item.icon}</MatIcon>
              <StyledButtonText>{item.text}</StyledButtonText>
            </StyledButton>
          )
        )}
      </StyledColumnRight>
    </StyledWrapper>
  );
};

export default BottombarIcon;
