import styled, { css } from "styled-components";

import { useState } from "react";

import { useSelector } from "react-redux";

import { QuestionTypePoints, QuestionTypeQuestion } from "../shared";

import { MatIcon } from "fiver-react-ui-components";

import { createImageCdnLink } from "@low6dev/react-utils/dist";

const successStyles = css`
  background: ${(p) => p.theme.colorSuccess};
  color: ${(p) => p.theme.colorSuccessText};
`;

const dangerStyles = css`
  background: ${(p) => p.theme.colorDanger};
  color: ${(p) => p.theme.colorDangerText};
`;

const StyledRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
`;

const StyledLeft = styled.div`
  flex: 1 1 auto;
  min-width: 1px;
`;

const StyledRight = styled.div`
  flex: 0 0 35%;

  @media (min-width: ${(props) => props.theme.screenWidthXs}) {
    flex: 0 0 96px;
  }
`;

const StyledButtons = styled.div`
  display: grid;
  gap: 0.75rem;
  grid-template-columns: 50% 50%;
`;

const StyledButton = styled.button`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 4rem;
  padding: 0;
  padding-right: 0.5rem;
  border-radius: ${(p) => p.theme.borderRadius};

  background: ${(p) =>
    p.isSelected ? p.theme.colorAccent : p.theme.colorLightGrey};
  color: ${(p) => (p.isSelected ? p.theme.colorAccentText : p.theme.colorText)};

  &:disabled {
    cursor: not-allowed;

    ${(p) =>
      !p.isSelected &&
      `background: ${p.theme.colorLightGrey}; color: #cfd5dc;`};
    ${(p) => p.isSuccess && successStyles};
    ${(p) => p.isDanger && dangerStyles};
  }

  @media (min-width: ${(props) => props.theme.screenWidthXs}) {
    height: 6rem;
    padding-right: 1.5rem;
  }
`;

const StyledButtonContent = styled.span`
  text-align: right;
  margin-left: 0.75rem;
  font-size: 0.75rem;
  min-width: 0;

  color: ${(p) => p.isGreyedOut && "#b1b4b9"};

  @media (min-width: ${(props) => props.theme.screenWidthXs}) {
    font-size: 1rem;
  }
`;

const StyledButtonAvatar = styled.span`
  flex: 0 0 50px;
  position: relative;
  overflow: hidden;
  width: 50px;
  height: 100%;
  border-top-left-radius: ${(p) => p.theme.borderRadius};
  border-bottom-left-radius: ${(p) => p.theme.borderRadius};

  @media (min-width: ${(props) => props.theme.screenWidthXs}) {
    width: 64px;
  }
`;

const StyledButtonAvatarWrapper = styled.span`
  position: absolute;
  overflow: hidden;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  width: 300px;
  height: 300px;
  border: 3px solid #ffffff;
  border-radius: 50%;
`;

const StyledButtonAvatarImage = styled.img`
  width: 64px;
  height: 6rem;
  object-fit: cover;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  opacity: ${(p) => p.isGreyedOut && 0.35};
`;

const StyledButtonFirstName = styled.span`
  display: block;
  margin-bottom: 3px;
`;

const StyledButtonLastName = styled.span`
  display: block;
  text-transform: uppercase;
  text-align: right;
  word-break: break-all;
`;

const inPlayIconStyles = css`
  color: #ffffff;
  background: ${(p) =>
    p.isRight ? p.theme.colorSuccess : p.theme.colorDanger};
`;

const StyledLockIcon = styled(MatIcon)`
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 38px;
  font-size: 16px;
  transform: translateY(-50%);
  background: white;
  color: ${(p) => p.theme.colorPrimary};
  border-radius: 50%;
  border: 2px solid white;
  padding: 2px;

  @media (min-width: ${(props) => props.theme.screenWidthXs}) {
    left: 34px;
    font-size: 20px;
  }

  ${(p) => p.isInPlay && inPlayIconStyles};
`;

const StyledPopularBadge = styled.span`
  position: absolute;
  z-index: 2;
  top: -10px;
  left: 20px;
  display: flex;
  align-items: center;
  gap: 3px;
  height: 24px;
  padding: 0 8px;
  border-radius: 12px;
  background: #e17000;
  border: 2px solid #ffffff;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.35);
  color: #ffffff;
`;

const StyledPopularBadgeIcon = styled(MatIcon)`
  font-size: 11px;
  transform: translateY(-1px);
`;

const StyledPopularBadgeText = styled.span`
  font-size: 10px;
  line-height: 1;
`;

const Wrapper = ({
  id,
  label,
  subtitle,
  data,
  options,
  correctAnswerId,
  myAnswerId,
  isLocked,
  showIcon,
  availablePoints,
  updatePicks,
  popularSelection,
}) => {
  const [isDirty, setDirty] = useState(false);

  const { hadBet } = useSelector((s) => s.toolbar);

  const onClick = (pickId, option) => {
    if (!isDirty) {
      setDirty(true);
    }
    updatePicks(pickId, option);
  };

  const isAnswerRight = correctAnswerId === myAnswerId;

  return (
    <>
      <StyledRow>
        <StyledLeft>
          <QuestionTypeQuestion
            question={label}
            questionStat={subtitle}
            entity={data}
          />
        </StyledLeft>
        <StyledRight>
          <QuestionTypePoints
            correctAnswerId={correctAnswerId}
            myAnswerId={myAnswerId}
            isLocked={isLocked}
            showIcon={showIcon}
            availablePoints={availablePoints}
          />
        </StyledRight>
      </StyledRow>

      <StyledButtons>
        {options.map((option) => {
          const isRight = option.points > 0;
          const isWrong = !isRight;
          const isAnswerWrong = !isAnswerRight;
          const isSelected = myAnswerId === option.id;
          const isGreyedOut = (isDirty || isLocked) && !isSelected;
          const isPopularSelection = popularSelection?.optionId === option?.id;

          return (
            <StyledButton
              onClick={() => onClick(id, option)}
              disabled={isLocked}
              isSuccess={
                hadBet && isLocked && showIcon && isRight && isAnswerRight
              }
              isDanger={hadBet && isLocked && showIcon && isWrong && isSelected}
              isSelected={isSelected}
            >
              {isPopularSelection && (
                <StyledPopularBadge>
                  <StyledPopularBadgeIcon>star</StyledPopularBadgeIcon>
                  <StyledPopularBadgeText>
                    {popularSelection.percentage}%
                  </StyledPopularBadgeText>
                </StyledPopularBadge>
              )}
              <StyledButtonAvatar>
                <StyledButtonAvatarWrapper>
                  <StyledButtonAvatarImage
                    isGreyedOut={isGreyedOut}
                    src={
                      option?.data?.logo ||
                      createImageCdnLink(process.env.REACT_APP_IMAGE_BLANK_LOGO)
                    }
                  />
                </StyledButtonAvatarWrapper>
              </StyledButtonAvatar>
              <StyledButtonContent isGreyedOut={isGreyedOut}>
                <StyledButtonFirstName>
                  {option?.data?.first_name}
                </StyledButtonFirstName>
                <StyledButtonLastName>
                  {option?.data?.last_name}
                </StyledButtonLastName>
              </StyledButtonContent>

              {isSelected && (showIcon || isLocked) && (
                <StyledLockIcon
                  isInPlay={showIcon}
                  isRight={isRight}
                  isWrong={isWrong}
                >
                  {showIcon && isRight && isAnswerRight
                    ? "check"
                    : showIcon && isWrong
                    ? "close"
                    : "lock"}
                </StyledLockIcon>
              )}
            </StyledButton>
          );
        })}
      </StyledButtons>
    </>
  );
};

export default Wrapper;
