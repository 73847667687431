import { useEffect } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import { setTenantDetails } from "../components/feature/app/actions";

const useTenantDetails = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    (async () => {
      try {
        const tenantDetails = await axios.get("/tenant", {
            headers: {
              "User-Agent" : process.env.REACT_APP_TENANT
            }
          }).then((data) => {return data?.data?.data});
        dispatch(setTenantDetails(tenantDetails));
      } catch (error) {
        dispatch(setTenantDetails(null));
      }
    })();
  }, []);
};

export default useTenantDetails;
