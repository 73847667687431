import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
  body {
    font-family: ${(p) => p.theme.fontFamily};
    color: ${(props) => props.theme.colorText};
  }

  @media (min-width: ${(props) => props.theme.screenWidthMd}) {
    html {
      min-height: 100%;
      display: flex;
      flex-direction: column;
    }

    body {
      flex: 1 1 auto;
      display: flex;
      flex-direction: column;
    }

    #root {
      flex: 1 1 auto;
      display: flex;
      flex-direction: column;
    }
  }

  button, input, select {
    color: ${(props) => props.theme.colorText};
    font-family: ${(p) => p.theme.fontFamily}
  }

  body .Toastify__toast-container {
    padding: 0;
    width: 100%;

    @media (min-width: ${(props) => props.theme.screenWidthMd}) {
      width: ${(props) => props.theme.railWidth};
    }
  }

  body .Toastify__toast {
    padding: 0;
    margin-bottom: 0;
    padding-right: 1rem;
    user-select: none;
    display: flex;
    justify-content: center;
  }

  body .Toastify__toast-container--bottom-center {
    bottom: 0;
  }

  body .Toastify__toast--success {
    background: ${(props) => props.theme.colorSuccess};
    color: ${(p) => p.theme.colorSuccessText};
  }

  body .Toastify__toast--error {
    background: ${(props) => props.theme.colorDanger};
    color: ${(p) => p.theme.colorDangerText};
  }

  body .Toastify__close-button {
    position: absolute;
    top: 50%;
    right: 1rem;
    transform: translateY(-50%);
  }

  body .Toastify__toast-body {
    padding: 1rem 2rem;
    text-align:center;
    font-family: ${(props) => props.theme.fontFamily};
  }

  body .tippy-tooltip {
    background: white;
    color: ${(p) => p.theme.colorText};
    font-size: 0.75rem;
    line-height: 1.75;
    padding: 1rem;
    border-radius: ${(p) => p.theme.borderRadius};
    box-shadow: 0px 0px 20px rgba(0,0,0,0.125);
  }

  body .tippy-tooltip .tippy-tooltip-content div {
    line-height: 1.5;
  }

  body .tippy-popper[x-placement^=top] [x-arrow] {
    border-top-color: white;
  }
`;
