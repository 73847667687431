import React, {useState} from "react";
import styled, {createGlobalStyle} from "styled-components";
import {Formik, Form, Field, useFormikContext} from "formik";
import {PrizeFormSchema} from "./PrizeFormSchema";
import DatePicker from "react-modern-calendar-datepicker";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import {FormRowMessage} from "../../shared";


const StyledField = styled(Field)`
  border-bottom: 3px solid #696971;
  border-left: none;
  border-right: none;
  border-top: none;
  width: 100%;
  padding: 0.5rem 0;
  font-size: 1.125rem;
  margin-bottom: 1rem;
  background: transparent;
  outline: none;
`;

const StyledInput = styled(Field)`
  border-bottom: border: 3px solid #696971;
  border-left: none;
  border-right: none;
  border-top: none;
  width: 100%;
  padding: 0.5rem 0;
  font-size: 1.125rem;
  margin-bottom: 1rem;
  background: transparent;
  outline: none;
`;

const DatepickerGlobal = createGlobalStyle`
  .DatePicker {
    width: 100%;
  }
`;

const StyledFormikWraper = styled.div`
  width: 100%;
  margin-bottom: 1rem;
`;


const PrizeForm = ({
  contest,
  pass
}) => {
  const currentDate = new Date()
  const [initialValues, setInitialValues] = useState({
    firstName: "",
    lastName: "",
    birthDate: {
      day: currentDate.getDate(),
      month: currentDate.getMonth(),
      year: currentDate.getFullYear(),
    },
    mobileNumber: "",
    email: "",
  });
  
  const onSubmit = (values, { setSubmitting }) => {

  };
  
  const DatePickerField = ({ name, setFieldTouched }) => {
    const formik = useFormikContext();
    const field = formik.getFieldProps(name);
    
    const renderCustomInput = ({ ref }) => (
      <input
        readOnly
        ref={ref} // necessary
        placeholder="Select Birth Date"
        value={`${('0' + field.value.day).slice(-2)}/${('0' + (field.value.month + 1)).slice(-2)}/${field.value.year}`}
        style={{
          textAlign: 'left',
          padding: '0.5rem 0',
          fontSize: '1.125rem',
          border: '3px solid #696971',
          marginBottom: '1rem',
          outline: 'none',
          width: '100%',
          borderLeft: 'none',
          borderRight: 'none',
          borderTop: 'none',
          background: 'transparent',
        }}
      />
    )
    
    return (
      <DatePicker
        value={field.value}
        onChange={value => {
          formik.setFieldValue(name, value)
          setFieldTouched(name)
        }}
        renderInput={renderCustomInput}
        style={{width: '100%'}}
      />
    );
  }
  
  return (
    <StyledFormikWraper>
      <Formik
        initialValues={initialValues}
        validationSchema={PrizeFormSchema()}
        onSubmit={onSubmit}
        enableReinitialize={true}
        
      >
        {({
            isValid,
            values,
            errors,
            touched,
            dirty,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            setFieldError,
            setFieldTouched,
            setTouched,
            validateField,
            setValues,
            isValidating,
            /* and other goodies */
          }) => {
          return (
            <Form>
              <DatepickerGlobal />
              <StyledField name="firstName" placeholder="First Name"
                onBlur={handleBlur}
                onChange={handleChange}
              />
              <FormRowMessage isVisible={(touched.firstName && errors.firstName)}>
                {errors.firstName}
              </FormRowMessage>
          
              <StyledField
                name="lastName"
                placeholder="Last Name"
                onBlur={handleBlur}
                onChange={handleChange}
              />
              <FormRowMessage isVisible={(touched.lastName && errors.lastName)}>
                {errors.lastName}
              </FormRowMessage>
          
              <StyledField
                name="mobileNumber"
                placeholder="Mobile Number"
                onBlur={handleBlur}
                onChange={handleChange}
              />
              <FormRowMessage isVisible={(touched.mobileNumber && errors.mobileNumber)}>
                {errors.mobileNumber}
              </FormRowMessage>
          
              <StyledField
                  type="email"
                  name="email"
                  placeholder="Email"
                  onBlur={handleBlur}
                  onChange={handleChange}
              />
              <FormRowMessage isVisible={(touched.email && errors.email)}>
                {errors.email}
              </FormRowMessage>
              
              <DatePickerField name="birthDate" setFieldTouched={setFieldTouched}/>
              <FormRowMessage isVisible={(touched.birthDate && errors.birthDate)}>
                {errors.birthDate}
              </FormRowMessage>
            </Form>
          );
        }}
      </Formik>
    </StyledFormikWraper>
    
  );
};


export default PrizeForm;