import React, { useState } from "react";
import styled from "styled-components";

import { MatIcon, Skeleton } from "fiver-react-ui-components";

import ContestBannerHelpList from "./ContestBannerHelpList";
import ContestBannerHelpPayoutStructure from "./ContestBannerHelpPayoutStructure";

const StyledDropdownWrapper = styled.div`
  overflow: hidden;
  margin: 0 auto;
  width: 90%;
  max-height: ${(props) => (props.dropdownOpen ? "600px" : "40px")};
  border-radius: ${(p) => p.theme.borderRadius};
  background: ${(props) => props.theme.colorAccent};
  transition: max-height 0.5s;
`;

const StyledDropdownHeader = styled.button`
  text-align: center;
  font-size: 0.875rem;
  height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: 1rem;
  justify-content: space-between;
  color: ${(props) => props.theme.colorAccentText};
`;

const StyledDropdownContent = styled.div`
  min-height: 100px;
  padding: 0 1rem;
  font-size: 0.75rem;
  font-weight: 400;
  background: ${(p) => p.theme.colorLightGrey};
`;

const StyledDropdownIcon = styled(MatIcon)`
  transform: rotate(${(props) => (props.dropdownOpen ? "180deg" : "0deg")});
  transition: transform 0.35s;
`;

const ContestBannerHelp = ({ prizepool, split }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  return split ? (
    <StyledDropdownWrapper dropdownOpen={dropdownOpen}>
      <StyledDropdownHeader onClick={() => setDropdownOpen(!dropdownOpen)}>
        {split && (
          <>
            How To Play
            <StyledDropdownIcon dropdownOpen={dropdownOpen}>
              expand_more
            </StyledDropdownIcon>
          </>
        )}
      </StyledDropdownHeader>
      <StyledDropdownContent>
        <ContestBannerHelpList />
        <ContestBannerHelpPayoutStructure split={split} />
      </StyledDropdownContent>
    </StyledDropdownWrapper>
  ) : (
    <Skeleton
      display="block"
      margin="0 15%"
      skeletonWidth="70%"
      skeletonHeight="40px"
    />
  );
};

export default ContestBannerHelp;
