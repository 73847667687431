import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { TextStack, EntryButton, FootballMatch, SportEvent } from "./shared";
import { DateTime } from "fiver-react-ui-components";

const StyledEntryButtonPlaceholder = styled.div`
  width: ${(p) => p.widthPlaceholder};
  max-width: ${(p) => p.widthPlaceholder};
  min-width: ${(p) => p.widthPlaceholder};
  margin-right: 0.5rem;
`;

const Wrapper = ({
  index,
  pickem,
  isActive,
  activeBet,
  handleEnterClicked,
  handleRowClicked,
}) => {
  const { hasPickemsAllowingMultipleBets } = useSelector((s) => s.toolbar);

  const widthPlaceholder = hasPickemsAllowingMultipleBets ? "50px" : "50px";

  const prizePool = pickem?.prizeFund || pickem?.promoPrizeFund;

  const isPromoPrize = pickem?.promoPrizeFund > 0;

  return (
    <>
      {process.env.REACT_APP_IS_EVENT_BASED_SPORT !== "true" && (
        <FootballMatch pickem={pickem} showLogos isActive={isActive} />
      )}
      {process.env.REACT_APP_IS_EVENT_BASED_SPORT === "true" && (
        <SportEvent pickem={pickem} isActive={isActive} />
      )}

      {process.env.REACT_APP_HIDE_MONEY_REFERENCES === "true" && (
        <>
          <TextStack
            label={
              process.env.REACT_APP_BETBAR_SHOW_SPORT_TYPE === "true" ? (
                <DateTime
                  isUTC={process.env.REACT_APP_IS_UTC_DATES}
                  format="h:mm a"
                >
                  {pickem?.start}
                </DateTime>
              ) : (
                ""
              )
            }
            value={
              process.env.REACT_APP_BETBAR_HIDE_DATE_ON_APPROVED === "true" ? (
                ""
              ) : (
                <DateTime
                  isUTC={process.env.REACT_APP_IS_UTC_DATES}
                  format="MMM DD "
                >
                  {pickem?.start}
                </DateTime>
              )
            }
            isCentered
          />

          {process.env.REACT_APP_BETBAR_SHOW_SPORT_TYPE === "true" ? (
            <TextStack
              valueHasSmallerFont
              value={pickem?.data?.sport?.label
                .replace("American ", "")
                .replace("Basketball", "Men's Basketball")}
              isCentered
            />
          ) : (
            <TextStack
              value={
                <DateTime
                  isUTC={process.env.REACT_APP_IS_UTC_DATES}
                  format="h:mma"
                >
                  {pickem?.start}
                </DateTime>
              }
              isCentered
            />
          )}
        </>
      )}

      {process.env.REACT_APP_HIDE_MONEY_REFERENCES !== "true" && (
        <>
          <TextStack
            label={pickem?.activeBet ? "Entered" : "Entry"}
            value={pickem?.stake || "FREE"}
            isMoney={pickem?.stake > 0}
            isCentered
          />
          <TextStack
            label={isPromoPrize ? "Promo Prize" : "Prize Pool"}
            value={prizePool}
            isMoney
            isCentered
          />
        </>
      )}

      <StyledEntryButtonPlaceholder widthPlaceholder={widthPlaceholder}>
        {process.env.REACT_APP_BETBAR_SHOW_SPORT_TYPE !== "true" && (
          <>
            {pickem?.maxBetsPerUser &&
            pickem?.usersBets?.length < pickem?.maxBetsPerUser &&
            pickem?.activeBet ? (
              <EntryButton
                isSuccess
                onClick={(e) => handleEnterClicked(e, index, pickem, true)}
              >
                Enter
              </EntryButton>
            ) : (!pickem?.activeBet &&
                pickem?.usersBets?.length < pickem?.maxBetsPerUser) ||
              !pickem?.activeBet ? (
              <EntryButton
                isSuccess
                onClick={(e) => handleEnterClicked(e, index, pickem)}
              >
                Enter
              </EntryButton>
            ) : null}
          </>
        )}
      </StyledEntryButtonPlaceholder>
    </>
  );
};

export default Wrapper;
