import React from "react";
import styled from "styled-components";
import {formatter, StyledOptionsTip} from "./ConfirmStep";

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 0.625rem;
`;

const PrizeDrawSmallText = ({
  contest,
  margin,
}) => {
  const getEndDate = (dateStr) => {
    const  endDate = new Date(dateStr?.replace(' ', 'T'));
    return endDate.toLocaleDateString("en-GB", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
  }

  return (
    <StyledOptionsTip margin={margin} >
      {`
      18+. UK Only. Entrants must hold account with Promoter.
      Enter online (${formatter.format(contest?.ticket_price.pence / 100)} per entry) or by post.
      Max ${contest?.maximum_per_user} entries pp. All entries charged, only correct answers enter draw. Entry closes 
      midnight ${getEndDate(contest?.date?.end?.string)}. Winners drawn 6pm on ${getEndDate(contest?.date?.draw?.string)}. 
    `}
      {`For postal entry
      route see general terms `}<a href="https://winme.tv/terms/" target="_blank">here.</a>
    </StyledOptionsTip>
  );
};


export default PrizeDrawSmallText;