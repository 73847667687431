import React from "react";
import styled from "styled-components";
import {MatIcon} from "../../shared";


const StyledWrapper = styled.div`
  width: 44px;
  height: 44px;
  background: ${p => p.disabled ? '#FAFAFA' : p.theme.colorSuccess};
  color: ${p => p.disabled ? '#E6E6E6' : '#ffffff'};
  margin-left: ${p => p.marginLeft ? p.marginLeft + 'px' : 0};
  margin-right: ${p => p.marginRight ? p.marginRight + 'px' : 0};
  border-radius: 4px;
  cursor: ${p => p.disabled ? 'not-allowed' : 'pointer'};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${p => p.size ? p.size : '1rem'};
  ${p => p.disabled || p.isLoading ? 'pointer-events: none;' : ''}
`;


const PrizeButton = ({
  plus,
  minus,
  caretLeft,
  caretRight,
  disabled,
  callback,
  marginLeft,
  marginRight,
  isLoading,
}) => {
  return (
    <StyledWrapper
      disabled={disabled}
      marginLeft={marginLeft}
      marginRight={marginRight}
      isLoading={isLoading}
      onClick={() => callback()}
    >
      {caretRight && (<MatIcon>arrow_forward_ios</MatIcon>)}
      {minus && (<MatIcon>remove</MatIcon>)}
      {plus && (<MatIcon>add</MatIcon>)}
      {caretLeft && (<MatIcon>arrow_back_ios</MatIcon>)}
    </StyledWrapper>
  );
};

export default PrizeButton;
