import React from "react";
import styled from "styled-components";

const StyledWrapper = styled.div`
  position: relative;
  margin-bottom: ${(props) => (props.isSmall ? "1rem" : "1rem")};
`;

const FormRow = (props) => (
  <StyledWrapper {...props}>{props.children}</StyledWrapper>
);

export default FormRow;
