import { useSelector } from "react-redux";
import styled from "styled-components";
import { PenceToPounds, Rank } from "components/shared";

const StyledWrapper = styled.div``;

const StyledList = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1rem;
  padding: 0 2rem;
`;

const StyledLabel = styled.div`
  font-weight: 700;
  flex: 1 0 100%;
  display: flex;
  justify-content: center;
  text-align: center;
  margin-bottom: 0.5rem;
  line-height: 1.4;
  color: ${(props) => props.theme.colorLabel};
`;

const StyledItem = styled.div`
  flex: 1 1 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.375rem 0;
`;

const StyledRank = styled(Rank)`
  font-weight: 700;
  color: ${(props) => props.theme.colorLabel};
  margin-right: 0.25rem;
`;

const StyledValue = styled.div`
  font-weight: 700;
`;

const ContestBannerHelpPayoutStructure = () => {
  const { activePickem } = useSelector((s) => s.toolbar);

  const split = activePickem?.split?.length
    ? activePickem?.split
    : activePickem?.promoSplit;

  return (
    <StyledWrapper>
      {split?.length && split[0] !== 0 && (
        <>
          <StyledLabel>Payout Structure</StyledLabel>
          <StyledList>
            {split &&
              split.map((obj, index) => (
                <StyledItem>
                  <StyledRank>{index + 1}</StyledRank>
                  <StyledValue>
                    £<PenceToPounds>{obj}</PenceToPounds>
                  </StyledValue>
                </StyledItem>
              ))}
          </StyledList>
        </>
      )}
    </StyledWrapper>
  );
};

export default ContestBannerHelpPayoutStructure;
