import React from "react";
import styled from "styled-components";

const StyledText = styled.div`
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.375;
  color: ${(props) =>
    props.isNormal ? props.theme.colorText : props.theme.colorLabel};
  margin-bottom: 1rem;

  & > a {
    font-size: inherit;
    color: ${(props) => props.theme.colorAccent};
  }
`;

const FormRowText = ({ children }) => (
  <StyledText isNormal>{children}</StyledText>
);

export default FormRowText;
