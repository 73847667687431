import React from "react";
import { useDispatch, useSelector } from "react-redux";
import history from "router/history";
import { Signup as AppSignup } from "@low6dev/react-feature-signup/dist";
import styled from "styled-components";
import { handleError, logBranchIo } from "utils";
import { getPage } from "components/layout/drawer/actions";
import { loadAppStuff } from "components/feature/app/actions";

const StyledWrapper = styled.div`
  padding: 1rem 0 0;
`;

const Wrapper = () => {
  const dispatch = useDispatch();

  const handleSignupSuccess = () => {
    if (process.env.REACT_APP_BRANCH_IO_KEY) {
      logBranchIo("COMPLETE_REGISTRATION");
      logBranchIo("TESTING");
    }

    dispatch(loadAppStuff());

    if (process.env.REACT_APP_IS_MOBILE_VERIFICATION_DISABLED === "true") {
      history.push(process.env.REACT_APP_PICKS_SLUG || "/picks");
    } else {
      history.push("/confirm-phone?signup=true");
    }
  };

  const handlePageButtonClicked = (page, pageTitle, useAssetsCdn) => {
    dispatch(getPage(page, pageTitle, useAssetsCdn));
  };

  const { tenantDetails } = useSelector((s) => s.app);

  return process.env.REACT_APP_HIDE_SIGN_UP === "true" ? (
    <></>
  ) : (
    <AppSignup
      dateInputFormat={process.env.REACT_APP_DATE_FORMAT}
      disableAgeValidation={
        process.env.REACT_APP_SIGNUP_DISABLE_AGE_VALIDATION === "true"
      }
      hidePushNotifcationCheckbox={
        process.env.REACT_APP_SIGNUP_HIDE_PUSH_NOTIFICATIONS_CHECKBOX === "true"
      }
      hideCheckAllCheckbox={process.env.REACT_APP_IS_BLENHEIM === "true"}
      hideMobileNumberCheckbox={
        process.env.REACT_APP_SIGNUP_SHOW_MOBILE_NUMBER_CHECKBOX !== "true"
      }
      hideDataShareCheckbox={
        process.env.REACT_APP_SIGNUP_SHOW_DATA_SHARING_CHECKBOX !== "true"
      }
      hideMobileVerificationMessage={
        process.env.REACT_APP_IS_MOBILE_VERIFICATION_DISABLED === "true"
      }
      hideMobileNumberAlert
      hasTextboxDatePicker={
        process.env.REACT_APP_SIGNUP_HAS_TEXTBOX_DATE_PICKER
      }
      hasDropdownDatePicker={
        process.env.REACT_APP_SIGNUP_HAS_DROPDOWN_DATE_PICKER
      }
      forcedCountryCode={process.env.REACT_APP_FORCED_COUNTRY_CODE ?? ""}
      onPageButtonClicked={handlePageButtonClicked}
      onSuccess={handleSignupSuccess}
      onError={handleError}
      privacySlug="privacy"
      isNonUkForced={process.env.REACT_APP_FORCED_NON_UK === "true"}
      termsSlug={process.env.REACT_APP_TERMS_SLUG || "terms"}
      smsSlug={process.env.REACT_APP_SMS_SLUG}
      tenantDetails={tenantDetails}
      gameRulesSlug={process.env.REACT_APP_RULES_SLUG}
    />
  );
};

export default Wrapper;
