import { useSelector } from "react-redux";
import styled from "styled-components";
import { createImageCdnLink } from "utils";

const StyledWrapper = styled.div`
  margin-bottom: ${(p) => p.hasBottomMargin && "4rem"};
`;

const StyledLink = styled.a`
  display: flex;
  cursor: pointer;
`;

const StyledIframeLink = styled.a`
  display: flex;
  cursor: pointer;
  height: 100%;
`;

const StyledImage = styled.img`
  width: 100%;
  margin-bottom: ${(p) => p.hasBottomMargin && "4rem"};
`;

const StyledIframeWrapper = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 150px;

  @media (min-width: ${(p) => p.theme.screenWidthXs}) {
    height: 240px;
  }

  @media (min-width: ${(p) => p.theme.screenWidthMd}) {
    height: 200px;
  }
`;

const StyledIframe = styled.iframe`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: scale(0.425) translate(-50%, -50%);
  border: 0;
  transform-origin: 0% 0%;
  margin-top: -20px;
  margin-bottom: -100px;

  @media (min-width: ${(p) => p.theme.screenWidthXs}) {
    transform: scale(0.75) translate(-50%, -50%);
  }

  @media (min-width: ${(p) => p.theme.screenWidthMd}) {
    transform: scale(0.525) translate(-50%, -50%);
  }
`;

const StyledBlock = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  background: transparent;
  z-index: 1;
`;

const Wrapper = ({ hasBottomMargin, imageUrl, ctaUrl, iframeUrl, index }) => {
  const { affiliateBannerUrl } = useSelector((s) => s.app);

  const openLink = (url) => {
    let position;

    switch (index) {
      case 0:
        position = "Top";
        break;
      case 3:
        position = "Middle";
        break;
      case 6:
        position = "Bottom";
        break;
      default:
        position = "";
        break;
    }
    window.xtremepush("tag", `AffiliateBanner.Click.${position}`,
      JSON.stringify({
        AppName: process.env.REACT_APP_TITLE
      }));

    window.open(url, '_blank').focus()
  }

  return iframeUrl ? (
    <StyledIframeWrapper>
      <StyledBlock>
        <StyledIframeLink onClick={() => openLink(ctaUrl)} />
      </StyledBlock>
      <StyledIframe
        width="980"
        height="270"
        srcDoc={`<script src=${iframeUrl}></script><style>body { margin: 0; } iframe { border: 0; }</style>`}
      ></StyledIframe>
    </StyledIframeWrapper>
  ) : ctaUrl || affiliateBannerUrl ? (
    <StyledWrapper hasBottomMargin={hasBottomMargin}>
      <StyledLink onClick={() => openLink(ctaUrl || affiliateBannerUrl)}>
        <StyledImage src={createImageCdnLink(imageUrl || "vbet-banner.png")} />
      </StyledLink>
    </StyledWrapper>
  ) : imageUrl ? (
    <StyledImage
      hasBottomMargin={hasBottomMargin}
      src={createImageCdnLink(imageUrl)}
    />
  ) : null;
};

export default Wrapper;
