import styled from "styled-components";

import DOMPurify from "dompurify";

import { rgba } from "polished";

import { useParams } from "react-router-dom";

import { useEffect, useState } from "react";

import { SkeletonTable } from "fiver-react-ui-components";

import axios from "axios";

const StyledWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
  overflow: auto;
  background: #ffffff;
`;

const StyledTitle = styled.h1`
  margin: 0;
  padding: 2rem;
  font-weight: 400;
  font-size: 1.5rem;
  background: #000000;
  color: ${rgba("#ffffff", 0.96)};
`;

const lineHeight = 1.45;

const StyledContent = styled.div`
  padding: 2rem;
  font-weight: 400;
  color: ${(p) => p.theme.colorText};

  font-weight: 400;
  font-size: 0.875rem;
  line-height: ${lineHeight};

  .footer-link {
    color: inherit;
    text-decoration: underline;
    font-size: inherit;
  }

  h2,
  h3 {
    line-height: ${lineHeight};
    margin: 0 0 1rem;
  }

  h2 {
    font-size: 1.175rem;
  }

  h3 {
    font-size: 1rem;
  }

  p {
    line-height: ${lineHeight};
  }

  a {
    color: ${(p) => p.theme.colorAccent};
    font-size: 0.875rem;
    line-height: ${lineHeight};
  }

  table {
    border-collapse: collapse;
    font-size: 0.75rem;
  }

  table td,
  table th {
    padding: 0.5rem 0.25rem;
    text-align: left;
    line-height: ${lineHeight};
    vertical-align: top;
    border: 1px solid ${(p) => p.theme.colorBorder};
  }

  ul {
    margin: 0 0 1rem;
    padding: 0 0 0 1.25rem;
  }

  ul li {
    line-height: ${lineHeight};
    margin-bottom: 0.5rem;
  }
`;

const Wrapper = () => {
  const [page, setPage] = useState({});

  let { id } = useParams();

  useEffect(async () => {
    let pageRes;
    try {
      pageRes = await axios.get(`/pages/${id}`);
    } catch (error) {
      console.log("error", error);
    }

    let pageContent = pageRes?.data?.data.content
      .replace(`**${page.title}**\n`, "")
      .replace(/\[[^\s]+\]/g, "")
      .replace(
        /\(?(https?:(\/\/)?([^\s\)\/]+))(\/?\)?)?/g,
        "<a href='$1' class='footer-link' target='_blank'>$3</a>"
      )
      .replace(
        /\(?((mailto:)?([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+))\)?/g,
        "<a href='$1' class='footer-link'>$3</a>"
      )
      .replace(/\n/gi, "<br />")
      .replace(/↵/gi, "<br />")
      .replace(/\*\*(.*?)\*\*/g, "<b>$1</b>");

    const clean = DOMPurify.sanitize(pageContent, {
      USE_PROFILES: { html: true },
    });

    const formattedContent = {
      __html: clean,
    };

    console.log("pageRes", pageRes);

    setPage({
      content: formattedContent,
      title: pageRes?.data?.data.title,
    });
  }, []);

  return (
    <StyledWrapper>
      {!page.title ? (
        <SkeletonTable />
      ) : (
        <>
          {/* <StyledTitle>{page.title}</StyledTitle> */}
          <StyledContent dangerouslySetInnerHTML={page.content} />
        </>
      )}
    </StyledWrapper>
  );
};

export default Wrapper;
