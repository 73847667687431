const users = [
  {
    id: 1,
    username: "ed__8888",
    rank: 1,
    points: 30,
    prize: 2500,
  },
  {
    id: 2,
    username: "fab665",
    rank: 2,
    points: 28,
    prize: 1500,
  },
  {
    id: 3,
    username: "jasonholder",
    rank: 2,
    points: 28,
  },
  {
    id: 4,
    username: "lucydan_xx",
    rank: 3,
    points: 26,
  },
  {
    id: 5,
    username: "slicktricks",
    rank: 3,
    points: 26,
  },
  // {
  //   id: 6,
  //   username: "legendzclub",
  //   rank: 4,
  //   points: 24,
  // },
  // {
  //   id: 7,
  //   username: "timtim990",
  //   rank: 4,
  //   points: 24,
  // },
  // {
  //   id: 8,
  //   username: "ivypoyz_x0x0",
  //   rank: 5,
  //   points: 22,
  // },
  // {
  //   id: 9,
  //   username: "cliffton555",
  //   rank: 6,
  //   points: 20,
  // },
  // {
  //   id: 10,
  //   username: "tezzz88",
  //   rank: 6,
  //   points: 20,
  // },
];

export default users;
