import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const StyledTitle = styled.div`
  margin-bottom: ${(props) => (props.isSmall ? "0.25rem" : "0.325rem")};
  font-size: ${(props) => (props.isSmall ? "0.875rem" : "1rem")};
`;

const StyledSubtitle = styled.div`
  font-size: ${(props) => (props.isSmall ? "0.625rem" : "0.75rem")};
  color: ${(props) => props.theme.colorLabel};
`;

const TitleStack = ({ isSmall, title, subtitle, marginBottom }) => (
  <div style={{ marginBottom: marginBottom || "1rem" }}>
    <StyledTitle isSmall={isSmall}>{title}</StyledTitle>
    {subtitle && <StyledSubtitle isSmall={isSmall}>{subtitle}</StyledSubtitle>}
  </div>
);

TitleStack.propTypes = {
  isSmall: PropTypes.bool,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  marginBottom: PropTypes.string,
};

export default TitleStack;
