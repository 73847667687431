import axios from "axios";
import { handleError } from "utils";
import { GET_CONTEST, GET_PICKEMS_BETS } from "../contest/actions";
import { KickoffFull } from "../../shared";
//import { mockLeaderboard } from "mocks/mockLeaderboard.js";

import { setToolbar } from "components/layout/toolbar/actions";

export const GET_ALL_PICKEMS = "leaderboard:GET_ALL_PICKEMS";
export const SELECT_PICKEM = "leaderboard:SELECT_PICKEM";
export const SET_LEADERBOARD = "leaderboard:SET_LEADERBOARD";
export const GET_ACTIVE_BET = "leaderboard:GET_ACTIVE_BET";
export const CLEAR_LEADERBOARD = "leaderboard:CLEAR_LEADERBOARD";
export const SET_LOADING = "leaderboard:SET_LOADING";
export const SET_HAS_MORE = "leaderboard:SET_HAS_MORE";
export const SET_ERROR = "leaderboard:SET_ERROR";
export const SET_PAGE_NUMBER = "leaderboard:SET_PAGE_NUMBER";
export const SET_PICKEM_ID = "leaderboard:SET_PICKEM_ID";
export const GET_FILTERED_LEADERBOARD = "leaderboard:GET_FILTERED_LEADERBOARD";
export const SET_FILTER_BY_ME = "leaderboard:SET_FILTER_BY_ME";
export const GET_CURRENT_SCORE = "leaderboard:GET_CURRENT_SCORE";

export const setLeaderboard = (leaderboard) => {
  return {
    type: SET_LEADERBOARD,
    payload: leaderboard,
  };
};

export const getLeaderboard = (
  activePickem,
  activeBet,
  stopFilteringMyBet,
  clearFirst
) => async (dispatch, getState) => {
  if (clearFirst) {
    dispatch(
      setLeaderboard({
        table: undefined,
      })
    );
  }

  dispatch(setLoading(true));

  try {
    if (!activePickem) {
      dispatch({
        type: SET_LEADERBOARD,
        payload: {
          isPickemEmpty: true,
          loading: false,
          canBet: false,
        },
      });
      console.log("were returning here!!");
      return;
    }

    dispatch({
      type: SET_LEADERBOARD,
      payload: {
        isPickemEmpty: false,
      },
    });

    if (!activeBet) {
      dispatch({
        type: SET_LEADERBOARD,
        payload: {
          isLeaderboardEmpty: true,
          loading: false,
          canBet: !activeBet && activePickem.status === "APPROVED",
        },
      });

      return;
    }

    if (stopFilteringMyBet) {
      dispatch(
        setLeaderboard({
          isShowingMyBet: false,
        })
      );

      dispatch({
        type: SET_LEADERBOARD,
        payload: {
          activeUserBet: {},
        },
      });

      if (getState().leaderboard.table?.length) {
        return;
      }
    }

    let result = {
      loading: false,
      activeBet,
      canBet: !activeBet && activePickem.status === "APPROVED",
    };

    if (
      getState().toolbar.isActivePickemCompleted ||
      getState().toolbar.isActivePickemInPlay
    ) {
      const tableRes = await axios.get(
        `/pickems/${activePickem?.id}/cached-leaderboard`
      );

      dispatch(
        setLeaderboard({
          ...result,
          table: tableRes?.data?.data,
          isLeaderboardEmpty: false,
        })
      );
    } else {
      dispatch(setLeaderboard({ ...result, isLeaderboardEmpty: true }));
    }
  } catch ({ response }) {
    handleError(response);
  }
};

export const getUsersBet = (betId) => (dispatch) => {
  dispatch({
    type: SET_LEADERBOARD,
    payload: {
      activeUserBet: undefined,
    },
  });

  if (betId) {
    axios
      .get(`/pickem-cached-bet/${betId}`)
      .then(({ data }) => {
        dispatch({
          type: SET_LEADERBOARD,
          payload: { activeUserBet: data },
        });
      })
      .catch(({ response }) => {
        handleError(response);
      });
  }
};

export const getMyBet = (activeBet) => async (dispatch, getState) => {
  dispatch({
    type: SET_LEADERBOARD,
    payload: {
      activeUserBet: undefined,
    },
  });

  if (activeBet) {
    dispatch({
      type: SET_LEADERBOARD,
      payload: {
        activeUserBet: activeBet,
        isShowingMyBet: true,
      },
    });
  }
};

export function setLoading(value) {
  return {
    type: SET_LOADING,
    payload: value,
  };
}
