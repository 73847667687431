import React from "react";
import styled, { keyframes } from "styled-components";

const StyledBadge = styled.div`
  position: relative;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 3px solid ${(p) => p.theme.colorDark};
  background: ${(p) => p.theme.colorSuccess};
`;

const pulse = keyframes`
  0% { opacity: 1; transform: scale(0);}
  50% { opacity: 0; transform: scale(3.5);}
  100% { opacity: 0; }
`;

const StyledBadgePulse = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  ${"" /* background: ${(p) => p.theme.colorSuccess}; */}
  border: 2px solid ${(p) => p.theme.colorSuccess};
  animation: ${pulse} 2s infinite linear;
`;

const BottombarBadge = (props) => (
  <StyledBadge {...props}>
    <StyledBadgePulse />
  </StyledBadge>
);

export default BottombarBadge;
