import styled from "styled-components";
import { MoneyText } from "components/shared";

const widthMobile = "50px";
const widthDesktop = "64px";

const StyledTitleBlock = styled.div`
  width: ${widthMobile};
  max-width: ${widthMobile};
  min-width: ${widthMobile};

  text-align: ${(p) => (p.isCentered ? "center" : "left")};

  @media (min-width: ${(props) => props.theme.screenWidthMd}) {
    width: ${widthDesktop};
    max-width: ${widthDesktop};
    min-width: ${widthDesktop};
  }
`;

const StyledTitleBlockLabel = styled.div`
  font-size: 0.575rem;
  opacity: 0.5;
  white-space: nowrap;
`;

const StyledTitleBlockValue = styled.div`
  font-size: ${(p) => (p.isSmallFont ? "0.75rem" : "0.875rem")};
  margin-bottom: 2px;
  white-space: nowrap;
`;

const Wrapper = ({
  label,
  value,
  valueHasSmallerFont,
  isMoney,
  isCentered,
}) => {
  return (
    <StyledTitleBlock isCentered={isCentered}>
      <StyledTitleBlockValue isSmallFont={valueHasSmallerFont}>
        {isMoney ? <MoneyText>{value}</MoneyText> : value}
      </StyledTitleBlockValue>
      <StyledTitleBlockLabel>{label}</StyledTitleBlockLabel>
    </StyledTitleBlock>
  );
};

export default Wrapper;
