import React from "react";
import styled from "styled-components";
import { BlockButton } from "components/shared";

const StyledWrapper = styled.div`
  background: ${(props) => props.theme.colorDark};
  color: white;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  transition: height 0.25s;
`;

const StyledInner = styled.div`
  padding: 1rem 1rem;
  font-size: 0.875rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledText = styled.div`
  flex: 1 1 auto;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.45;
  text-align: center;

  @media (min-width: ${(props) => props.theme.screenWidthMd}) {
    font-size: 0.875rem;
  }
`;

const StyledButton = styled.div`
  flex: 1 1 auto;
  margin-left: 1rem;
`;

const LandingTerritoryBlocked = () => {
  return (
    <StyledWrapper>
      <StyledInner>
        <StyledText>
          Sorry, but it looks like you are trying to access this app from a
          blocked country.
        </StyledText>
      </StyledInner>
    </StyledWrapper>
  );
};

export default LandingTerritoryBlocked;
