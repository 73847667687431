import { connect } from "react-redux";
import styled from "styled-components";
import { MatIcon, SkeletonTable, Overlay } from "fiver-react-ui-components";

import { toggleDrawer } from "./actions";
import DOMPurify from "dompurify";

const StyledWrapper = styled.div`
  position: fixed;
  z-index: 10;
  top: 0;
  right: 0;
  width: 80%;
  height: 100%;
  overflow: auto;
  background: white;
  transform: translateX(${(props) => (props.isOpen ? "0" : "100%")});
  transition: transform 0.35s;

  @media (min-width: ${(props) => props.theme.screenWidthSm}) {
    width: 30%;
  }

  @media (min-width: ${(props) => props.theme.screenWidthLg}) {
    width: 20%;
  }

  @media (min-width: ${(props) => props.theme.screenWidthXl}) {
    width: 15%;
  }
`;

const StyledHeader = styled.div`
  position: sticky;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 56px;
  padding: 0 1rem;
`;

const lineHeight = 1.45;

const StyledContent = styled.div`
  padding: 0 1rem 1rem;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: ${lineHeight};

  .footer-link {
    color: inherit;
    text-decoration: underline;
    font-size: inherit;
  }

  h2,
  h3 {
    line-height: ${lineHeight};
    margin: 0 0 1rem;
  }

  h2 {
    font-size: 1.175rem;
  }

  h3 {
    font-size: 1rem;
  }

  p {
    line-height: ${lineHeight};
  }

  a {
    color: ${(p) => p.theme.colorAccent};
    font-size: 0.875rem;
    line-height: ${lineHeight};
  }

  table {
    border-collapse: collapse;
    font-size: 0.75rem;
  }

  table td,
  table th {
    padding: 0.5rem 0.25rem;
    text-align: left;
    line-height: ${lineHeight};
    vertical-align: top;
    border: 1px solid ${(p) => p.theme.colorBorder};
  }

  ul {
    margin: 0 0 1rem;
    padding: 0 0 0 1.25rem;
  }

  ul li {
    line-height: ${lineHeight};
    margin-bottom: 0.5rem;
  }
`;

const Drawer = ({ drawer, page, toggleDrawer }) => {
  let pageContent = "";

  if (page?.content && !page?.isLegalPage) {
    pageContent = page?.content
      .replace(`**${page.title}**\n`, "")
      .replace(/\[[^\s]+\]/g, "")

      // .replace(
      //   /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim
      // )
      //
      // .replace(/(([a-zA-Z0-9\-\_\.])+@[a-zA-Z\_]+?(\.[a-zA-Z]{2,6})+)/gim)

      .replace(
        /\(?(https?:(\/\/)?([^\s\)\/]+))(\/?\)?)?/g,
        "<a href='$1' class='footer-link' target='_blank'>$3</a>"
      )
      .replace(
        /\(?((mailto:)?([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+))\)?/g,
        "<a href='$1' class='footer-link'>$3</a>"
      )
      .replace(/\n/gi, "<br />")
      .replace(/↵/gi, "<br />")
      .replace(/\*\*(.*?)\*\*/g, "<b>$1</b>");
  } else {
    pageContent = page?.content;
  }

  const clean = DOMPurify.sanitize(pageContent, {
    USE_PROFILES: { html: true },
  });

  const formattedContent = {
    __html: clean,
  };

  return (
    <>
      <StyledWrapper isOpen={drawer.isOpen}>
        {page && (
          <>
            <StyledHeader onClick={() => toggleDrawer(false)}>
              <span>{page && page.title}</span>
              <MatIcon>close</MatIcon>
            </StyledHeader>
            <StyledContent
              dangerouslySetInnerHTML={formattedContent}
            ></StyledContent>
          </>
        )}

        {!page && drawer.isOpen && <SkeletonTable />}
      </StyledWrapper>
      <Overlay
        isVisible={drawer.isOpen}
        zIndex={9}
        onClick={() => toggleDrawer(false)}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  drawer: state.drawer,
  page: state.drawer.page,
});

const mapActionsToProps = {
  toggleDrawer,
};

export default connect(mapStateToProps, mapActionsToProps)(Drawer);
