import { useSelector } from "react-redux";
import styled, { css } from "styled-components";
import { MatIcon } from "components/shared";

const successStyles = css`
  background: ${(p) => p.theme.colorSuccess};
  color: ${(p) => p.theme.colorSuccessText};
`;

const dangerStyles = css`
  background: ${(p) => p.theme.colorDanger};
  color: ${(p) => p.theme.colorDangerText};
`;

const StyledButton = styled.button`
  position: relative;
  display: block;
  width: 100%;
  height: 50px;
  padding: 0;
  border-radius: ${(p) => p.theme.borderRadius};
  margin-bottom: 0.5rem;
  background: ${(p) =>
    p.isSelected ? p.theme.colorAccent : p.theme.colorLightGrey};
  color: ${(p) => (p.isSelected ? p.theme.colorAccentText : p.theme.colorText)};

  &:disabled {
    cursor: not-allowed;

    ${(p) =>
      !p.isSelected &&
      `background: ${p.theme.colorLightGrey}; color: #cfd5dc;`};
    ${(p) => p.isSuccess && successStyles};
    ${(p) => p.isDanger && dangerStyles};
  }
`;

const StyledLockIcon = styled(MatIcon)`
  position: absolute;
  top: 50%;
  right: 1rem;
  font-size: 20px;
  transform: translateY(-50%);
`;

const ContestPicksButtons = ({
  questionId,
  questionOptions,
  updatePicks,
  correctAnswerId,
  myAnswerId,
  isLocked,
  showIcon,
}) => {
  const { hadBet } = useSelector((s) => s.toolbar);

  const onClick = (pickId, option) => {
    updatePicks(pickId, option);
  };

  return (
    <div>
      {questionOptions.map((option) => {
        const isRight = option.points > 0;
        const isAnswerRight = correctAnswerId === myAnswerId;
        const isWrong = !isRight;
        const isAnswerWrong = !isAnswerRight;
        const isSelected = myAnswerId === option.id;

        return (
          <StyledButton
            disabled={isLocked}
            isSuccess={
              hadBet && isLocked && showIcon && isRight && isAnswerRight
            }
            isDanger={hadBet && isLocked && showIcon && isWrong && isSelected}
            isSelected={isSelected}
            onClick={() => onClick(questionId, option)}
          >
            {option.label}
            {isSelected && (showIcon || isLocked) && (
              <StyledLockIcon>
                {showIcon && isRight && isAnswerRight
                  ? "check"
                  : showIcon && isWrong
                  ? "close"
                  : "lock"}
              </StyledLockIcon>
            )}
          </StyledButton>
        );
      })}
    </div>
  );
};

export default ContestPicksButtons;
