import { useFormikContext} from "formik";
import {useEffect} from "react";

const FormikEffect = ({ onChange }) => {
  const { values, errors, validateForm } = useFormikContext();
  
  useEffect(() => {
    validateForm(values).then(() => {onChange(values, errors)})
    
  }, [values, errors]);
  
  return null;
};

export default FormikEffect;