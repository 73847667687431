import styled from "styled-components";

import { TextStack } from "./";

import { DateTime } from "fiver-react-ui-components";

import { rgba } from "polished";

const widthWrapperMobile = "144px";

const StyledWrapper = styled.div`
  flex: 1 1 auto;
  position: relative;
  width: ${widthWrapperMobile};
  max-width: ${widthWrapperMobile};
  min-width: ${widthWrapperMobile};
  padding: 0.25rem 1rem;

  @media (min-width: ${(props) => props.theme.screenWidthMd}) {
  }
`;

const StyledTitle = styled.div`
  text-transform: uppercase;
  font-size: 0.875rem;
`;

const StyledDate = styled.div`
  font-size: 0.75rem;
  text-transform: uppercase;
  color: ${(p) => (p.isActive ? rgba("#ffffff", 0.66) : p.theme.colorLabel)};
`;

const Wrapper = ({ pickem, isActive }) => {
  return (
    <StyledWrapper>
      <StyledTitle
        dangerouslySetInnerHTML={{
          __html: pickem?.featuredTitle?.replace("-", "<br />"),
        }}
      ></StyledTitle>
      {/* <StyledDate isActive={isActive}>
        <DateTime format="D MMMM">{pickem.start}</DateTime>
      </StyledDate> */}
    </StyledWrapper>
  );
};

export default Wrapper;
