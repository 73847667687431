import styled from "styled-components";
import { Skeleton, Card } from "fiver-react-ui-components";

const StyledWrapper = styled.div`
  padding: 0 3rem 2rem;
  border-top: 2px solid ${(props) => props.theme.colorBorder};
  background: white;
`;

const StyledCard = styled(Card)`
  padding-top: 0;
  border-radius: ${(p) => p.theme.borderRadius};
`;

const StyledRow = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 1rem;
`;

const StyledLeft = styled.div`
  flex: 8;
  margin-right: 1rem;
`;

const StyledRight = styled.div`
  flex: 4;
`;

const StyledNumber = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  margin: 0 auto;
  font-weight: 700;
  border-radius: 50%;
  border: 5px solid ${(props) => props.theme.colorLightGrey};
  background: ${(props) => props.theme.colorSkeleton};
  color: white;
  transform: translateY(-25px);
`;

const ContestPicksSkeleton = (props) => (
  <StyledCard>
    <StyledNumber />
    <StyledRow>
      <StyledLeft>
        <div>
          <Skeleton
            skeletonHeight="20px"
            skeletonWidth="180px"
            margin="0 0 6px"
          />
        </div>
        <div>
          <Skeleton
            skeletonHeight="20px"
            skeletonWidth="120px"
            margin="0 0 6px"
          />
        </div>
        <div>
          <Skeleton
            skeletonHeight="20px"
            skeletonWidth="70px"
            margin="0 0 12px"
          />
        </div>
        <Skeleton
          skeletonHeight="12px"
          skeletonWidth="90px"
          skeletonBorderRadius="0.25rem"
          margin="0 0 16px"
        />
        <div>
          <Skeleton skeletonHeight="58px" skeletonWidth="100%" />
        </div>
      </StyledLeft>
      <StyledRight>
        <Skeleton
          skeletonHeight="90px"
          skeletonWidth="90px"
          margin="0 0 30px"
        />
        <Skeleton skeletonHeight="58px" skeletonWidth="100%" />
      </StyledRight>
    </StyledRow>
    <Skeleton skeletonHeight="50px" skeletonWidth="100%" margin="0 0 0.5rem" />
    <Skeleton skeletonHeight="50px" skeletonWidth="100%" />
  </StyledCard>
);

export default ContestPicksSkeleton;
