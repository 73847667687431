import { rgba } from "polished";

const colorLightGrey = process.env.REACT_APP_COLOR_LIGHT_GREY || "#ecf0f5";
const colorText = process.env.REACT_APP_COLOR_TEXT || "#626a75";
const colorLabel = process.env.REACT_APP_COLOR_LABEL || "#5b626b";

export const theme = {
  colorBackground: "#1b458f",
  colorContentBackgroundMobile:
    process.env.REACT_APP_COLOR_CONTENT_BACKGROUND || "transparent",
  colorContentBackground:
    process.env.REACT_APP_COLOR_CONTENT_BACKGROUND || colorLightGrey,
  colorTicketBackground: "white",
  colorTicketText: colorText,
  colorTicketLabel: colorLabel,
  colorPrimary: "#c8ccd1",
  colorPrimaryText: "white",
  colorAccent: "red",
  colorAccentText: "white",
  colorDark: "#040404",
  colorDarkText: "white",
  colorText: colorText,
  colorBorder: "#e7e7e7",
  colorLightGrey: colorLightGrey,
  colorBurgerTitleBackground: "#040404",
  colorBurgerTitleText: "white",
  colorSuccess: "#008044",
  colorSuccessText: "#ffffff",
  colorDanger: "#df3636",
  colorLabel: colorLabel,
  colorLabelHover: "#909aa8",
  colorCardBackground: "#ffffff",
  colorDisabledBackground: "#c8ccd1",
  colorDisabledText: "#a4acb6",
  colorNavbarLink: colorLabel,
  colorSkeleton: "#eceef1",
  colorSkeletonShimmer: "#e4e7ea",
  colorSpinnerBackground: "rgba(0, 0, 0, 0.1)",
  colorSpinnerBackgroundForDark: "rgba(255, 255, 255, 0.175)",
  colorAlertSuccessText: "#056136",
  colorAlertSuccessTextAlt: "#008044",
  colorAlertSuccessBackground: "#e1f1e6",
  colorAlertDefaultText: "#416ebb",
  colorAlertDefaultBackground: "#e9effb",
  colorAlertDangerText: "#f35959",
  colorAlertDangerBackground: "#ffe7e7",
  colorAlertDefaultText: "#416ebb",
  colorAlertDefaultBackground: "#e9effb",
  colorLeaderboardLight: "#9aa4b1",
  fontFamily: "Poppins",
  screenWidthXxs: "360px",
  screenWidthXs: "576px",
  screenWidthSm: "768px",
  screenWidthMd: "992px",
  screenWidthLg: "1200px",
  screenWidthXl: "1920px",
  screenWidthXxl: "2400px",
  railWidth: "500px",
  skeletonBorderRadius: process.env.REACT_APP_BORDER_RADIUS || "0.5rem",
  borderRadius: process.env.REACT_APP_BORDER_RADIUS || "0.5rem",
  floatingBarHeight: "3rem",
  topbarHeight: process.env.REACT_APP_IS_TOPBAR_HIDDEN !== "true" ? "56px" : 0,
  inputFontStyle: "normal",
  inputBorderStyle: "full",
  inputBorderRadius: process.env.REACT_APP_INPUT_BORDER_RADIUS || "0.5rem",
  inputFontSize: "1rem",
  inputFontWeight: "600",
  inputFontFamily: "Poppins",
  buttonUppercase: false,
  boxShadowCard: "none",
};
