import axios from "axios";
import { handleError } from "utils";
import { SET_LEADERBOARD } from "components/feature/leaderboard/actions";
import { setToolbar } from "components/layout/toolbar/actions";

export const refreshMyBet = (activeBetId) => async (dispatch) => {
  try {
    const activeBetSelectionsResponse = await axios.get(
      `/pickem-cached-bet/${activeBetId}`
    );

    const activeBet = activeBetSelectionsResponse?.data;

    dispatch(
      setToolbar({
        activeBet,
      })
    );
  } catch ({ response }) {
    handleError(response);
  }
};

export const handleLeaderboardUpdated = (data, activeBetId) => (
  dispatch,
  getState
) => {
  const pusherLeaderboardBets = data?.leaderboard?.data?.map(
    (obj) => obj.betId
  );

  const leaderboardBets = getState()
    .leaderboard?.table?.map((obj) => obj.betId)
    .slice(25);

  // check for leaderboard updates
  if (
    JSON.stringify(leaderboardBets) !== JSON.stringify(pusherLeaderboardBets) &&
    getState().toolbar?.activePickem?.status === "IN_PLAY"
  ) {
    console.log("leaderboard has changed", data);

    dispatch(refreshMyBet(getState().toolbar?.activeBet?.betId));
    dispatch({
      type: SET_LEADERBOARD,
      payload: { table: data.leaderboard.data },
    });
  } else {
    console.log("leaderboard DID NOT change");
  }

  // const question = activePickem.questions.find(
  //   (obj) => obj.id === pusherPickem.question.id
  // );
};

export const handleQuestionUpdated = (pusherPickem) => (dispatch, getState) => {
  console.log("handlePusherEvent", pusherPickem);

  const { question } = pusherPickem;

  const pusherMatchInfo = question.data;

  let hasMatchUpdates, hasGoalScored, hasGoalDisallowed;

  const activePickem = getState().toolbar?.activePickem;

  // update questions and options
  const activePickemQuestion = activePickem.questions.find(
    (obj) => obj.id === question?.id
  );

  activePickemQuestion?.options.forEach((item, i) => {
    item.points = question.options[i].points;
  });

  if (activePickem?.status !== pusherPickem?.status) {
    activePickem.status = pusherPickem?.status;
    hasMatchUpdates = true;
  }

  if (activePickem?.sportMatch?.matchTime !== pusherMatchInfo?.matchTime) {
    activePickem.sportMatch.matchTime = pusherMatchInfo?.matchTime;
    hasMatchUpdates = true;
  }

  if (activePickem?.sportMatch?.period !== pusherMatchInfo?.period) {
    activePickem.sportMatch.period = pusherMatchInfo?.period;
    hasMatchUpdates = true;
  }

  // check only for goal scored
  if (
    pusherMatchInfo?.homeScore > activePickem?.sportMatch?.homeScore ||
    pusherMatchInfo?.awayScore > activePickem?.sportMatch?.awayScore
  ) {
    hasGoalScored = true;
    console.log("GOAL SCORED!!", hasGoalScored);
  }

  if (
    pusherMatchInfo?.homeScore < activePickem?.sportMatch?.homeScore ||
    pusherMatchInfo?.awayScore < activePickem?.sportMatch?.awayScore
  ) {
    hasGoalDisallowed = true;
    console.log("VAR DISALLOWED!!", hasGoalDisallowed);
  }

  if (activePickem?.sportMatch?.homeScore !== pusherMatchInfo?.homeScore) {
    activePickem.sportMatch.homeScore = pusherMatchInfo?.homeScore;
    hasMatchUpdates = true;
  }

  if (activePickem?.sportMatch?.awayScore !== pusherMatchInfo?.awayScore) {
    activePickem.sportMatch.awayScore = pusherMatchInfo?.awayScore;
    hasMatchUpdates = true;
  }

  // const question = activePickem.questions.find(
  //   (obj) => obj.id === pusherPickem.question.id
  // );

  if (hasMatchUpdates) {
    console.log("There have been match updates!", pusherPickem);

    const activePickemList = getState().toolbar.activePickemList;

    // check for other pickems with same match
    const activePickemsWithSameMatch = activePickemList?.filter(
      (obj) => obj?.sportMatch?.id === activePickem?.sportMatch?.id
    );

    if (activePickemsWithSameMatch?.length) {
      for (let p of activePickemsWithSameMatch) {
        p.sportMatch.homeScore = activePickem.sportMatch.homeScore;
        p.sportMatch.awayScore = activePickem.sportMatch.awayScore;
        p.sportMatch.matchTime = activePickem.sportMatch.matchTime;
        p.sportMatch.period = activePickem.sportMatch.period;
      }
    }

    dispatch(
      setToolbar({
        hasGoalScored,
        hasGoalDisallowed,
        activePickem,
        isActivePickemInPlay: activePickem?.status === "IN_PLAY",
        isActivePickemApproved: activePickem?.status === "APPROVED",
        isActivePickemCompleted: activePickem?.status === "COMPLETED",
      })
    );

    if (getState().toolbar?.activeBet?.betId) {
      dispatch(refreshMyBet(getState().toolbar?.activeBet?.betId));
    }

    // if (getState()?.leaderboard?.activePickem) {
    // }
    //
    // if (getState()?.contest?.activePickem) {
    //   dispatch({
    //     type: SET_CONTEST,
    //     payload: { hasGoalScored, hasGoalDisallowed },
    //   });
    //   setTimeout(() => {
    //     dispatch({
    //       type: SET_CONTEST,
    //       payload: { activePickem },
    //     });
    //   }, 100);
    // }
  } else {
    console.log("NO MATCH UPDATES", pusherPickem);
  }
};
