import {
  SET_CONTEST,
  GET_PICKEMS,
  GET_PICKEMS_BETS,
  SET_PICKS,
  SET_IS_ALL_SELECTED,
} from "./actions";

const initialState = {
  isRefreshingToken: false,
  isContestEmpty: undefined,
  isAllSelected: undefined,
  picks: {},
  tiebreaker: undefined,
  xtremepushData: {},
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case SET_CONTEST:
    case GET_PICKEMS:
    case GET_PICKEMS_BETS:
    case SET_IS_ALL_SELECTED:
      return {
        ...state,
        ...payload,
      };
    case SET_PICKS:
      return {
        ...state,
        picks: payload,
      };
    default:
      return state;
  }
}
