import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, FormRow, BlockButton, Card, Alert } from "components/shared";
import { getCoolingOffDuration, setCoolingOffDuration } from "./actions";
import FormSelect from "../../shared/form/FormSelect";
import { MatIcon } from "components/shared";
import styled from "styled-components"

export const StyledMatIcon = styled(MatIcon)`
  position: absolute;
  right: 0.5rem;
  top: 50%;
  transform: translate(0, -50%);
  z-index: -1;
`

const TakeBreak = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCoolingOffDuration());
  }, []);

  const { coolingOffData } = useSelector((state) => state.auth);
  const [state, setState] = useState(
    coolingOffData ? coolingOffData?.duration + 1 : 1
  );

  const handleSelectChanged = (e) => {
    setState(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(setCoolingOffDuration({ duration: state }));
  };

  return (
    <Card style={{zIndex: 0}}>
      <Alert margin="0 0 1rem">
        If you want to give yourself a break from playing low6 for up to 6
        weeks, you can do so by selecting an option below.
      </Alert>
      <Form>
        <FormRow isSmall>
          <FormSelect
            autoFocus
            isRequired
            onChange={handleSelectChanged}
            name="duration"
            placeholder="1 day"
            value={state}
          >
            {new Array(42).fill(0).map((item, i) => {
              if (
                (coolingOffData && i + 1 > coolingOffData.duration) ||
                !coolingOffData
              ) {
                return (
                  <option value={i + 1}>
                    {i + 1 > 1 ? `${i + 1} days` : `${i + 1} day`}
                  </option>
                );
              } else {
                return null;
              }
            })}
          </FormSelect>
          <StyledMatIcon>expand_more</StyledMatIcon>
        </FormRow>
        {coolingOffData ? (
          <Alert margin="0 0 1rem" isDanger>
            {`You have chosen to take a break for ${coolingOffData.duration}
                ${
                  coolingOffData.duration > 1 ? "days" : "day"
                }. You can play again
                on ${coolingOffData.expiresAt}.`}
          </Alert>
        ) : null}

        <BlockButton onClick={handleSubmit}>SET TIME OUT</BlockButton>
      </Form>
    </Card>
  );
};

export default TakeBreak;
