import { TOGGLE_DRAWER, GET_PAGE, CLEAR_PAGE } from "./actions";

const initialState = {
  isOpen: false,
  title: "",
  page: undefined,
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case TOGGLE_DRAWER:
      return {
        ...state,
        ...payload,
      };
    case GET_PAGE:
      return {
        ...state,
        page: payload,
      };
    case CLEAR_PAGE:
      return {
        ...state,
        page: payload,
      };
    default:
      return state;
  }
}
