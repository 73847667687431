import styled, { css } from "styled-components";
import { useEffect, useState } from "react";
import { createImageCdnLink } from "@low6dev/react-utils/dist";

const hiddenStyles = css`
  opacity: 0;
  visibility: hidden;
`;

const customSplashBackgound = css`
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    width: 100%;
    height: 100%;
    transform: translateX(-50%);
    background: ${(p) => p.theme.colorDark}
      url(${createImageCdnLink(process.env.REACT_APP_SPLASH_BACKGROUND)});
    background-position: 50% 50%;
    background-size: ${process.env.REACT_APP_SPLASH_HAS_FULL_SCREEN_IMAGE ===
    "true"
      ? "contain"
      : "100%"};
    background-repeat: no-repeat;

    @media (min-width: ${(p) => p.theme.screenWidthMd}) {
      width: ${(p) => p.theme.railWidth};
    }
  }
`;

const StyledWrapper = styled.div`
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000000;
  display: flex;

  ${(p) => p.isHidden && hiddenStyles};

  ${process.env.REACT_APP_SPLASH_BACKGROUND && customSplashBackgound};

  transition-property: visibility, opacity;
  transition-duration: 0.35s;
`;

const StyledWrapperContent = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-width: 0;
`;

const StyledTopLogo = styled.img`
  height: 130px;
  margin-bottom: 230px;
`;

const StyledTagline = styled.div`
  color: #ffffff;
  font-size: 20px;
  line-height: 1.4;
  text-align: center;
  margin-top: 2rem;
  margin-bottom: 3rem;
  min-width: 0;
  max-width: 300px;
`;

const StyledSponsorLogo = styled.img`
  height: 64px;
`;

const Wrapper = () => {
  const [isLoaded, setLoaded] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setLoaded(true);
    }, 2000);
  }, []);

  return process.env.REACT_APP_SPLASH_TOP_LOGO ? (
    <StyledWrapper isHidden={isLoaded}>
      <StyledWrapperContent>
        <StyledTopLogo
          src={createImageCdnLink(process.env.REACT_APP_SPLASH_TOP_LOGO)}
        />
        <StyledTagline>{process.env.REACT_APP_SPLASH_TAGLINE}</StyledTagline>
        {process.env.REACT_APP_SPLASH_SPONSOR_LOGO &&
          process.env.REACT_APP_SPLASH_SPONSOR_LOGO !== "" && (
            <StyledSponsorLogo
              src={createImageCdnLink(
                process.env.REACT_APP_SPLASH_SPONSOR_LOGO
              )}
            />
          )}
      </StyledWrapperContent>
    </StyledWrapper>
  ) : null;
};

export default Wrapper;
