import React from "react";
import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import { MatIcon } from "components/shared";

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: ${process.env.REACT_APP_LANDING_BUTTONS_HORIZONTAL === "true"
    ? "row"
    : "column"};
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  width: ${process.env.REACT_APP_LANDING_BUTTONS_HORIZONTAL === "true"
    ? "80%"
    : "260px"};
  padding: 2rem 0;
`;

const sharedButtonStyles = css`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 72px;
  line-height: 1;
  font-weight: 600;
  font-size: 1.25rem;
  border-radius: ${(p) => p.theme.borderRadius};
  border: 4px solid ${(props) => props.theme.colorPrimaryText};
`;

const StyledButton = styled(Link)`
  ${sharedButtonStyles};
  color: ${(props) =>
    props.primary ? props.theme.colorPrimary : props.theme.colorPrimaryText};
  background: ${(props) =>
    props.primary ? props.theme.colorPrimaryText : "transparent"};
`;

const StyledDisabledButton = styled.div`
  position: relative;
  ${sharedButtonStyles};
  background: ${(props) => props.theme.colorDisabledBackground};
  color: ${(props) => props.theme.colorDisabledText};
  border: 4px solid ${(props) => props.theme.colorDisabledBackground};
`;

const StyledIcon = styled(MatIcon)`
  position: absolute;
  top: 50%;
  right: 1rem;
  transform: translateY(-50%);
`;

const LandingButtons = ({ isDisabled }) => (
  <StyledWrapper>
    {!isDisabled && (
      <>
        {process.env.REACT_APP_IS_SIGNUP_DISABLED !== "true" && (
          <StyledButton to="/signup" primary={1}>
            Sign up
          </StyledButton>
        )}
        <StyledButton to="/login">Login</StyledButton>
      </>
    )}
    {isDisabled && (
      <>
        {process.env.REACT_APP_IS_SIGNUP_DISABLED !== "true" && (
          <StyledDisabledButton>
            Sign up
            <StyledIcon>lock</StyledIcon>
          </StyledDisabledButton>
        )}
        <StyledDisabledButton>
          Login
          <StyledIcon>lock</StyledIcon>
        </StyledDisabledButton>
      </>
    )}
  </StyledWrapper>
);

export default LandingButtons;
