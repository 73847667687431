import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import {
  Wallet as AppWallet,
  WalletTickets,
  WalletVouchers,
} from "@low6dev/react-feature-wallet/dist";
import { handleError } from "utils";
import { Tabs } from "fiver-react-ui-components";
import { setToolbar } from "components/layout/toolbar/actions";

const StyledWrapper = styled.div`
  padding-bottom: 1rem;
`;

const StyledTabWrapper = styled.div`
  margin-bottom: 1rem;
`;

const StyledTicketsWrapper = styled.div`
  margin: 0 1rem;
`;

const Wallet = () => {
  const dispatch = useDispatch();

  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    dispatch(setToolbar({ isBetsOpen: false }));
  }, []);

  return (
    <StyledWrapper>
      <StyledTabWrapper>
        <Tabs
          tabs={["Wallet", "Tickets"]}
          onTabClicked={setActiveTab}
          activeTab={activeTab}
        />
      </StyledTabWrapper>
      {activeTab === 0 && (
        <AppWallet
          hideDeposit={process.env.REACT_APP_HIDE_WALLET_DEPOSIT === "true"}
          onError={handleError}
          currencySymbol={process.env.REACT_APP_CURRENCY_SYMBOL}
          showFonixDeposit={process.env.REACT_APP_SHOW_FONIX_DEPOSIT === "true"}
        />
      )}
      {activeTab === 1 && (
        <StyledTicketsWrapper>
          <WalletTickets
            prizeImageUrl="https://cdn.jsdelivr.net/gh/low6dev/fiver-react-assets@main/m4soldout.png"
            onError={handleError}
          />
        </StyledTicketsWrapper>
      )}
      {activeTab === 2 && <WalletVouchers />}
    </StyledWrapper>
  );
};

export default Wallet;
