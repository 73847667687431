import styled from "styled-components";
import { Card, MatIcon } from "components/shared";

const StyledWrapper = styled.div`
  padding: 0 0 3rem;
`;

const StyledCard = styled(Card)`
  ${(p) => p.isMinimal && "padding: 0; background: transparent;"};
  border-radius: ${(p) => p.theme.borderRadius};
`;

const StyledPrizeSplit = styled.div`
  text-align: center;
  line-height: 1.4;
  width: 300px;
  margin: 0 auto;
`;

const StyledPrizeList = styled.ul`
  list-style: none;
  padding: 0;
  text-align: center;
`;

const StyledPrizeListItem = styled.li`
  padding-bottom: 4px;
`;

const StyledLabel = styled.div`
  font-weight: 700;
  flex: 1 0 100%;
  display: flex;
  justify-content: center;
  text-align: center;
  line-height: 1.4;
  color: ${(props) => props.theme.colorLabel};
`;

const StyledFooterText = styled.div`
  flex: 1 0 100;
  font-size: 0.75rem;
  font-weight: 400;
  text-align: center;
  padding: 0 1rem
`;

const StyledPrizeIcon = styled(MatIcon)`
  font-size: 20px;
  padding-right: 5px;
`;

const NonMonetaryPrizing = ({ prizeText }) => {
    var separatedPrizes;
    var prizeList;

    if (prizeText != null) {
        separatedPrizes = prizeText.length ? prizeText.split(', ') : "Empty";

        prizeList = separatedPrizes.map((prize) => <StyledPrizeListItem>{prize}</StyledPrizeListItem>);
    } else {
        separatedPrizes = "";
    }

    return (
        <StyledWrapper>
            <StyledCard>
                {process.env.REACT_APP_HIDE_GAMBLING_REFERENCES && (
                    <>
                        <StyledLabel>
                            <StyledPrizeIcon>
                                {"emoji_events"}
                            </StyledPrizeIcon>
                            {separatedPrizes.length !== 0 ? "Today's Prizes" : "Prizes Coming Soon"}
                        </StyledLabel>
                        <StyledPrizeSplit>
                            {separatedPrizes.length !== 0 ? <StyledPrizeList>{prizeList}</StyledPrizeList> : null}
                        </StyledPrizeSplit>
                    </>
                )}

                {separatedPrizes.length !== 0 && (
                    <StyledFooterText>
                        Enter for a chance to win one of our prizes!
                    </StyledFooterText>
                )}
            </StyledCard>
        </StyledWrapper>
    )
};

export default NonMonetaryPrizing;