import React from "react";
import styled from "styled-components";
import {useState, useEffect} from "react";

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledLabel = styled.div`
  margin-left: 1rem;
`;

export const StyledIndex = styled.div`
  width: 1.5rem;
  height: 1.875rem;
  font-size: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #696971;
  position: relative;
  border: 1px solid #E4E4E4;
  border-radius: 2px;
  z-index: 0;
  margin-left: 0.25rem;
  background: white;
  &::before {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    bottom: 0;
    left: 0;
    right: 0;
    background: #f1f1f1;
    z-index: -1;
  }
`;

const PrizeTimeLeft = ({
  date,
}) => {
  const [time, setTime] = useState([]);
  const timeLeft = (data, callback) => {
    const counter = () => {
      const  countUpDate = new Date(date?.replace(' ', 'T')).getTime();
      const now = new Date().getTime();
      const distance = countUpDate - now;
      
      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      callback(
          [
        `${days ? `${days > 9 ? days : `0${days}`}:` : `00:`}`,
        `${hours ? `${hours > 9 ? hours : `0${hours}`}:` : `00:`}`,
        `${minutes ? `${minutes > 9 ? minutes : `0${minutes}`}:` : `00:`}`
      ]
      )
    }
    
    const id = setInterval(counter, 1000)
    counter()
    return id;
  }


  useEffect(() => {
    const id = timeLeft(date, setTime);
    return () => {
      clearInterval(id)
    }
  }, []);

  return (
    <StyledWrapper>
      {/*time left  <StyledSpan>{time}</StyledSpan>*/}
      days <StyledIndex>{time[0]?.charAt(0)}</StyledIndex><StyledIndex>{time[0]?.charAt(1)}</StyledIndex>
      <StyledLabel>hrs</StyledLabel><StyledIndex>{time[1]?.charAt(0)}</StyledIndex><StyledIndex>{time[1]?.charAt(1)}</StyledIndex>
      <StyledLabel>mins</StyledLabel> <StyledIndex>{time[2]?.charAt(0)}</StyledIndex><StyledIndex>{time[2]?.charAt(1)}</StyledIndex>
    </StyledWrapper>
  );
};


export default PrizeTimeLeft;