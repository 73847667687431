import React from "react";
import styled from "styled-components";
import {formatter, StyledPrizeDrawButton} from "./ConfirmStep";
import {MatIcon} from "../../shared";
import PrizeButton from "./PrizeButton";
import PrizeDrawSmallText from "./PrizeDrawSmallText";
import {StyledDetailsHeader, StyledNotification} from "./NotEnoughStep";
import PrizeForm from "./PrizeForm";
import {StyledMatIcon} from "../auth/TakeBreak";

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
`;


const StyledStep3 = ({
  activeStep,
  selected,
  amount,
  setAmount,
  setSelected,
  countTotal,
  contest,
  availableFunds,
}) => {
  return  (
    <StyledWrapper>
      <StyledDetailsHeader>
        NEARLY <br/> THERE...
      </StyledDetailsHeader>
      
      <StyledNotification style={{marginBottom: '4rem'}}>
        Just provide us with some details to ensure a chance at winning!
      </StyledNotification>
      
      <PrizeForm />
      
      <StyledPrizeDrawButton style={{marginTop: 'auto', minHeight: '3rem'}}>
        Enter Now
        <StyledMatIcon>arrow_forward_ios</StyledMatIcon>
      </StyledPrizeDrawButton>
      <PrizeDrawSmallText contest={contest} />
    </StyledWrapper>
  );
};


export default StyledStep3;
