import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {formatter, StyledPrizeDrawButton} from "./ConfirmStep";
import PrizeDrawSmallText from "./PrizeDrawSmallText";
import {StyledMatIcon} from "../auth/TakeBreak";
import PrizeDepositForm from "./PrizeDepositForm";
import {Spinner} from "../../shared";

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
`;

const StyledBalance = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 2rem;
  margin-bottom: 1.25rem;
  align-items: center;
`;


const DepositStep = ({
  contest,
  availableFunds,
  isLoading,
  depositParams,
  depositErrors,
  setDepositParams,
  setDepositErrors,
  setShowConfirmDeposit,
}) => {
  return  (
    <StyledWrapper>
      <StyledBalance>
        Current Balance
        <span>{formatter.format(availableFunds / 100)}</span>
      </StyledBalance>
      
      <PrizeDepositForm setDepositParams={setDepositParams} setDepositErrors={setDepositErrors}  />
      
      <StyledPrizeDrawButton
        style={{marginTop: 'auto', minHeight: '3rem'}}
        disabled={depositErrors?.amount || depositErrors?.password || !depositParams?.amount || !depositParams?.password}
        onClick={() => setShowConfirmDeposit(true)}
      >
        {!isLoading && <>Enter Now<StyledMatIcon>arrow_forward_ios</StyledMatIcon></>}
        {isLoading && <Spinner lineWidth={4} />}
      </StyledPrizeDrawButton>
      <PrizeDrawSmallText margin="0 0 1rem" contest={contest} />
    </StyledWrapper>
  );
};


export default DepositStep;
