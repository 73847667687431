import styled, { css } from "styled-components";

import { useState } from "react";

import { useSelector, useDispatch } from "react-redux";

import axios from "axios";

import {
  Overlay,
  Alert,
  FormCheckbox,
  BlockButton,
} from "fiver-react-ui-components";

import { handleError } from "utils";

import { setAuth } from "./actions";

const StyledWrapper = styled.div``;

const visibleStyles = css`
  visibility: visible;
  opacity: 1;
`;

const StyledPopup = styled.div`
  position: fixed;
  z-index: 26;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 94%;
  visibility: hidden;
  opacity: 0;
  background: white;
  padding: 2rem;
  border-radius: ${(p) => p.theme.borderRadius};

  ${(p) => p.isVisible && visibleStyles};

  transition: all 0.35s;

  @media (min-width: ${(props) => props.theme.screenWidthMd}) {
    width: calc(${(p) => p.theme.railWidth} - 60px);
  }
`;

const StyledPopupTitle = styled.div`
  margin-bottom: 1rem;
`;

const StyledPopupContent = styled.div`
  margin-bottom: 2rem;
  font-weight: 400;
  line-height: 1.35;

  & > a {
    text-decoration: underline;
    color: ${(p) => p.theme.colorSuccess};
  }
`;

const Wrapper = () => {
  const dispatch = useDispatch();

  const { isShowingConsentPopup } = useSelector((s) => s.auth);

  const [isConfirmed, setConfirmed] = useState(false);
  const [isSaving, setSaving] = useState(false);

  const handleConfirm = async () => {
    setSaving(true);

    try {
      const get = await axios.get("/me");

      const params = get.data.data;

      params.contactPermissions.inAppNotificationsAccepted = isConfirmed;

      // console.log("profile", profile);
      // //
      // const params = {
      //   ...profile,
      //   contactPermissions: {
      //     inAppNotificationsAccepted: isConfirmed,
      //   },
      // };
      //
      const res = await axios.put("/me", params);
      //
      dispatch(
        setAuth({
          isShowingConsentPopup: false,
        })
      );

      setSaving(false);
    } catch ({ response }) {
      handleError(response);
    }
  };

  const handleChange = (e) => {
    setConfirmed(e.target.checked);
  };

  return (
    <>
      <Overlay zIndex="25" isVisible={isShowingConsentPopup} />
      <StyledPopup isVisible={isShowingConsentPopup}>
        <StyledPopupTitle>Data Sharing Consent</StyledPopupTitle>
        <StyledPopupContent>
          Please confirm if you are happy for us to share your name and email
          address with The Jockey Club, for the purpose of enabling The Jockey
          Club to email you about their products and services.
          <br />
          <br />
          Please note if you no longer wish to receive emails from The Jockey
          Club, you will need to contact The Jockey Club directly{" "}
          <a href="https://www.thejockeyclub.co.uk/privacy/" target="_blank">
            using the contact details here
          </a>
          .
        </StyledPopupContent>
        <FormCheckbox
          name="isConfirmed"
          checked={isConfirmed}
          onChange={handleChange}
          label="I confirm my consent"
          alignItems="center"
        />
        <br />
        <br />
        <BlockButton isLoading={isSaving} onClick={handleConfirm}>
          Confirm
        </BlockButton>
      </StyledPopup>
    </>
  );
};

export default Wrapper;
