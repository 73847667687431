import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {
  BannerControls,
  createPrizeImageCdnLink,
  StyledAmount,
  StyledDateCounter,
  StyledTotal,
  StyledTotalIndex
} from "./PrizeDraw";
import PrizeButton from "./PrizeButton";
import PrizeTimeLeft from "./PrizeTimeLeft";
import BlockButton from "../../shared/button/BlockButton";
import {MatIcon} from "../../shared";
import ConfirmStep, {formatter} from "./ConfirmStep";
import NotEnoughStep from "./NotEnoughStep";
import StyledStep3 from "./StyledStep3";
import ConfirmModal from "./ConfirmModal";
import {StyledMatIcon} from "../auth/TakeBreak";
import DepositStep from "./DepositStep";
import axios from "axios";
import {toast} from "react-toastify";

const StyledWrapper = styled.div`
  position: fixed;
  z-index: 5;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  overflow: hidden;
  background: white;
  transform: translateX(${(props) => (props.isOpen ? "0" : "100%")});
  transition: transform 0.35s;
  display: flex;
  flex-direction: column;
  @media (min-width: ${(props) => props.theme.screenWidthXs}) {
    width: 70vw;
  }
  
  @media (min-width: ${(props) => props.theme.screenWidthSm}) {
    width: 60vw;
  }
  
  @media (min-width: ${(props) => props.theme.screenWidthMd}) {
      width: 400px;
  }
`;

const StyledBackgroundWrapper = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  overflow: hidden;
  z-index: 0;
`;

const StyledBackground = styled.div`
  height: 200vh;
  width: 150vw;
  position: absolute;
  z-index: 0;
  background: #f1efef;
  transform:  rotate(13deg) translate(0, -50%);
  transform-origin: 0 0;
  left: 50%;
  top: 50%;
`;

const StyledDrawerContent = styled.div`
  position: relative;
  z-index: 1;
  width: 100%;
  flex: 1 1 auto;
  overflow: auto;
  padding: 3rem 1rem 0;
`;

const StyledControls = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 1.375rem 1.125rem 0;
  position: relative;
  z-index: 2;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
`;

const StyledCloseControl = styled.div`
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
`;

const StyledDepositControl = styled.div`
  position: relative;
  width: 100%;
  text-align: center;
`;

const StyledDepositIcon = styled(MatIcon)`
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(0, -50%);
  cursor: pointer;
  z-index: 2;
  width: 1.5rem;
  height: 1.5rem;
`;

const PrizeDraw = ({
  toggleDrawer,
  isOpen,
  amount,
  setAmount,
  contest,
  wallet,
  finalPrice,
  isLoading,
  setIsLoading,
}) => {
  const [selected, setSelected] = useState(undefined)
  const [activeStep, setActiveStep] = useState(1)
  const [showConfirm, setShowConfirm] = useState(false)
  const [showConfirmDeposit, setShowConfirmDeposit] = useState(false)
  const [confirm, setConfirm] = useState(false)
  const [confirmDeposit, setConfirmDeposit] = useState(false)
  const [depositParams, setDepositParams] = useState(undefined);
  const [depositErrors, setDepositErrors] = useState(undefined);

  useEffect(() => {
    if (confirm) {
      buyTickets()
    } else if (confirmDeposit) {
      buyTicketsWithDeposit()
    }
  }, [confirm, confirmDeposit])
  
  const buyTickets = async () => {
    setIsLoading(true)

    await axios.post(
        `/prize_draw/game/ticket/buy?answer_array[${contest.game_id}]=${selected}&qty=${amount}`
    ).then(
      res => {
        setAmount(1)
        toggleDrawer(false)
        setSelected(undefined)
        toast.success('You successfully bought tickets.')
        window.xtremepush("tag", "PrizeDraw.Enter", JSON.stringify({
          AppName: process.env.REACT_APP_TITLE,
          PrizeDrawName: contest?.name,
        }));
        window.xtremepush("event", "PrizeDraw.Enter", JSON.stringify({
          AppName: process.env.REACT_APP_TITLE,
          PrizeDrawName: contest?.name,
        }));
    }).catch(err => {
      if(err?.response?.data?.code === 'insufficientFunds') {
        setActiveStep(2)
      } else if (err?.response?.data?.code === 'max_user_tickets') {
        toast.error(`
            ${err?.response?.data?.message}. You can purchase
            ${err?.response?.data?.data?.tickets?.limit - err?.response?.data?.data?.tickets?.previously_bought } more tickets.`)
        setAmount(err?.response?.data?.data?.tickets?.limit - err?.response?.data?.data?.tickets?.previously_bought)
        toggleDrawer(false)
      } else if (err?.response?.data?.code === 'max_game_tickets') {
        toast.error(`
            ${err?.response?.data?.message}. You can purchase
            ${err?.response?.data?.data?.tickets?.limit - err?.response?.data?.data?.tickets?.tickets_sold } more tickets.`)
        setAmount(err?.response?.data?.data?.tickets?.limit - err?.response?.data?.data?.tickets?.tickets_sold)
        toggleDrawer(false)
      } else if (err?.response?.data?.message){
        toast.error(err?.response?.data?.message)
      } else {
        toast.error(err?.message)
      }
    })
    setIsLoading(false)
    setConfirm(false)
  }
  
  const buyTicketsWithDeposit = async () => {
    setIsLoading(true)

    await axios.post(`/wallet/deposit`, {
      amount: depositParams.amount * 100,
      password: depositParams.password,
    })
    .then(async res => {
      toast.success(`You successfully deposited ${formatter.format(depositParams.amount)}.`)
      window.xtremepush("tag", "Wallet.Deposit", JSON.stringify({
        AppName: process.env.REACT_APP_TITLE
      }));
      await axios.post(
          `/prize_draw/game/ticket/buy?answer_array[${contest.game_id}]=${selected}&qty=${amount}`
      ).then(
        res => {
          setAmount(1)
          toggleDrawer(false)
          setActiveStep(1)
          setSelected(undefined)
          toast.success('You successfully bought tickets.')
          window.xtremepush("tag", "PrizeDraw.Enter", JSON.stringify({
            AppName: process.env.REACT_APP_TITLE,
            PrizeDrawName: contest?.name,
          }));
          window.xtremepush("event", "PrizeDraw.Enter", JSON.stringify({
            AppName: process.env.REACT_APP_TITLE,
            PrizeDrawName: contest?.name,
          }));
      }).catch(err => {
        if(err?.response?.data?.code === 'insufficientFunds') {
          setActiveStep(2)
        } else if (err?.response?.data?.code === 'max_user_tickets') {
          toast.error(`
            ${err?.response?.data?.message}. You can purchase
            ${err?.response?.data?.data?.tickets?.limit - err?.response?.data?.data?.tickets?.previously_bought } more tickets.`)
          setAmount(err?.response?.data?.data?.tickets?.limit - err?.response?.data?.data?.tickets?.previously_bought)
          toggleDrawer(false)
        } else if (err?.response?.data?.code === 'max_game_tickets') {
          toast.error(`
            ${err?.response?.data?.message}. You can purchase
            ${err?.response?.data?.data?.tickets?.limit - err?.response?.data?.data?.tickets?.tickets_sold } more tickets.`)
          setAmount(err?.response?.data?.data?.tickets?.limit - err?.response?.data?.data?.tickets?.tickets_sold)
          toggleDrawer(false)
        } else if (err?.response?.data?.message){
          toast.error(err?.response?.data?.message)
        } else {
          toast.error(err?.message)
        }
      })
    }).catch(err => {
      if (err?.response?.data?.message) {
        toast.error(err?.response?.data?.message)
      } else {
        toast.error(err?.message)
      }
    })
    setIsLoading(false)
    setConfirmDeposit(false)
  }

  return contest  ? (
    <StyledWrapper isOpen={isOpen}>
      <StyledControls>
        {
          activeStep === 3 ? (
            <StyledDepositControl>
              <div onClick={() => setActiveStep(2)}>
                <StyledDepositIcon>
                  arrow_back_ios
                </StyledDepositIcon>
              </div>
              Deposit
            </StyledDepositControl>
          ) : (
            <StyledCloseControl
              onClick={() => toggleDrawer(false)}
            >
              Cancel
            </StyledCloseControl>
          )
        }
      </StyledControls>
      
      <StyledBackground />
      <StyledDrawerContent>
        {activeStep === 1 && (
          <ConfirmStep
            selected={selected}
            amount={amount}
            setAmount={setAmount}
            setSelected={setSelected}
            contest={contest}
            finalPrice={finalPrice}
            isLoading={isLoading}
            setShowConfirm={setShowConfirm}
          />
        )}
        
        {activeStep === 2 && (
          <NotEnoughStep
            availableFunds={wallet.balance}
            amount={amount}
            setAmount={setAmount}
            contest={contest}
            finalPrice={finalPrice}
            isLoading={isLoading}
            setActiveStep={setActiveStep}
            setShowConfirm={setShowConfirm}
          />
        )}
        
        {activeStep === 3 && (
          <DepositStep
            availableFunds={wallet.balance}
            contest={contest}
            isLoading={isLoading}
            depositParams={depositParams}
            depositErrors={depositErrors}
            setDepositParams={setDepositParams}
            setDepositErrors={setDepositErrors}
            setShowConfirmDeposit={setShowConfirmDeposit}
          />
        )}
  
        {/*{activeStep === 4 && (*/}
        {/*    <StyledStep3*/}
        {/*        availableFunds={wallet.balance}*/}
        {/*        amount={amount}*/}
        {/*        setAmount={setAmount}*/}
        {/*        contest={contest}*/}
        {/*    />*/}
        {/*)}*/}
      </StyledDrawerContent>
      {
        showConfirm ? (
          <ConfirmModal
            finalPrice={finalPrice}
            setConfirm={setConfirm}
            setShowConfirm={setShowConfirm}
          />
        ) : null
      }
      {
        showConfirmDeposit ? (
          <ConfirmModal
            finalPrice={finalPrice}
            setConfirm={setConfirmDeposit}
            setShowConfirm={setShowConfirmDeposit}
          />
        ) : null
      }
      
    </StyledWrapper>
  ) : null;
};

export default PrizeDraw;
