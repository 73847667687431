import React, { useState } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { validateEmail } from "utils";
import {
  Form,
  FormRow,
  FormRowMessage,
  FormTextbox,
  BlockButton,
  AppIcon,
  Card,
} from "components/shared";
import { forgotPassword } from "./actions";

const StyledLogoRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem 0;
`;

const ForgotPassword = ({ forgotPassword, passwordResetSent }) => {
  const [state, setState] = useState({
    email: undefined,
  });

  const formIsValid =
    state.email && state.email !== "" && validateEmail(state.email);

  const handleTextboxChanged = (e) => {
    const target = e.target;
    setState({
      ...state,
      [target.name]: target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    forgotPassword(state.email);
  };

  return (
    <Card>
      <Form>
        <StyledLogoRow>
          <AppIcon size="120px" />
        </StyledLogoRow>
        <FormRow>
          <FormTextbox
            isError={state.email && !formIsValid}
            onChange={handleTextboxChanged}
            name="email"
            placeholder="Enter your email address"
          />
        </FormRow>
        <FormRowMessage
          margin="0 0 1rem"
          isVisible={state.email && !formIsValid}
        >
          Email address not valid
        </FormRowMessage>
        <FormRowMessage
          isSuccess
          margin="0 0 1rem"
          isVisible={passwordResetSent}
        >
          Password reset email sent
        </FormRowMessage>
        <FormRow>
          <BlockButton
            disabled={!formIsValid || passwordResetSent}
            onClick={handleSubmit}
          >
            Reset Password
          </BlockButton>
        </FormRow>
      </Form>
    </Card>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  passwordResetSent: state.auth.passwordResetSent,
});

const mapActionsToProps = {
  forgotPassword,
};

export default connect(mapStateToProps, mapActionsToProps)(ForgotPassword);
