import styled, { css } from "styled-components";

const activeStyles = css`
  background: ${(p) =>
    p.theme.colorPrimary === p.theme.colorDark ? "#040404" : p.theme.colorDark};
  color: ${(p) => p.theme.colorDarkText};
  cursor: default;
`;

const StyledRow = styled.button`
  position: relative;
  overflow: hidden;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 3rem;
  width: 100%;
  max-width: 100%;
  text-align: left;
  border-top: ${(p) => !p.isActive && "1px solid #ededed"};

  ${(p) => p.isActive && activeStyles};
`;
const Wrapper = ({ children, isActive, onClick }) => {
  return (
    <StyledRow onClick={onClick} disabled={isActive} isActive={isActive}>
      {children}
    </StyledRow>
  );
};

export default Wrapper;
