import { useEffect } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import { setGeoLocation } from "../components/feature/app/actions";

const useGetLocation = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    (async () => {
      try {
        const geoLocationData = await axios.get(
          `https://pro.ip-api.com/json/?key=${process.env.REACT_APP_IP_API_KEY}`
        );
        dispatch(setGeoLocation(geoLocationData.data));
      } catch (error) {
        dispatch(setGeoLocation(null));
      }
    })();
  }, []);
};

export default useGetLocation;
