import React from "react";
import styled from "styled-components";
import { TextStack, FootballMatch, EntryButton, SportEvent } from "./shared";
import { ordinalSuffix } from "utils";

const StyledEntryButtonPlaceholder = styled.div`
  flex: 1 0 auto;
  width: ${(p) => p.widthPlaceholder};
  max-width: ${(p) => p.widthPlaceholder};
  min-width: ${(p) => p.widthPlaceholder};
  display: flex;
`;

const Wrapper = ({ pickem, isActive }) => {
  return (
    <>
      {process.env.REACT_APP_IS_EVENT_BASED_SPORT !== "true" && (
        <FootballMatch showLogos pickem={pickem} isActive={isActive} />
      )}
      {process.env.REACT_APP_IS_EVENT_BASED_SPORT === "true" && (
        <SportEvent pickem={pickem} isActive={isActive} />
      )}

      <TextStack
        label="Winnings"
        value={
          pickem?.activeBet?.prize ||
          (pickem?.activeBet?.hasWinTieBreaker ? "Prize" : "-")
        }
        isMoney={pickem?.activeBet?.prize}
        isCentered
      />
      <TextStack
        label="Rank"
        isCentered
        value={
          pickem?.activeBet?.rank
            ? process.env.REACT_APP_BETBAR_HIDE_TOTAL_BETS === "true"
              ? `${pickem?.activeBet?.rank}`
              : `${pickem?.activeBet?.rank}/${pickem?.betsCount}`
            : "-"
        }
      />
      <TextStack
        label="Points"
        value={pickem?.activeBet?.points > -1 ? pickem?.activeBet?.points : "-"}
        isCentered
      />
    </>
  );
};

export default Wrapper;
