import styled, { css } from "styled-components";

import { rgba } from "polished";

import { MatIcon } from "fiver-react-ui-components";

import {
  QuestionTypePoints,
  QuestionTypeButtons,
  QuestionTypePopular,
  QuestionTypeTips,
} from "../shared";

const StyledRow = styled.div`
  display: flex;
`;

const StyledLeft = styled.div`
  flex: 1 1 auto;
  min-width: 1px;
`;

const StyledRight = styled.div`
  flex: 0 0 35%;

  @media (min-width: ${(props) => props.theme.screenWidthXs}) {
    flex: 0 0 130px;
  }
`;

const StyledWrapper = styled.div``;

const iconSize = "24px";

const iconStyles = css`
  flex: 0 0 ${iconSize};
  width: ${iconSize};
  height: ${iconSize};
  display: grid;
  place-items: center;
`;

const StyledQuestionTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  background: ${(p) => p.theme.colorPrimary};
  color: ${rgba("white", 0.96)};
  margin-bottom: 1rem;
`;

const StyledQuestionTitleIcon = styled.div`
  ${iconStyles};
  background: ${rgba("white", 0.96)};
  color: ${(p) => p.theme.colorPrimary};
`;

const StyledQuestionTitleText = styled.span``;

const StyledQuestionSubtitle = styled.div`
  display: flex;
  gap: 1rem;
  padding: 1rem;
  margin-bottom: 1rem;

  background: ${(p) => p.theme.colorLightGrey};
`;

const StyledQuestionSubtitleIcon = styled.div`
  ${iconStyles};
  background: ${(p) => p.theme.colorPrimary};
  color: ${(p) => p.theme.colorPrimaryText};
`;

const StyledQuestionSubtitleText = styled.span`
  line-height: 1.35;
  font-size: 0.875rem;
`;

const Wrapper = ({
  questionTitle,
  questionSubtitle,
  popularSelectionString,
  correctAnswerId,
  myAnswerId,
  isLocked,
  showIcon,
  availablePoints,
  tips,
  tipsterString,
  id,
  updatePicks,
  options,
}) => {
  return (
    <>
      <StyledWrapper>
        <StyledQuestionSubtitle>
          <StyledQuestionSubtitleIcon>i</StyledQuestionSubtitleIcon>
          <StyledQuestionSubtitleText>
            {questionSubtitle}
          </StyledQuestionSubtitleText>
        </StyledQuestionSubtitle>
        <StyledQuestionTitle>
          <StyledQuestionTitleIcon>?</StyledQuestionTitleIcon>
          <StyledQuestionTitleText>{questionTitle}</StyledQuestionTitleText>
        </StyledQuestionTitle>
      </StyledWrapper>

      <StyledRow style={{ marginBottom: "1rem" }}>
        <StyledLeft>
          <QuestionTypePopular popularSelection={popularSelectionString} />
        </StyledLeft>
        <StyledRight>
          <QuestionTypePoints
            correctAnswerId={correctAnswerId}
            myAnswerId={myAnswerId}
            isLocked={isLocked}
            showIcon={showIcon}
            availablePoints={availablePoints}
          />
        </StyledRight>
      </StyledRow>

      {tips && tips[0] && (
        <QuestionTypeTips tip={tips[0]} tipsterString={tipsterString} />
      )}

      <QuestionTypeButtons
        correctAnswerId={correctAnswerId}
        myAnswerId={myAnswerId}
        isLocked={isLocked}
        showIcon={showIcon}
        questionId={id}
        updatePicks={updatePicks}
        questionOptions={options}
      />
    </>
  );
};

export default Wrapper;
