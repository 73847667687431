import React, { useEffect } from "react";
import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import { isAndroid } from "react-device-detect";
import { MatIcon, LogoBadge } from "components/shared";
import { Low6Button } from "fiver-react-ui-components";
import { darken, rgba } from "polished";
import { createImageCdnLink } from "utils";

const StyledWrapper = styled.div`
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: ${(props) => props.theme.colorBurgerTitleBackground};
  transform: translateX(${(props) => (props.isOpen ? "0" : "-100%")});
  transition: transform 0.475s;

  @media (min-width: ${(props) => props.theme.screenWidthSm}) {
    width: 30%;
  }

  @media (min-width: ${(props) => props.theme.screenWidthLg}) {
    width: 20%;
  }

  @media (min-width: ${(props) => props.theme.screenWidthXl}) {
    width: 15%;
  }
`;

const StyledOverlay = styled.div`
  position: fixed;
  z-index: 9;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.25);
  opacity: ${(props) => (props.isOpen ? 1 : 0)};
  visibility: ${(props) => (props.isOpen ? "visible" : "hidden")};
  transition-property: opacity, visibility;
  transition-duration: 0.35s;
`;

const StyledLogoRow = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 56px;
  width: 100%;
`;

const StyledCloseButton = styled.button`
  position: absolute;
  top: 0;
  left: 4px;
  color: ${(props) => props.theme.colorPrimaryText};
  width: 56px;
  height: 56px;
  padding: 0;
`;

const StyledLinkList = styled.div`
  background: ${(p) => darken(0.025, p.theme.colorDark)};
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 3rem 0;

  @media (min-width: ${(props) => props.theme.screenWidthMd}) {
    padding: 6rem 0;
  }
`;

const sharedStyles = css`
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: ${(p) => rgba(p.theme.colorBurgerTitleText, 0.95)};
  padding: 0 5rem;
  font-size: 1.25rem;
  letter-spacing: 1px;
  white-space: nowrap;
  ${"" /* background: ${rgba("white", 0.05)}; */}
  ${"" /* margin-bottom: 6px; */}
`;

const StyledLink = styled(Link)`
  ${sharedStyles};
`;

const StyledButton = styled.button`
  padding: 0;
  ${sharedStyles};
`;
const StyledLogo = styled.img`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
`;
const StyledLow6Wrapper = styled.div`
  padding: 1rem;
  background: ${(props) => props.theme.colorDark};
  width: 100%;
`;

const items = [
  {
    name:
      process.env.REACT_APP_TEXT_PICKS_TO_CHOICE === "true"
        ? "Choices"
        : "Picks",
    to: process.env.REACT_APP_PICKS_SLUG || "/picks",
    icon: "format_list_numbered",
  },
  {
    name: "Leaderboard",
    to: "/leaderboard",
    icon: "emoji_events",
  },
];

if (process.env.REACT_APP_BURGER_HIDE_WALLET !== "true") {
  items.push({
    name: "Wallet",
    to: "/wallet",
    icon: "account_balance_wallet",
  });
}

if (process.env.REACT_APP_BURGER_HIDE_PROFILE !== "true") {
  items.push({
    name: "Profile",
    to: "/profile",
    icon: "person",
  });
}

if (process.env.REACT_APP_BURGER_HIDE_PRIZEDRAW !== "true") {
  items.push({
    name: "Prize Draw",
    icon: "",
    onClick: () => {
      window.open("https://winme.tv", "_blank");
    },
  });
}

items.push({
  name: "Logout",
  icon: "exit_to_app",
  onClick: () => {
    localStorage.removeItem("fiver_react_access_token");
    localStorage.removeItem("fiver_react_refresh_token");

    window.location.href = "/";
  },
});

const TopbarBurger = ({ isOpen, toggleIsOpen, logout }) => {
  const handleButtonClick = (onClick) => {
    toggleIsOpen(false);
    onClick();
  };

  return (
    <>
      <StyledOverlay onClick={() => toggleIsOpen(false)} isOpen={isOpen} />
      <StyledWrapper toggleIsOpen={toggleIsOpen} isOpen={isOpen}>
        <StyledLogoRow>
          <StyledCloseButton onClick={() => toggleIsOpen(false)}>
            <MatIcon size="50px">close</MatIcon>
          </StyledCloseButton>
          <StyledLogo
            height="56px"
            width="auto"
            src={createImageCdnLink(
              process.env.REACT_APP_BURGER_LOGO ||
                process.env.REACT_APP_MAIN_LOGO
            )}
          />
        </StyledLogoRow>
        <StyledLinkList>
          {items.map(({ name, to, icon, onClick }) =>
            to ? (
              <StyledLink
                key={name}
                onClick={() => toggleIsOpen(false)}
                to={to}
              >
                {name}
              </StyledLink>
            ) : (
              <StyledButton
                key={name}
                onClick={() => handleButtonClick(onClick)}
              >
                {name}
              </StyledButton>
            )
          )}
        </StyledLinkList>
        <StyledLow6Wrapper>
          <Low6Button
            ctaUrl={process.env.REACT_APP_CTA_URL}
            ctaText={process.env.REACT_APP_CTA_TEXT}
            ctaIconUrl={
              process.env.REACT_APP_CTA_ICON
                ? createImageCdnLink(process.env.REACT_APP_CTA_ICON)
                : null
            }
          />
        </StyledLow6Wrapper>
      </StyledWrapper>
    </>
  );
};

export default TopbarBurger;
