import React from "react";
import styled from "styled-components";
import { Skeleton } from "components/shared";
import { ClubName } from "fiver-react-ui-components";

const StyledRow = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem 0;
  margin-bottom: 2.5rem;
`;

const StyledColumn = styled.div`
  flex: 1 1 50%;
  text-align: ${(props) => (props.isLeft ? "right" : "left")};
  padding: ${(props) => (props.isLeft ? "0 2rem 0 0" : "0 0 0 2rem")};
`;

const StyledLogoWrapper = styled.div`
  position: relative;
  display: inline-block;
  width: 110px;
  text-align: center;
`;

const StyledFixtureLogo = styled.img`
  height: 90px;
  max-width: 113px;
`;

const StyledVs = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 2rem;
  color: ${(props) => props.theme.colorLabel};
`;

const StyledClubName = styled(ClubName)`
  position: absolute;
  top: 110%;
  left: 50%;
  line-height: 1.25;
  hyphens: auto;
  width: 120px;
  transform: translateX(-50%);
  text-align: center;
  font-size: 0.875rem;
  font-weight: 600;
  padding-bottom: 0.5rem;
`;

const ContestBannerFixture = ({
  homeTeamLogo,
  homeTeamName,
  awayTeamLogo,
  awayTeamName,
}) => {
  return (
    <StyledRow>
      <StyledColumn isLeft>
        <StyledLogoWrapper>
          {!homeTeamLogo && (
            <Skeleton
              skeletonWidth="90px"
              skeletonHeight="90px"
              skeletonBorderRadius="20px"
            />
          )}
          {homeTeamLogo && <StyledFixtureLogo src={homeTeamLogo} />}
          {homeTeamName && (
            <StyledClubName
              isAbbreviated
              isNormalAbbreviatedStyle={
                process.env.REACT_APP_IS_NORMAL_CLUB_ABBREVIATED_STYLE ===
                "true"
              }
            >
              {homeTeamName}
            </StyledClubName>
          )}
        </StyledLogoWrapper>
      </StyledColumn>

      <StyledVs>
        {homeTeamLogo && awayTeamLogo && "vs"}
        {!homeTeamLogo && !awayTeamLogo && (
          <Skeleton
            skeletonWidth="20px"
            skeletonHeight="16px"
            skeletonBorderRadius="0.25rem"
          />
        )}
      </StyledVs>

      <StyledColumn>
        <StyledLogoWrapper>
          {!awayTeamLogo && (
            <Skeleton
              skeletonWidth="90px"
              skeletonHeight="90px"
              skeletonBorderRadius="20px"
            />
          )}
          {awayTeamLogo && <StyledFixtureLogo src={awayTeamLogo} />}
          {awayTeamName && (
            <StyledClubName
              isAbbreviated
              isNormalAbbreviatedStyle={
                process.env.REACT_APP_IS_NORMAL_CLUB_ABBREVIATED_STYLE ===
                "true"
              }
            >
              {awayTeamName}
            </StyledClubName>
          )}
        </StyledLogoWrapper>
      </StyledColumn>
    </StyledRow>
  );
};

export default ContestBannerFixture;
