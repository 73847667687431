import React from "react";
import styled from "styled-components";
import { rgba } from "polished";
import { createImageCdnLink } from "utils";

const StyledBanner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 1rem 3rem;
  background: ${(p) =>
    process.env.REACT_APP_LANDING_DONT_USE_DARK_COLOR === "true"
      ? "transparent"
      : p.theme.colorDark !== p.theme.colorPrimary
      ? p.theme.colorDark
      : rgba("black", 0.15)};
`;

const StyledLogo = styled.img`
  width: 56px;
  margin-right: 1rem;
  display: ${process.env.REACT_APP_LANDING_BANNER_HIDE_LOGO === "true"
    ? "none"
    : "block"};
`;

const StyledText = styled.div`
  color: ${(p) => p.theme.colorDarkText};
  line-height: 1.25;
  text-align: center;
  width: ${process.env.REACT_APP_LANDING_BANNER_HIDE_LOGO === "true"
    ? "100%"
    : "240px"};
  font-size: ${process.env.REACT_APP_LANDING_BANNER_HIDE_LOGO === "true"
    ? "1.25rem"
    : "1rem"};
`;

const LandingBanner = (props) => (
  <StyledBanner>
    <StyledLogo src={createImageCdnLink(process.env.REACT_APP_CLUB_LOGO)} />
    <StyledText>{process.env.REACT_APP_WEBSITE_DESCRIPTION}</StyledText>
  </StyledBanner>
);

export default LandingBanner;
