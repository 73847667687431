import styled from "styled-components";

import { rgba } from "polished";

import { MatIcon } from "components/shared";

const StyledIconBlock = styled.div`
  height: 2rem;
  width: 100%;
  border-radius: ${(p) => p.theme.borderRadius};
  padding: 0 0.75rem 0 0.5rem;
  background: ${(p) =>
    p.isSuccess ? p.theme.colorSuccess : p.theme.colorLightGrey};
  color: ${(p) => (p.isSuccess ? p.theme.colorSuccessText : p.theme.colorText)};
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

const StyledIconBlockIcon = styled(MatIcon)`
  font-size: 18px;
  margin: 0 0.25rem 0 0.35rem;
  color: ${(p) => (p.isSuccess ? p.theme.colorSuccessText : p.theme.colorText)};
`;

const StyledIconBlockText = styled.div`
  flex: 1 1 auto;
  min-width: 0;
  transform: translateY(-1px);
`;

const StyledIconBlockLabel = styled.div`
  font-size: 0.425rem;
  white-space: nowrap;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  height: 0.75rem;
  color: ${(p) =>
    rgba(p.isSuccess ? p.theme.colorSuccessText : p.theme.colorText, 0.67)};
`;

const StyledIconBlockValue = styled.div`
  font-size: ${(p) => (p.isSmaller ? "0.65rem" : "0.875rem")};
  height: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const StyledIconBlockValueSpan = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const Wrapper = ({ icon, hideIcon, label, value, isSuccess }) => {
  return (
    <StyledIconBlock isSuccess={isSuccess}>
      {!hideIcon && (
        <StyledIconBlockIcon isSuccess={isSuccess}>{icon}</StyledIconBlockIcon>
      )}
      <StyledIconBlockText>
        <StyledIconBlockLabel isSuccess={isSuccess}>
          {label}
        </StyledIconBlockLabel>
        <StyledIconBlockValue isSmaller={value?.length > 7}>
          <StyledIconBlockValueSpan>{value}</StyledIconBlockValueSpan>
        </StyledIconBlockValue>
      </StyledIconBlockText>
    </StyledIconBlock>
  );
};

export default Wrapper;
