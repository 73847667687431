import React from "react";
import styled from "styled-components";
import { MatIcon } from "components/shared";
import { createImageCdnLink } from "utils";

const StyledWrapper = styled.div`
  position: relative;
  margin-right: 1rem;
  display: flex;
  padding-left: 0.875rem;
  align-items: center;
  justify-content: flex-start;
  height: 58px;
  border-radius: ${(p) => p.theme.borderRadius};
  background: ${(p) => p.theme.colorLightGrey};
`;

const StyledPopularLabel = styled.div`
  font-size: 0.75rem;
  margin-bottom: 0.3rem;
  color: ${(p) => p.theme.colorLabel};
`;

const StyledPopularValue = styled.div`
  font-size: 1rem;
`;

const StyledIconWrapper = styled.div`
  background: ${(p) =>
    p.hasIcon
      ? "transparent"
      : p.isLightPrimary
      ? p.theme.colorAccent
      : p.theme.colorPrimary};
  color: ${(p) =>
    p.hasIcon
      ? "transparent"
      : p.isLightPrimary
      ? p.theme.colorAccentText
      : p.theme.colorPrimaryText};
  border-radius: ${(p) => (p.hasIcon ? 0 : "50%")};
  width: 40px;
  height: 40px;
  min-width: 40px;
  max-width: 40px;
  flex: 0 0 40px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledIcon = styled.img`
  width: 40px;
  height: 40px;
  min-width: 40px;
  max-width: 40px;
  flex: 0 0 40px;
`;

const ContestPicksPopular = ({ popularSelection }) => {
  let iconUrl = process.env.REACT_APP_POPULAR_PICK_ICON;

  if (iconUrl) {
    iconUrl = createImageCdnLink(iconUrl);
  }

  const isLightPrimary = process.env.REACT_APP_COLOR_PRIMARY_IS_LIGHT;

  return (
    <StyledWrapper>
      <StyledIconWrapper isLightPrimary={isLightPrimary} hasIcon={iconUrl}>
        {iconUrl ? <StyledIcon src={iconUrl} /> : <MatIcon>groups</MatIcon>}
      </StyledIconWrapper>
      <div style={{ marginLeft: "0.75rem" }}>
        <StyledPopularLabel>
          {process.env.REACT_APP_POPULAR_PICK_TITLE || "Popular Pick"}
        </StyledPopularLabel>
        <StyledPopularValue>{popularSelection || "-"}</StyledPopularValue>
      </div>
    </StyledWrapper>
  );
};

export default ContestPicksPopular;
