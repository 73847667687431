import React, { useState, useEffect } from "react";
import styled from "styled-components";
import countries from "assets/json/countries.json";
import { createFlagCdnLink } from "utils";
import { MatIcon } from "components/shared";

const StyledCountryActive = styled.button`
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  border-radius: ${(p) => p.theme.borderRadius};
  background: ${(p) => p.theme.colorLightGrey};
`;

const StyledActiveImage = styled.img`
  width: 28px;
  height: 28px;
`;

const StyledCountryWrapper = styled.div`
  position: relative;
`;

const StyledMenu = styled.div`
  position: absolute;
  top: 50px;
  z-index: 3;
  max-height: 300px;
  width: 300px;
  border: 2px solid ${(p) => p.theme.colorBorder};
  border-radius: ${(p) => p.theme.borderRadius};
  overflow: auto;
  visibility: ${(p) => (p.isOpen ? "visible" : "hidden")};
  opacity: ${(p) => (p.isOpen ? 1 : 0)};
  transform: translateY(${(p) => (p.isOpen ? 0 : "1.5rem")});
  background: white;
  transition-property: visibility, opacity, transform;
  transition-duration: 0.25s;
`;

const StyledMenuInner = styled.div`
  position: relative;
`;

const StyledMenuCloseButton = styled.button`
  background: transparent;
  padding: 0;
  width: 56px;
  height: 56px;
`;

const StyledMenuTitle = styled.div`
  position: sticky;
  z-index: 1;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: white;
  width: 100%;
  height: 56px;
  padding-left: 1rem;
  font-size: 0.875rem;
  color: ${(p) => p.theme.colorLabel};
`;

const StyledCountryMenuButton = styled.div`
  margin: 0 0.575rem;
  display: flex;
  align-items: center;
  height: 40px;
  padding: 0 0.5rem;
  border-radius: ${(p) => p.theme.borderRadius};
  cursor: pointer;

  &:hover {
    background: ${(p) => p.theme.colorLightGrey};
  }
`;

const StyledMenuButtonFlag = styled.img`
  margin-right: 1rem;
  width: 30px;
  height: 30px;
`;

const StyledMenuButtonText = styled.div`
  font-size: 0.875rem;
`;

const FormCountryPicker = ({
  countryCode,
  hideCountry,
  disableCountry,
  onCountryClicked,
  value,
}) => {
  const [isOpen, setOpen] = useState(undefined);
  const [activeCountry, setCountry] = useState(undefined);

  useEffect(() => {
    if (countryCode) {
      const country = countries?.find((obj) => obj.code === countryCode);
      setCountry(country);
      if (!value) {
        onCountryClicked(null, country.callingCode);
      }
    }
  }, [countryCode]);

  const listener = () => {
    setOpen(false);
  };

  useEffect(() => {
    document.addEventListener("click", listener);
  }, [listener]);

  const onOpenDropdown = (e) => {
    e.nativeEvent.stopImmediatePropagation();
    e.stopPropagation();

    if (!disableCountry) {
      setOpen(isOpen === undefined ? true : !isOpen);
    }
  };

  const internalOnCountryClicked = (e, code) => {
    e.nativeEvent.stopImmediatePropagation();
    e.stopPropagation();

    const country = countries?.find((obj) => obj.code === code);

    setCountry(country);
    setOpen(false);
    onCountryClicked(e, country?.callingCode);
  };

  return (
    <StyledCountryWrapper>
      <StyledCountryActive type="button" onClick={(e) => onOpenDropdown(e)}>
        <StyledActiveImage
          onError={(e) => {
            e.target.src = createFlagCdnLink("Default");
          }}
          src={createFlagCdnLink(activeCountry?.name)}
        />
      </StyledCountryActive>
      <StyledMenu isOpen={isOpen}>
        <StyledMenuTitle>
          Select your country
          <StyledMenuCloseButton type="button" onClick={() => setOpen(false)}>
            <MatIcon>close</MatIcon>
          </StyledMenuCloseButton>
        </StyledMenuTitle>
        <StyledMenuInner>
          {countries.map((c) => (
            <StyledCountryMenuButton
              onClick={(e) => internalOnCountryClicked(e, c.code)}
            >
              <StyledMenuButtonFlag
                onError={(e) => {
                  e.target.src = createFlagCdnLink("Default");
                }}
                src={createFlagCdnLink(c.name)}
              />
              <StyledMenuButtonText>{c.name}</StyledMenuButtonText>
            </StyledCountryMenuButton>
          ))}
        </StyledMenuInner>
      </StyledMenu>
    </StyledCountryWrapper>
  );
};

export default FormCountryPicker;
