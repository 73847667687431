import React, { useState } from "react";
import styled, { css } from "styled-components";
import PropTypes from "prop-types";
import { Spinner } from "../";

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 2px solid ${(props) => props.theme.colorBorder};

  ${(p) => p.isFocused && `border-color: ${p.theme.colorAccent}`};

  ${(props) =>
    props.isError || (props.isRequired && props.value === "")
      ? `border-color: ${props.theme.colorDanger} !important;`
      : null};
`;

const StyledPrefix = styled.div`
  padding: 0 0.5rem;
  color: ${(props) =>
    props.prefixLight ? props.theme.colorLabel : props.theme.colorText};
`;

const sharedStyles = () => {
  return css`
    width: 100%;
    height: ${(props) => (props.isSmall ? "60px" : "70px")};
    line-height: ${(props) => (props.isSmall ? "60px" : "70px")};
    font-size: ${(props) => (props.isSmall ? "0.875rem" : "1rem")};
    border: 0;

    background: transparent;

    &::placeholder {
      color: ${(props) => props.theme.colorLabel};
    }
  `;
};

const StyledReadOnly = styled.div`
  ${sharedStyles};
`;

const StyledInput = styled.input`
  ${sharedStyles};
`;

const StyledSpinner = styled(Spinner)`
  position: absolute;
  top: 22px;
  right: 1rem;
`;

const FormTextbox = ({
  disabled,
  name,
  type,
  value,
  min,
  placeholder,
  onChange,
  onFocus,
  onBlur,
  isSmall,
  autoFocus,
  isRequired,
  isLoading,
  isError,
  isReadOnly,
  minLength,
  prefix,
  prefixLight,
}) => {
  const [isFocused, setFocused] = useState(false);

  const handleOnFocus = () => {
    setFocused(true);
    if (onFocus) {
      onFocus();
    }
  };

  const handleOnBlur = () => {
    setFocused(false);
    if (onBlur) {
      onBlur();
    }
  };

  return (
    <StyledWrapper
      isFocused={isFocused}
      isError={isError}
      isRequired={isRequired}
      value={value}
    >
      {prefix && (
        <StyledPrefix prefixLight={prefixLight}>{prefix}</StyledPrefix>
      )}
      {isReadOnly && <StyledReadOnly>{value}</StyledReadOnly>}
      {!isReadOnly ? (
        <StyledInput
          autoFocus={autoFocus}
          value={value}
          name={name}
          onChange={onChange}
          onFocus={handleOnFocus}
          onBlur={handleOnBlur}
          type={type}
          min={min}
          placeholder={placeholder}
          isSmall={isSmall}
        />
      ) : null}
    </StyledWrapper>
  );
};

FormTextbox.defaultProps = {
  type: "text",
  isSmall: false,
  isRequired: false,
  isLoading: false,
};

FormTextbox.propTypes = {
  value: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  isSmall: PropTypes.bool,
  isRequired: PropTypes.bool,
  isLoading: PropTypes.bool,
};

export default FormTextbox;
