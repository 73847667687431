import React from "react";
import styled from "styled-components";
import {formatter, StyledPrizeDrawButton} from "./ConfirmStep";
import {MatIcon, Spinner} from "../../shared";
import PrizeButton from "./PrizeButton";
import PrizeDrawSmallText from "./PrizeDrawSmallText";

const StyledWrapper = styled.div``;

export const StyledDetailsHeader = styled.div`
  font-weight: bold;
  font-size: 3.75rem;
  line-height: 0.8;
  letter-spacing: -0.02em;
  text-align: center;
  margin-bottom: 1.75rem;
  margin-top: 2rem;
`;

export const StyledNotification = styled.div`
  font-weight: 600;
  line-height: 1.2;
  font-size: 1rem;
  text-align: center;
  width: 75%;
  margin: auto;
  margin-bottom: 2rem;
`;

const StyledIndicatorsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 2.5rem;
`;

const StyledColumn = styled.div`
  width: calc((100% - 0.325rem)/2);
`;

const StyledLabel = styled.div`
  font-weight: bold;
  font-size: 0.875;
  text-align: center;
  margin-bottom: 0.5rem;
`;

const StyledIndicator = styled.div`
  flex: 1;
  width: 100%;
  height: 75px;
  border: 1px solid #E4E4E4;
  box-sizing: border-box;
  border-radius: 4px;
  font-size: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index:0;
  margin-left: ${p => p.marginLeft ? p.marginLeft + 'px' : 0};
  margin-right: ${p => p.marginRight ? p.marginRight + 'px' : 0};
  color: ${p => p.isBalance ? p.isEnough ? 'currentColor' : '#D12E2E' : p.isEnough ? p.theme.colorSuccess : 'currentColor'};
  background: white;
  &::before {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    bottom: 0;
    left: 0;
    right: 0;
    background: #f1f1f1;
    z-index: -1;}
`;

const StyledAddFundsButton = styled.div`
  border-radius: 0.25rem;
  margin: 1rem 0 0.75rem;
  position: relative;
  text-transform: capitalize;
  height: 4.625rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 1.75rem;
  font-size: 1.5rem;
  margin-bottom: 1rem;
  ${({disabled, theme}) => disabled ? `
      color: #E6E6E6;
      background: #F1F1F1;
      pointer-events: none;
    ` : `
      color: #fff;
      background: ${theme.colorSuccess};
      cursor: pointer;
    `
}
`;

const StyledMatIcon = styled(MatIcon)`
  position: absolute;
  top: 50%;
  right: 0.74rem;
  transform: translate(0, -50%);
`

const StyledLine = styled.div`
  height: 3px;
  background: #F1F1F1;
  margin-bottom: 1rem;
`

const StyledControlsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1.75rem;
`

const StyledAmount = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 65px;
  height: 65px;
  border: 1px solid #E4E4E4;
  box-sizing: border-box;
  border-radius: 4px;
  margin-left: 0.875rem;
  margin-right: 0.875rem;
  background: white;
`

const NotEnoughStep = ({
  amount,
  setAmount,
  contest,
  availableFunds,
  finalPrice,
  isLoading,
  setActiveStep,
  setShowConfirm,
}) => {
  return  (
    <StyledWrapper>
      <StyledDetailsHeader>
        NEARLY <br/> THERE...
      </StyledDetailsHeader>
      
      <StyledNotification>
        We’ve noticed you don’t have enough funds in your wallet to enter this prize draw.
        Deposit funds or change your number of entries.
      </StyledNotification>
      
      <StyledIndicatorsWrapper>
        <StyledColumn>
          <StyledLabel>cost to enter</StyledLabel>
          <StyledIndicator isEnough={(availableFunds / 100) >= finalPrice} isBalance={false}>{formatter.format(finalPrice)}</StyledIndicator>
        </StyledColumn>
        <StyledColumn>
          <StyledLabel>available funds</StyledLabel>
          <StyledIndicator isEnough={(availableFunds / 100) >= finalPrice} isBalance={true}>{formatter.format(availableFunds / 100)}</StyledIndicator>
        </StyledColumn>
      </StyledIndicatorsWrapper>
      
      <StyledAddFundsButton
          disabled={(availableFunds / 100) >= finalPrice}
          // disabled={false}
          onClick={() => setActiveStep(3)}
      >
        Add More Funds
        <StyledMatIcon size="2rem">arrow_forward_ios</StyledMatIcon>
      </StyledAddFundsButton>
      
      <StyledLine />
  
      <StyledNotification>
        or change number of entries
      </StyledNotification>
      
      <StyledControlsWrapper>
        <PrizeButton
            disabled={amount === 1}
            minus
            callback={() => setAmount(prev => prev === 1 ? 1 : prev - 1)}
            isLoading={isLoading}
        />
        <StyledAmount>{amount}</StyledAmount>
        <PrizeButton
            disabled={amount === contest?.maximum_per_user}
            plus
            callback={() => setAmount(prev => prev + 1)}
            isLoading={isLoading}
        />
      </StyledControlsWrapper>
      <StyledPrizeDrawButton
        disabled={isLoading || (finalPrice  > (availableFunds / 100))}
        onClick={() => setShowConfirm(true)}
      >
        {!isLoading && <>Enter Now<StyledMatIcon>arrow_forward_ios</StyledMatIcon></>}
        {isLoading && <Spinner lineWidth={4} />}
      </StyledPrizeDrawButton>
      <PrizeDrawSmallText margin="0 0 1rem" contest={contest} />
    </StyledWrapper>
  );
};


export default NotEnoughStep;
