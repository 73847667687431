import React from "react";
import styled, { css } from "styled-components";
import { Link } from "react-router-dom";

const sharedStyles = css`
  color: ${(props) => props.theme.colorText};
`;

const StyledButton = styled.button`
  ${sharedStyles};
`;

const StyledLink = styled(Link)`
  color: ${(props) => props.theme.colorAccent};
`;

const TextButton = (props) => {
  const { children, to } = props;
  return to ? (
    <StyledLink {...props}>{children}</StyledLink>
  ) : (
    <StyledButton {...props}>{children}</StyledButton>
  );
};

export default TextButton;
