import styled from "styled-components";

import {
  QuestionTypeAvatar,
  QuestionTypePoints,
  QuestionTypeButtons,
  QuestionTypePopular,
  QuestionTypeQuestion,
  QuestionTypeTips,
} from "../shared";

const StyledRow = styled.div`
  display: flex;
`;

const StyledLeft = styled.div`
  flex: 1 1 auto;
  min-width: 1px;
`;

const StyledRight = styled.div`
  flex: 0 0 35%;

  @media (min-width: ${(props) => props.theme.screenWidthXs}) {
    flex: 0 0 130px;
  }
`;

const Wrapper = ({
  label,
  subtitle,
  data,
  popularSelectionString,
  correctAnswerId,
  myAnswerId,
  isLocked,
  showIcon,
  availablePoints,
  tips,
  tipsterString,
  id,
  updatePicks,
  options,
}) => {
  return (
    <>
      <StyledRow>
        <StyledLeft>
          <QuestionTypeQuestion
            question={label}
            questionStat={subtitle}
            entity={data}
          />
        </StyledLeft>
        <StyledRight>
          <QuestionTypeAvatar avatar={data?.avatar || data?.logo} />
        </StyledRight>
      </StyledRow>

      <StyledRow style={{ marginBottom: "1rem" }}>
        <StyledLeft>
        {
          process.env.REACT_APP_HIDDEN_POPULAR_ANSWER !== "true" && (
            <QuestionTypePopular popularSelection={popularSelectionString} />
          )
        }
        </StyledLeft>
        <StyledRight>
          <QuestionTypePoints
            correctAnswerId={correctAnswerId}
            myAnswerId={myAnswerId}
            isLocked={isLocked}
            showIcon={showIcon}
            availablePoints={availablePoints}
          />
        </StyledRight>
      </StyledRow>

      {tips && tips[0] && (
        <QuestionTypeTips tip={tips[0]} tipsterString={tipsterString} />
      )}

      <QuestionTypeButtons
        correctAnswerId={correctAnswerId}
        myAnswerId={myAnswerId}
        isLocked={isLocked}
        showIcon={showIcon}
        questionId={id}
        updatePicks={updatePicks}
        questionOptions={options}
      />
    </>
  );
};

export default Wrapper;
