import React, {useState} from "react";
import styled from "styled-components";
import {formatter} from "./ConfirmStep";

const StyledFade = styled.div`
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  z-index: 6;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
`

const StyledText = styled.div`
  flex: 1;
  text-align: center;
`

const StyledTitle = styled.div`
  font-size: 1rem;
  line-height: 1.2;
  margin-bottom: 2px;
  font-weight: bold;
  text-align: center;
`

const StyledDescription = styled.div`
  font-size: 0.75rem;
  text-align: center;
  line-height: 1.2;
`

const StyledTop = styled.div`
  padding: 1.125rem 1rem;
`

const StyledBottom = styled.div`
  display: flex;
`

const StyledButton = styled.div`
  color: #007AFF;
  width: 50%;
  border-top: 1px solid rgba(60, 60, 67, 0.36);
  padding: 0.625rem 0;
  text-align: center;
  cursor: pointer;
  &:not(:last-child) {
    border-right: 1px solid rgba(60, 60, 67, 0.36);
  }
`

const StyledWrapper = styled.div`
  width: auto;
  max-width: 80vw;
  background: rgba(242, 242, 242, 1);
  box-shadow: 0 0 6px #DADADA;
  transform: translate(-50%, -50%);
  border-radius: 14px;
  position: fixed;
  z-index: 7;
  left: 50%;
  top: 50%;
`;

const ConfirmModal = ({
  setConfirm,
  setShowConfirm,
  finalPrice,
}) => {
  return (
    <>
      <StyledWrapper>
        <StyledTop>
          <StyledTitle>{`Confirm ${formatter.format(finalPrice)} entry?`}</StyledTitle>
          <StyledDescription>You will be entered into the prize draw.</StyledDescription>
        </StyledTop>
        <StyledBottom>
          <StyledButton
            onClick={() => setShowConfirm(prev => !prev)}
          >
            Cancel
          </StyledButton>
          <StyledButton
            onClick={() => {
              setConfirm(true)
              setShowConfirm(prev => !prev)
            }}
          >
            Confirm
          </StyledButton>
        </StyledBottom>
      </StyledWrapper>
      <StyledFade />
    </>
  );
};

export default ConfirmModal;
