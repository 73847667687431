import React from "react";
import styled, { css } from "styled-components";
import FormCountryPicker from "./FormCountryPicker";

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 2px solid ${(props) => props.theme.colorBorder};

  ${(p) => p.isFocused && `border-color: ${p.theme.colorAccent}`};

  ${(props) =>
    props.isError || (props.isRequired && props.value === "")
      ? `border-color: ${props.theme.colorDanger} !important;`
      : null};
`;

const StyledInput = styled.input`
  width: 100%;
  height: ${(props) => (props.isSmall ? "60px" : "70px")};
  line-height: ${(props) => (props.isSmall ? "60px" : "70px")};
  font-size: ${(props) => (props.isSmall ? "0.875rem" : "1rem")};
  border: 0;
  margin-left: ${(p) => (p.hideCountry ? "0" : "0.75rem")};
  background: transparent;

  &::placeholder {
    color: ${(props) => props.theme.colorLabel};
  }
`;

const FormTelephone = ({
  disabled,
  isSmall,
  isError,
  isRequired,
  isFocused,
  onChange,
  name,
  relatedName,
  value,
  placeholder,
  countryCode,
  hideCountry,
  disableCountry,
}) => {
  const handleCountryClicked = (e, code) => {
    onChange({ target: { name: name, value: code } });
    onChange({ target: { name: relatedName, value: code } });
  };

  return (
    <StyledWrapper isError={isError} isRequired={isRequired} value={value}>
      {!hideCountry && (
        <FormCountryPicker
          value={value}
          onCountryClicked={handleCountryClicked}
          hideCountry={hideCountry}
          disableCountry={disableCountry}
          countryCode={countryCode}
        />
      )}
      <StyledInput
        isSmall={isSmall}
        disabled={disabled}
        hideCountry={hideCountry}
        placeholder={placeholder}
        type="tel"
        name={name}
        value={value}
        onChange={onChange}
      />
    </StyledWrapper>
  );
};

export default FormTelephone;
