import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { createImageCdnLink } from "utils";

const StyledLogo = styled.img`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
`;

const LogoBadge = (props) => (
  <StyledLogo
    height={props.height}
    width={props.width}
    src={createImageCdnLink(process.env.REACT_APP_MAIN_LOGO)}
  />
);

LogoBadge.defaultProps = {
  height: "auto",
  width: "100px",
};

LogoBadge.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
};

export default LogoBadge;
