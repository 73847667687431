import React, { useEffect } from "react";
import styled from "styled-components";
import {
  BannerControls,
  createPrizeImageCdnLink,
  StyledAmount,
  StyledDateCounter,
  StyledTotal,
  StyledTotalIndex,
} from "./PrizeDraw";
import PrizeButton from "./PrizeButton";
import PrizeTimeLeft from "./PrizeTimeLeft";
import { MatIcon, Spinner } from "../../shared";
import PrizeDrawSmallText from "./PrizeDrawSmallText";
import { Tooltip } from "react-tippy";

const StyledWrapper = styled.div``;

const StyledBanner = styled.div`
  min-height: 9.6875rem;
  position: relative;
  margin-bottom: 0.625rem;
  padding: 0 0.25rem;
`;

const StyledBannerText = styled.div`
  font-size: 4rem;
  white-space: nowrap;
  line-height: 0.8;
  letter-spacing: -0.02rem;
  text-transform: uppercase;
  color: ${(p) => p.theme.colorSuccess};
  text-align: center;
  position: absolute;
  z-index: 0;
  left: 0;
  right: 0;
  margin: auto;
  top: 1.5rem;
`;

const StyledPrizeImg = styled.img`
  padding: 3.5rem 0 0;
  margin: 0 auto;
  display: block;
  width: 100%;
  position: relative;
  z-index: 1;
  @media (min-width: ${(props) => props.theme.screenWidthSm}) {
    width: 60.5%;
  }
`;

const StyledModel = styled.div`
  font-size: 2.5rem;
  color: #696971;
  text-transform: uppercase;
  text-align: right;
  font-weight: bold;
  position: relative;
  z-index: 2;
  line-height: 0.8;
  letter-spacing: -0.02em;
  font-family: inherit;
  margin-top: -1rem;
`;

const StyledCard = styled.div`
  position: relative;
  padding: 0.625rem;
  border-radius: 0.25rem;
  background: #ffffff;
  margin-top: 1rem;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  background: #fafafa;
  color: #696971;
`;

const StyledCardTtitle = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
`;

const StyledCardTtitleText = styled.div`
  color: #696971;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  flex: 1;
  margin-right: 1rem;
`;

const StyledCardHelp = styled.a`
  background: ${(p) => p.theme.colorSuccess};
  width: 33px;
  height: 33px;
  color: #fff;
  cursor: pointer;
  font-size: 1.5rem;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
`;

const StyledQuestionWrapper = styled.div`
  border-radius: 0.25rem;
  padding: 1rem;
  background: white;
`;

const StyledQuestionText = styled.div`
  margin-bottom: 1.25rem;
  text-align: center;
  line-height: 1.2;
`;

const StyledOptionsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const StyledOption = styled.div`
  background: ${(p) => (p.isSelected ? p.theme.colorSuccess : "#696971")};
  color: ${(p) => (p.isSelected ? p.theme.colorSuccess : "currentColor")};
  border-radius: 0.25rem;
  width: calc((100% - 1rem) / 2);
  padding: 0.375rem;
  margin-bottom: 0.625rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  ${(p) => (p.isLoading ? "pointer-events: none;" : "")};
`;

const StyledOptionMarker = styled.div`
  border-radius: 50%;
  width: 33px;
  height: 33px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  flex: 0 0 33px;
`;

const StyledOptionText = styled.div`
  color: #fff;
  flex: 1;
  text-align: center;
  padding: 0 0.5rem;
`;

export const StyledOptionsTip = styled.div`
  text-align: center;
  font-size: 0.5rem;
  line-height: 1.2;
  position: relative;
  z-index: 1;
  margin: ${(p) => (p.margin ? p.margin : "0")};
  > a {
    font-size: inherit;
    line-height: inherit;
    color: inherit;
  }
`;

export const StyledPrizeDrawButton = styled.div`
  border-radius: 0.25rem;
  margin: 1rem 0 0.75rem;
  position: relative;
  text-transform: uppercase;
  height: 2.875rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  ${({ disabled, theme }) =>
    disabled
      ? `
      color: #E6E6E6;
      background: #F1F1F1;
      pointer-events: none;
    `
      : `
      color: #fff;
      background: ${theme.colorSuccess};
    `}
`;
const StyledMatIcon = styled(MatIcon)`
  position: absolute;
  top: 50%;
  right: 0.74rem;
  transform: translate(0, -50%);
`;

const alphabet = [
  "a",
  "b",
  "c",
  "d",
  "e",
  "f",
  "g",
  "h",
  "i",
  "j",
  "k",
  "l",
  "m",
  "n",
  "o",
  "p",
  "q",
  "r",
  "s",
  "t",
  "u",
  "v",
  "w",
  "x",
  "y",
  "z",
];

export const formatter = new Intl.NumberFormat("en-GB", {
  style: "currency",
  currency: "GBP",
  minimumFractionDigits: 2,
});

const ConfirmStep = ({
  selected,
  amount,
  setAmount,
  setSelected,
  contest,
  finalPrice,
  isLoading,
  setShowConfirm,
}) => {
  return contest ? (
    <StyledWrapper>
      <StyledBanner>
        <StyledBannerText>{contest?.descriptions?.short}</StyledBannerText>
        <StyledPrizeImg src={contest?.image?.url} alt="prize" />
        {/* {contest?.prize?.item ? (
          <StyledModel>
            {`${contest?.prize?.item?.split(" ")[0]}`} <br />
            {`${contest?.prize?.item?.split(" ")?.slice(1)?.join(" ")}`}
          </StyledModel>
        ) : null} */}
      </StyledBanner>
      <StyledDateCounter>
        <PrizeTimeLeft date={contest?.date?.end?.string} />
      </StyledDateCounter>
      <BannerControls>
        <PrizeButton
          disabled={amount === 1}
          minus
          marginRight={10}
          callback={() => setAmount((prev) => (prev === 1 ? 1 : prev - 1))}
          isLoading={isLoading}
        />
        <StyledAmount marginRight={10}>{amount}</StyledAmount>
        <PrizeButton
          disabled={amount === contest?.maximum_per_user}
          plus
          marginRight={10}
          callback={() => setAmount((prev) => prev + 1)}
          isLoading={isLoading}
        />
        <StyledTotal>
          total <br />
          cost
          <StyledTotalIndex marginRight={10} marginLeft={10}>
            {formatter.format(finalPrice)}
          </StyledTotalIndex>
        </StyledTotal>
      </BannerControls>
      <StyledCard>
        <StyledCardTtitle>
          <StyledCardTtitleText>
            Just answer a quick question
          </StyledCardTtitleText>
          {/*<Tooltip*/}
          {/*  trigger="click"*/}
          {/*  interactive*/}
          {/*  arrow*/}
          {/*  distance={5}*/}
          {/*  html={<PrizeDrawSmallText contest={contest} />}*/}
          {/*>*/}
          <StyledCardHelp href="https://winme.tv/terms/" target="_blank">
            <img
              src={createPrizeImageCdnLink("question-mark.svg")}
              alt="help"
            />
          </StyledCardHelp>
          {/*</Tooltip>*/}
        </StyledCardTtitle>
        <StyledQuestionWrapper>
          <StyledQuestionText>
            {contest?.questions[0]?.question}
          </StyledQuestionText>
          <StyledOptionsWrapper>
            {contest?.questions[0]?.answers.map((item, i) => {
              return (
                <StyledOption
                  isSelected={selected === item.id}
                  onClick={() => setSelected(item.id)}
                  key={i}
                  isLoading={isLoading}
                >
                  <StyledOptionMarker>{alphabet[i]}</StyledOptionMarker>
                  <StyledOptionText>{item.answer}</StyledOptionText>
                </StyledOption>
              );
            })}
          </StyledOptionsWrapper>
          <StyledOptionsTip>
            Participants who answer correctly will be considered for the prize.
          </StyledOptionsTip>
        </StyledQuestionWrapper>
      </StyledCard>
      <StyledPrizeDrawButton
        disabled={(!selected && !(selected === 0)) || isLoading}
        onClick={async () => setShowConfirm(true)}
      >
        {!isLoading && (
          <>
            Enter Now<StyledMatIcon>arrow_forward_ios</StyledMatIcon>
          </>
        )}
        {isLoading && <Spinner lineWidth={4} />}
      </StyledPrizeDrawButton>
      <PrizeDrawSmallText margin="0 0 1rem" contest={contest} />
    </StyledWrapper>
  ) : null;
};

export default ConfirmStep;
