import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { KickoffFull } from "components/shared";
import { EmptyScreen } from "fiver-react-ui-components";

import ContestEventBanner from "./eventBanner/Wrapper";
import ContestBanner from "./banner/ContestBanner";
import ContestBannerPresentedBy from "./banner/ContestBannerPresentedBy";
import ContestPicks from "./picks/ContestPicks";
import ContestBetNow from "./ContestBetNow";
import ContestMakePicks from "./ContestMakePicks";
import ContestFloatingHeaderVerify from "./ContestFloatingHeaderVerify";
import ContestFloatingHeaderIdentity from "./ContestFloatingHeaderIdentity";
import NonMonetaryPrizing from "./picks/ContestPicksNonMonetary";

import { getClubLogo, createImageCdnLink } from "utils";
import { clearContest, placeBet, setPicks, setAllSelected } from "./actions";

import { usePusherContest } from "hooks";

import { getCoolingOffDuration } from "../auth/actions";
import PrizeDraw from "../prizedraw/PrizeDraw";
import BannerPrizeDraw from "./banner/BannerPrizeDraw";

const StyledFixtureLogo = styled.img``;

const StyledEmptyWrapper = styled.div`
  overflow: hidden;
  border-radius: ${(p) => p.theme.borderRadius};
  margin: 0 1rem 1rem;
`;

const StyledWrapper = styled.div`
  padding-top: ${process.env.REACT_APP_AFFILIATE_LOGO &&
  process.env.REACT_APP_AFFILIATE_LOGO !== "" &&
  process.env.REACT_APP_PICKS_HIDE_BANNER !== "false"
    ? "1rem"
    : "2rem"};
`;

const Contest = (props) => {
  const dispatch = useDispatch();
  useEffect(() => {
    window.xtremepush(
      "tag",
      "lobby",
      JSON.stringify({
        AppName: process.env.REACT_APP_TITLE,
      })
    );
  }, []);

  usePusherContest();

  const {
    activePickem,
    activeBet,
    isActivePickemApproved,
    isBetsOpen,
    isPickemEmpty,
  } = useSelector((s) => s.toolbar);

  const { isPlacingBet, isAllSelected, picks, tiebreaker } = useSelector(
    (state) => state.contest
  );

  const {
    isScrolledDown,
    isNonUkUser,
    isIdentityPassed,
    isMobileNumberVerified,
    walletBalance,
    walletPromoBalance,
  } = useSelector((state) => state.app);

  useEffect(() => {
    dispatch(getCoolingOffDuration());
  }, []);

  useEffect(() => {
    if (activePickem?.id) {
      dispatch(setPicks(undefined));
    }
  }, [activePickem?.id]);

  useEffect(() => {
    if (activeBet) {
      dispatch(setPicks(activeBet?.selections));
    }
  }, [activeBet]);

  const isLocked =
    (activePickem?.stake > 0 &&
      walletBalance + walletPromoBalance < activePickem?.stake) ||
    !isIdentityPassed ||
    !isMobileNumberVerified ||
    (activePickem && activePickem.status === "IN_PLAY") ||
    (activePickem && activePickem.status === "COMPLETED") ||
    activeBet
      ? true
      : false;

  const questionAnswered = (value) => {
    return value > 0;
  };

  const shouldShowPlaceBetButton =
    isAllSelected &&
    !activeBet &&
    (!activePickem?.tieBreakerQuestion?.length || tiebreaker?.length > 0);

  console.log("shouldShowPlaceBetButton", shouldShowPlaceBetButton);

  useEffect(() => {
    const selectionsAreComplete =
      !isLocked &&
      activePickem &&
      activePickem.questions &&
      picks &&
      Object.keys(picks).every((q) => questionAnswered(picks[q])) &&
      Object.keys(picks).length === activePickem.questions.length;

    if (selectionsAreComplete && !isAllSelected) {
      dispatch(setAllSelected(selectionsAreComplete));
    } else if (isAllSelected && !selectionsAreComplete) {
      dispatch(setAllSelected(selectionsAreComplete));
    }
  }, [isLocked, activePickem, picks]);

  const updatePicks = (id, option) => {
    const newPicks = { ...picks };
    newPicks[id] = option.id;

    dispatch(setPicks(newPicks));
  };

  const handlePlaceBet = () => {
    dispatch(placeBet(activePickem?.id, picks, activePickem?.name, tiebreaker));
  };

  return (
    <StyledWrapper>
      {isPickemEmpty && (
        <StyledEmptyWrapper>
          <EmptyScreen />
        </StyledEmptyWrapper>
      )}
      {!isPickemEmpty && (
        <>
          {isMobileNumberVerified === false && <ContestFloatingHeaderVerify />}
          {isIdentityPassed === false && <ContestFloatingHeaderIdentity />}
          <div>
            {process.env.REACT_APP_PRIZE_DRAW && !isNonUkUser && (
              <PrizeDraw nonUkUser={isNonUkUser} />
            )}
            
            {/*{process.env.REACT_APP_PRIZE_DRAW && <BannerPrizeDraw />}*/}
            {process.env.REACT_APP_HIDE_CONTEST_BANNER !== "true" &&
              process.env.REACT_APP_IS_EVENT_BASED_SPORT === "true" &&
              process.env.REACT_APP_HAS_DEFAULT_CONTEST_BANNER !== "true" && (
                <ContestEventBanner />
              )}
            {process.env.REACT_APP_SHOW_PRESENTEDBY_BANNER === "true" && <ContestBannerPresentedBy />}
            {(process.env.REACT_APP_HIDE_CONTEST_BANNER !== "true" &&
              process.env.REACT_APP_IS_EVENT_BASED_SPORT !== "true") ||
            process.env.REACT_APP_HAS_DEFAULT_CONTEST_BANNER === "true" ? (
              <ContestBanner
                split={activePickem && activePickem.split}
                prizepool={activePickem && activePickem.prizeFund}
                homeTeamLogo={
                  !activePickem
                    ? null
                    : activePickem?.sportMatch?.homeTeam?.logo ||
                      createImageCdnLink(process.env.REACT_APP_IMAGE_BLANK_LOGO)
                }
                homeTeamName={activePickem?.sportMatch?.homeTeam?.name}
                awayTeamLogo={
                  !activePickem
                    ? null
                    : activePickem?.sportMatch?.awayTeam?.logo ||
                      createImageCdnLink(process.env.REACT_APP_IMAGE_BLANK_LOGO)
                }
                awayTeamName={activePickem?.sportMatch?.awayTeam?.name}
              />
            ) : null}

            {process.env.REACT_APP_HIDE_GAMBLING_REFERENCES === "true" &&
            process.env.REACT_APP_HIDE_MONEY_REFERENCES === "true" ? (
              <NonMonetaryPrizing
                prizeText={activePickem?.prizeText}
              ></NonMonetaryPrizing>
            ) : null}

            {(activePickem?.status === "UPCOMING" ||
              activePickem?.status === "APPROVED") &&
              isLocked !== undefined &&
              !isLocked &&
              process.env.REACT_APP_PICKS_HIDE_MAKE_YOUR_PICKS !== "true" &&
              process.env.REACT_APP_HIDE_CONTEST_BANNER !== "true" &&
              process.env.REACT_APP_IS_BLENHEIM !== "true" && (
                <ContestMakePicks />
              )}
            <ContestPicks
              status={activePickem?.status}
              isLocked={
                process.env.REACT_APP_SCREENSHOT_MODE === "true" || isLocked
              }
              updatePicks={updatePicks}
            />
          </div>
          <ContestBetNow
            isLoading={isPlacingBet}
            selections={picks}
            activePickemId={activePickem && activePickem.id}
            placeBet={handlePlaceBet}
            isOpen={shouldShowPlaceBetButton}
          />
        </>
      )}
    </StyledWrapper>
  );
};

export default Contest;
