import { combineReducers } from "redux";

import appReducer from "components/feature/app/reducer";
import authReducer from "components/feature/auth/reducer";
import loginReducer from "components/feature/auth/login/reducer";
import contestReducer from "components/feature/contest/reducer";
import leaderboardReducer from "components/feature/leaderboard/reducer";

import drawerReducer from "components/layout/drawer/reducer";
import toolbarReducer from "components/layout/toolbar/reducer";

export default combineReducers({
  app: appReducer,
  auth: authReducer,
  login: loginReducer,
  contest: contestReducer,
  drawer: drawerReducer,
  leaderboard: leaderboardReducer,
  toolbar: toolbarReducer,
});
