import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";

const WalletCardResult = () => {
  const history = useHistory();
  const location = useLocation();
  const isSuccess = location?.search.indexOf("paymentStatus=AUTHORISED") > -1;
  useEffect(() => {
    if (isSuccess) {
      toast.success("Card Added");
    } else {
      toast.error("Error Adding Card");
    }

    history.push(`/wallet?success=${isSuccess}`);
  });

  return <div></div>;
};

export default WalletCardResult;
