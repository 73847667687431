import React from "react";
import styled from "styled-components";

const StyledLabel = styled.div`
  display: flex;
  font-size: ${(p) => (p.isCondensed ? "0.875rem" : "1rem")};
  align-items: ${(p) => (p.isCondensed ? "flex-end" : "center")};
  height: ${(p) => (p.isCondensed ? "36px" : "56px")};
  color: ${(p) => (p.isCondensed ? p.theme.colorLabel : p.theme.colorText)};
`;

const FormRowLabel = ({ children, isCondensed }) => (
  <StyledLabel isCondensed={isCondensed}>{children}</StyledLabel>
);

export default FormRowLabel;
