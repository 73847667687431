import { useSelector } from "react-redux";
import styled from "styled-components";
import { rgba } from "polished";
import { ClubName, DateTime, Spinner } from "fiver-react-ui-components";
import { ordinalSuffix, createImageCdnLink } from "utils";

const StyledPopup = styled.div`
  position: absolute;
  z-index: 1;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 65%;
  box-shadow: 0px 16px 40px rgba(0, 0, 0, 0.1);
  display: flex;
  overflow: hidden;
  flex-direction: column;
`;

const StyledHeader = styled.div`
  padding: 1rem;
  background: ${(p) => p.theme.colorDark};
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  align-items: center;
  border-top-left-radius: ${(p) => p.theme.borderRadius};
  border-top-right-radius: ${(p) => p.theme.borderRadius};
`;

const StyledKickOff = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  min-width: 0;
  font-size: 1rem;
  color: ${(p) => p.theme.colorDarkText};
  font-weight: bold;

  & span,
  & time {
    color: ${(p) => rgba(p.theme.colorDarkText, 0.75)};
    font-size: 0.75rem;
    line-height: 1.3;
    margin-bottom: 0.125rem;
  }
`;

const StyledLive = styled.div`
  flex: 1 0 54px;
  border-radius: 0.25rem;
  padding: 0.4rem;
  min-width: 54px;
  max-width: 54px;
  width: 54px;
  background: #dedede;
  text-transform: uppercase;
  white-space: nowrap;
  font-size: 0.625rem;
  font-weight: bold;
  color: ${(p) => (p.isLive ? p.theme.colorText : "#c0c0c0")};
  &:before {
    content: "";
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%;
    margin-right: 0.5rem;
    display: inline-block;
    background: ${(p) => (p.isLive ? "#55c164" : "#beccc0")};
  }
`;

const StyledTeams = styled.div`
  padding: 1rem;
  display: flex;
  align-items: center;
  position: relative;

  &:after {
    content: "";
    display: block;
    width: 85%;
    left: 50%;
    transform: translate(-50%, 0);
    position: absolute;
    bottom: 0;
    height: 0.0625rem;
    background: #ebebeb;
  }
`;

const StyledClub = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
`;

const StyledClubLogo = styled.img`
  height: 2.875rem;
  margin-bottom: 0.25rem;
`;

const StyledClubName = styled(ClubName)`
  font-size: 0.625rem;
  line-height: 1.2;
  text-align: center;
  letter-spacing: 0.04em;
`;

const StyledVs = styled.div`
  width: 60px;
  text-align: center;
  color: ${(p) => p.theme.colorLabel};
  font-size: 1.375rem;
  font-weight: bold;
`;

const StyledMessage = styled.div`
  padding: 1.5rem 2rem 1.5rem;
  color: ${(p) => p.theme.colorLabel};
  font-size: 1rem;
  line-height: 1.5;
  text-align: center;
  max-width: 230px;
  margin: 0 auto;
`;

const StyleSpinnerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 1.5rem 2.25rem;
  flex: 1 1 auto;
`;

const StyledSpinner = styled(Spinner)`
  border-top-color: ${(p) => p.theme.colorDark};
`;

const StyledSpinnerText = styled.div`
  font-weight: ${(p) => p.theme.fontWeightSemibold};
  font-size: 0.875rem;
  line-height: 1.25;
`;

const StyledEvent = styled.div`
  text-transform: uppercase;
  font-weight: 600;
`;

const StyledEventTitle = styled.div`
  white-space: normal;
  margin-bottom: 4px;
`;

const StyledEventSubtitle = styled.div`
  font-size: 0.875rem;
  color: ${(p) => p.theme.colorDarkText};
`;

const StyledContent = styled.div`
  background: white;
  border-bottom-left-radius: ${(p) => p.theme.borderRadius};
  border-bottom-right-radius: ${(p) => p.theme.borderRadius};
`;

const Wrapper = () => {
  const {
    activePickem,
    activeBet,
    isActivePickemCompleted,
    isActivePickemInPlay,
    isActivePickemApproved,
  } = useSelector((s) => s.toolbar);

  const statusText =
    activePickem?.sportMatch?.period !== "HalfTime" ||
    activePickem?.sportMatch?.period !== "HalfTime"
      ? "Live"
      : "Ended";

  const blankLogo = createImageCdnLink(process.env.REACT_APP_IMAGE_BLANK_LOGO);

  // uncomment to test
  // activePickem.status = "IN_PLAY";

  return (
    <StyledPopup>
      <StyledHeader>
        <StyledKickOff>
          {process.env.REACT_APP_IS_EVENT_BASED_SPORT === "true" ? (
            <StyledEvent>
              <StyledEventTitle>{activePickem?.name}</StyledEventTitle>
              <StyledEventSubtitle>
                {activePickem?.featuredTitle && (
                  <span>{activePickem?.featuredTitle}</span>
                )}
                {/* <DateTime
                  isUTC={process.env.REACT_APP_IS_UTC_DATES}
                  format="D MMMM"
                >
                  {activePickem?.start}
                </DateTime> */}
              </StyledEventSubtitle>
            </StyledEvent>
          ) : (
            <>
              <span>
                {isActivePickemInPlay && activePickem?.sportMatch?.matchTime
                  ? `${activePickem?.sportMatch?.matchTime}'`
                  : isActivePickemCompleted
                  ? "Ended"
                  : "Starting"}
              </span>
              {!isActivePickemApproved &&
              activePickem?.sportMatch?.matchTime ? (
                `${statusText} ${activePickem?.sportMatch?.homeScore}:${activePickem?.sportMatch?.awayScore}`
              ) : (
                <DateTime
                  isUTC={process.env.REACT_APP_IS_UTC_DATES}
                  format={
                    activePickem?.start?.indexOf(":00:00") > -1
                      ? "ddd ha"
                      : "ddd h:mma"
                  }
                >
                  {activePickem?.start}
                </DateTime>
              )}
            </>
          )}
        </StyledKickOff>
        <StyledLive isLive={isActivePickemInPlay}>LIVE</StyledLive>
      </StyledHeader>
      <StyledContent>
        {process.env.REACT_APP_IS_EVENT_BASED_SPORT !== "true" && (
          <StyledTeams>
            <StyledClub>
              <StyledClubLogo
                src={activePickem?.sportMatch?.homeTeam?.logo || blankLogo}
              />
              <StyledClubName
                isAbbreviated
                isNormalAbbreviatedStyle={
                  process.env.REACT_APP_IS_NORMAL_CLUB_ABBREVIATED_STYLE ===
                  "true"
                }
              >
                {activePickem?.sportMatch?.homeTeam?.name}
              </StyledClubName>
            </StyledClub>
            <StyledVs>vs</StyledVs>
            <StyledClub>
              <StyledClubLogo src={activePickem?.sportMatch?.awayTeam?.logo} />
              <StyledClubName
                isAbbreviated
                isNormalAbbreviatedStyle={
                  process.env.REACT_APP_IS_NORMAL_CLUB_ABBREVIATED_STYLE ===
                  "true"
                }
              >
                {activePickem?.sportMatch?.awayTeam?.name}
              </StyledClubName>
            </StyledClub>
          </StyledTeams>
        )}
        {isActivePickemInPlay ? (
          <StyleSpinnerWrapper>
            <StyledSpinnerText>
              Awaiting <br /> Updates...
            </StyledSpinnerText>
            <StyledSpinner lineWidth={5} />
          </StyleSpinnerWrapper>
        ) : isActivePickemApproved ? (
          <StyledMessage>Starting soon</StyledMessage>
        ) : (
          <StyledMessage>
            You ranked {ordinalSuffix(activeBet?.rank)} in this contest
          </StyledMessage>
        )}
      </StyledContent>
    </StyledPopup>
  );
};

export default Wrapper;
