import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { MatIcon } from "../";

const StyledWrapper = styled.div`
  overflow: hidden;
  position: relative;
  background: ${(props) => (props.isSuccess ? "#e7f0ff" : "#fdeeee")};
  color: ${(props) => (props.isSuccess ? "#6596ec" : "#ea5454")};
  border-radius: 0.5rem;
  max-height: ${(props) => (props.isVisible ? "200px" : "0")};
  margin: ${(props) => props.margin || "0"};
  ${(p) => p.isVisible !== undefined && "transition: max-height 0.325s"};
`;

const StyledMessage = styled.div`
  ${"" /* position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; */}
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0.5rem 1rem;
  font-size: 0.875rem;

  & > span,
  & > div {
    line-height: 1.5;
  }
`;

const FormRowMessage = ({ margin, children, isVisible, isSuccess }) => (
  <StyledWrapper margin={margin} isVisible={isVisible} isSuccess={isSuccess}>
    <StyledMessage>
      <MatIcon margin="0 0.5rem 0 0">highlight_off</MatIcon>
      <div>{children}</div>
    </StyledMessage>
  </StyledWrapper>
);

FormRowMessage.defaultProps = {
  isSuccess: false,
};

FormRowMessage.propTypes = {
  isVisible: PropTypes.bool,
  isSuccess: PropTypes.bool,
};

export default FormRowMessage;
