import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { getUsersBet, getUserPicks, setFilterByMe } from "./actions";
import LazyLoad from "react-lazyload";
import { Skeleton } from "fiver-react-ui-components";

import LeaderboardListRow from "./LeaderboardListRow";
import LeaderboardRowHeader from "./shared/LeaderboardRowHeader";

const StyledWrapper = styled.div`
  background: #fdfdfd;
  border-bottom: 3px solid #f4f4f4;
`;

const Wrapper = () => {
  const dispatch = useDispatch();

  const { loggedInUserName, loggedInUserImage } = useSelector((s) => s.app);

  const { table, loading, activeUserBet, isShowingMyBet } = useSelector(
    (s) => s.leaderboard
  );

  const { activePickem, activeBet } = useSelector((s) => s.toolbar);

  const handleRowClicked = (betId, activeUserBetId) => {
    if (
      activePickem?.status === "APPROVED" ||
      (activePickem?.status === "IN_PLAY" &&
        !(activePickem?.hasLiveScoring ?? true))
    ) {
      return;
    }

    if (betId !== activeUserBetId) {
      dispatch(getUsersBet(betId));
    } else {
      dispatch(getUsersBet(undefined));
    }
  };

  const isInPlay = activePickem?.status === "IN_PLAY";
  const isCompleted = activePickem?.status === "COMPLETED";
  const isPreMatchLeaderboard =
    (!isInPlay && !isCompleted) || (isInPlay && !activePickem?.hasLiveScoring);

  // const isPreMatchLeaderboard = !isInPlay && !isCompleted;

  const skeletonRows = new Array(20).fill(0);

  return (
    <div>
      {isShowingMyBet ? (
        <LeaderboardListRow
          isPreMatchLeaderboard={isPreMatchLeaderboard}
          isShowingMyBet={isShowingMyBet}
          isActive
          row={{
            id: activeBet?.betId,
            points: activeBet?.points,
            prize: activeBet?.prize,
            promoPrize: activeBet?.promoPrize,
            rank: activeBet?.rank,
            user: {
              username: loggedInUserName,
              image: loggedInUserImage,
            },
            selections: activeBet?.selections,
          }}
        />
      ) : !table && loading ? (
        <>
          {skeletonRows.map((row, index) => (
            <StyledWrapper>
              <LeaderboardRowHeader isSkeleton index={index} />
            </StyledWrapper>
          ))}
        </>
      ) : (
        table?.map((row, index) => (
          // <LazyLoad
          //   unmountIfInvisible
          //   offset={800}
          //   height={67}
          //   key={row.betId}
          //   placeholder={<Skeleton />}
          // >
          <LeaderboardListRow
            isPreMatchLeaderboard={isPreMatchLeaderboard}
            isShowingMyBet={isShowingMyBet}
            isActive={activeUserBet?.betId === row?.betId ? true : false}
            row={row}
            onRowClicked={() =>
              handleRowClicked(row?.betId, activeUserBet?.betId)
            }
          />
          // </LazyLoad>
        ))
      )}
    </div>
  );
};

export default Wrapper;
