import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Pusher from "pusher-js";
import {
  handleLeaderboardUpdated,
  handleQuestionUpdated,
} from "components/feature/pusher/actions";

const usePusherLeaderboard = () => {
  const dispatch = useDispatch();

  const { activePickem, activeBet } = useSelector((s) => s.toolbar);

  let pusher, channel;

  const onLeaderboardUpdated = (response) => {
    dispatch(handleLeaderboardUpdated(response, activeBet?.betId));
  };

  const onQuestionUpdated = (response) => {
    dispatch(handleQuestionUpdated(response.pickem));
  };

  useEffect(() => {
    if (activePickem) {
      pusher = new Pusher(
        process.env.REACT_APP_IS_LIVE === "false"
          ? process.env.REACT_APP_PUSHER_DEV_KEY
          : process.env.REACT_APP_PUSHER_LIVE_KEY,
        {
          cluster: "eu",
        }
      );

      channel = pusher.subscribe(`Pickem.${activePickem?.id}`);

      if (channel) {
        channel.bind("PickemLeaderboardUpdated", onLeaderboardUpdated);

        channel.bind("PickemQuestionUpdated", onQuestionUpdated);

        console.log("pusher channel", channel);
      }
    }
    return () => {
      return channel && channel?.unbind();
    };
  }, [activePickem]);
};

export default usePusherLeaderboard;
