import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { createImageCdnLink } from "utils";

import LandingTopbar from "./LandingTopbar";
import LandingBanner from "./LandingBanner";
import LandingButtons from "./LandingButtons";
import LandingGeoMessage from "./LandingGeoMessage";
import LandingTerritoryBlocked from "./LandingTerritoryBlocked";
import { useCheckLogin } from "hooks";

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background: ${(p) =>
    process.env.REACT_APP_LANDING_NO_WRAPPER_BACKGROUND !== "true" &&
    p.theme.colorPrimary};
`;

const StyledImage = styled.img`
  position: relative;
  z-index: 1;
  height: 30px;
  margin: 0 auto;
`;

const Landing = (props) => {
  useEffect(() => {
    window.xtremepush(
      "tag",
      "landing_page",
      JSON.stringify({
        AppName: process.env.REACT_APP_TITLE,
      })
    );
    window.xtremepush(
      "tag",
      "web_app_opened",
      JSON.stringify({
        AppName: process.env.REACT_APP_TITLE,
      })
    );
    // window.xtremepush("tag", "LandingPage.Login", JSON.stringify({
    //   AppName: process.env.REACT_APP_TITLE
    // }));
    // window.xtremepush("event", "LandingPage.Login", JSON.stringify({
    //   AppName: process.env.REACT_APP_TITLE
    // }));
  }, []);

  useCheckLogin();

  const { isInBlockedTerritory } = useSelector((state) => state.auth);

  return (
    <StyledWrapper>
      {isInBlockedTerritory && <LandingTerritoryBlocked />}
      <LandingTopbar />
      {process.env.REACT_APP_HIDE_LANDING_BANNER !== "true" && (
        <LandingBanner />
      )}
      {process.env.REACT_APP_SHOW_OPERATED_BY === "true" && (
          <StyledWrapper>
            <StyledImage
              src={createImageCdnLink(process.env.REACT_APP_OPERATED_BY)}
            />
          </StyledWrapper>
        )}
      <LandingButtons isDisabled={isInBlockedTerritory} />
    </StyledWrapper>
  );
};

export default Landing;
