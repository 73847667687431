import React from "react";
import styled, { css } from "styled-components";
import { rgba } from "polished";

const stylesForDarkBackground = css`
  padding: 0.325rem 0;
  margin-bottom: 2.75rem;
  background: linear-gradient(
    to left,
    transparent 0%,
    ${(p) => rgba(p.theme.colorDark, 0.75)} 10%,
    ${(p) => rgba(p.theme.colorDark, 0.75)} 90%,
    transparent 100%
  );
  color: ${(p) => p.theme.colorDarkText};
`;

const StyledWrapper = styled.div`
  width: 80%;
  margin: 0 auto 1rem;
  padding: 0 0 1.75rem;
  font-size: 1.5rem;
  text-align: center;
  color: ${(p) => p.theme.colorLabel};
  background: transparent;

  ${(process.env.REACT_APP_BACKGROUND_IMAGE ||
    process.env.REACT_APP_HAS_DARK_BACKGROUND === "true") &&
  stylesForDarkBackground};
`;

const ContestMakePicks = () => <StyledWrapper>{process.env.REACT_APP_TEXT_PICKS_TO_CHOICE === 'true' ? 'MAKE YOUR CHOICES!' : 'MAKES YOUR PICKS!'}</StyledWrapper>;

export default ContestMakePicks;
