import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { MatIcon, BlockButton } from "fiver-react-ui-components";
import { getLeaderboard } from "../actions";

const StyledWrapper = styled.div`
  background: white;
`;

const StyledInner = styled.div`
  padding: 0.5rem 0 1rem;
`;

const StyledRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
  margin-bottom: 1rem;
`;

const StyledIndex = styled.div`
  flex: 0 0 18px;
  margin-right: 1rem;
  font-size: 1rem;
  font-weight: 700;
  width: 18px;
  height: 18px;
  border-radius: 0.325rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.theme.colorLabel};
`;

const StyledQuestion = styled.div`
  flex: 1 1 auto;
`;

const StyledQuestionText = styled.div`
  margin-bottom: 0.325rem;
  font-size: 0.75rem;
  line-height: 1.35;
`;

const StyledQuestionAnswer = styled.div`
  font-size: 0.75rem;
`;

const StyledPoints = styled.div`
  flex: 0 0 26px;
  height: 26px;
  font-weight: 700;
  font-size: 0.75rem;
  text-align: center;
  margin-left: 1rem;
  background: ${(props) =>
    props.hasPoints ? props.theme.colorSuccess : props.theme.colorLightGrey};
  color: ${(props) => (props.hasPoints ? "white" : "#d0d6de")};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledOptionList = styled.div`
  display: flex;
  padding-top: 4px;
  height: 24px;
`;

const StyledOptionListItem = styled.div`
  position: relative;
  margin-right: 0.325rem;
  color: ${(p) => (p.isCorrect ? p.theme.colorText : p.theme.colorLeaderboardLight)};
`;

const StyledOptionListItemIcon = styled(MatIcon)`
  position: absolute;
  top: 14px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 14px;
  color: ${(p) => (p.isCorrect ? p.theme.colorSuccess : p.theme.colorLeaderboardLight)};
`;

const StyledButtonWrapper = styled.div`
  padding: 0 1rem 1rem;
`;

const Wrapper = () => {
  const dispatch = useDispatch();

  const { activeUserBet, isShowingMyBet } = useSelector((s) => s.leaderboard);
  const { activePickem, activeBet } = useSelector((s) => s.toolbar);
  const { questions } = activePickem;

  const selections = activeUserBet ? activeUserBet.selections : {};

  const getListQuestionAnswer = (options, id) => {
    const answerId = selections?.[id];
    return (
      <StyledOptionList>
        {options.map((opt, i, arr) => {
          return (
            <StyledOptionListItem
              isSelected={opt.id === answerId}
              isCorrect={opt.points}
            >
              {opt.label}
              {opt.id === answerId && (
                <StyledOptionListItemIcon isCorrect={opt.points}>
                  navigation
                </StyledOptionListItemIcon>
              )}
              {i === arr.length - 1 ? "" : ", "}
            </StyledOptionListItem>
          );
        })}
      </StyledOptionList>
    );
  };

  const getQuestionAnswer = (options, id) => {
    const answerId = selections?.[id];
    return answerId ? options?.find((obj) => obj.id === answerId)?.label : "-";
  };

  const hasPoints = (options, id) => {
    const answerId = selections?.[id];
    return answerId
      ? options?.find((obj) => obj.id === answerId)?.points > 0
      : false;
  };

  const handleStopFilteringMyBetClicked = () => {
    window.scrollTo(0, 0);
    dispatch(getLeaderboard(activePickem, activeBet, true));
  };

  return (
    <StyledWrapper>
      <StyledInner>
        {questions?.map((q, index) => (
          <StyledRow>
            <StyledIndex>{index + 1}</StyledIndex>
            <>
              <StyledQuestion>
                <StyledQuestionText>{q.label}</StyledQuestionText>
                <StyledQuestionAnswer>
                  {q.options.length > 2
                    ? getListQuestionAnswer(q.options, q.id)
                    : getQuestionAnswer(q.options, q.id)}
                </StyledQuestionAnswer>
              </StyledQuestion>
              <StyledPoints hasPoints={hasPoints(q.options, q.id)}>
                {q.availablePoints}
              </StyledPoints>
            </>
          </StyledRow>
        ))}
      </StyledInner>

      {isShowingMyBet && (
        <StyledButtonWrapper>
          <BlockButton isAccent onClick={handleStopFilteringMyBetClicked}>
            Full Leaderboard
          </BlockButton>
        </StyledButtonWrapper>
      )}
    </StyledWrapper>
  );
};

export default Wrapper;
