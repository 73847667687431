import React, { useEffect, useState } from "react";
import { handleError } from "utils";
import styled from "styled-components";
import PrizeTimeLeft from "./PrizeTimeLeft";
import PrizeButton from "./PrizeButton";
import PrizeDrawer from "./PrizeDrawer";
import axios from "axios";
import { formatter } from "./ConfirmStep";

const StyledWrapper = styled.div`
  margin: 0 0 1.5rem;
  padding: 1rem 1rem 1.5rem;
  background: white;
`;

const StyledBanner = styled.div`
  ${"" /* min-height: 9.6875rem; */}
  position: relative;
  margin-bottom: 0.625rem;
`;

const StyledBannerText = styled.div`
  font-size: 4rem;
  line-height: 0.8;
  letter-spacing: -0.02rem;
  text-transform: uppercase;
  color: ${(p) => p.theme.colorSuccess};
  text-align: center;
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  right: 0;
`;

const StyledPrizeImg = styled.img`
  margin: 0 auto;
  width: 71.5vw;
  display: block;
  position: relative;
  z-index: 1;
  padding-top: 1.5rem;
  margin-bottom: 1rem;
  @media (min-width: ${(props) => props.theme.screenWidthMd}) {
    width: 71.5%;
  }
`;

export const StyledDateCounter = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 0.625rem;
  flex-wrap: wrap;
`;

const BannerDescription = styled.div`
  font-size: 16px;
  line-height: 18px;
  text-transform: uppercase;
  text-align: center;
  color: #696971;
  font-weight: bold;
  margin-bottom: 0.625rem;
`;

const StyledBannerSmallPrint = styled.div`
  color: ${(p) => p.theme.colorLabel};
  text-align: center;
  margin-bottom: 1rem;
  font-size: 0.75rem;

  & > a {
    font-size: 0.75rem;
    color: ${(p) => p.theme.colorSuccess};
    text-decoration: underline;
  }
`;

export const BannerControls = styled.div`
  display: flex;
`;

export const StyledAmount = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 44px;
  height: 44px;
  border: 1px solid #e4e4e4;
  box-sizing: border-box;
  border-radius: 4px;
  margin-left: ${(p) => (p.marginLeft ? p.marginLeft + "px" : 0)};
  margin-right: ${(p) => (p.marginRight ? p.marginRight + "px" : 0)};
  position: relative;
  z-index: 0;
  background: white;
  &::before {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    bottom: 0;
    left: 0;
    right: 0;
    background: #f1f1f1;
    z-index: -1;
  }
`;
export const StyledTotal = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  font-size: 0.5rem;
`;

export const StyledTotalIndex = styled.div`
  flex: 1;
  width: 98px;
  height: 44px;
  border: 1px solid #e4e4e4;
  box-sizing: border-box;
  border-radius: 4px;
  font-size: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 0;
  margin-left: ${(p) => (p.marginLeft ? p.marginLeft + "px" : 0)};
  margin-right: ${(p) => (p.marginRight ? p.marginRight + "px" : 0)};
  background: white;
  &::before {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    bottom: 0;
    left: 0;
    right: 0;
    background: #f1f1f1;
    z-index: -1;
  }
`;

const StyledPrizeDescription = styled.div`
  color: ${(p) => p.theme.colorSuccess};
  text-align: center;
  margin-bottom: 1rem;
`;

export const createPrizeImageCdnLink = (fileName) => {
  return `https://cdn.jsdelivr.net/gh/low6dev/prize-draw-assets@master/${fileName}`;
};

export const mock = {
  data: {
    game_id: 4,
    image: {
      url: "https://s3.eu-west-2.amazonaws.com/low6-assets-local/32/kenny.jpg",
    },
    name: "win me",
    description: "you will be able to use this field for any additional text",
    prize: {
      money: {
        pounds: "110.00",
        pence: 11000,
      },
      name: "mini \n cooper s",
    },
    price: 2,
    maxPerUser: 20,
    winnerDrawn: "2021-02-24 12:00:00",
    tickets: {
      remaining: 15000,
    },
    questions: [
      {
        question: "The 2022 FIFA World Cup will be held in which country?",
        answers: [
          {
            id: 1,
            answer: "Qatar",
          },
          {
            id: 2,
            answer: "Bahrain",
          },
          {
            id: 3,
            answer: "Kuwait",
          },
          {
            id: 4,
            answer: "Oman",
          },
        ],
      },
    ],
    ticket_price: {
      pence: 100,
    },
    date: {
      end: {
        note:
          "contains date in dateTime format (property.string) and expanded date elements ( property.date_time)",
        string: "2021-02-18 12:00:00",
        date_time: {
          year: 2021,
          month: {
            name: {
              long: "February",
              short: "Feb",
              number: 2,
            },
          },
          day: {
            name: {
              long: "Monday",
              short: "Mon",
              number: 15,
            },
          },
          hour: "12",
          minute: "00",
          seconds: "00",
        },
      },
      remaining: {
        note: "contains remaining time broken down as key value pairs",
        year: 0,
        month: 0,
        day: 2,
        hour: 2,
        minute: 20,
        second: 9,
      },
    },
  },
};

const PrizeDraw = ({ nonUkUser }) => {
  const [amount, setAmount] = useState(1);
  const [finalPrice, setFinalPrice] = useState(0);
  const [isOpenQuestion, setIsOpenQuestion] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [prizeContest, setPrizeContest] = useState(undefined);
  const [wallet, setWallet] = useState(undefined);
  const [profile, setProfile] = useState(undefined);

  useEffect(() => {
    getPrizeContestData();
  }, []);

  useEffect(() => {
    if (prizeContest) {
      setFinalPrice(amount * (prizeContest?.ticket_price?.pence / 100));
    }
  }, [amount, prizeContest]);

  const getEndDate = (date) => {
    if (date) {
      let suffix = "";
      switch (+date?.day.name.number.toString().split("").pop()) {
        case 1:
          suffix = "ST";
          break;
        case 2:
          suffix = "ND";
          break;
        case 3:
          suffix = "RD";
          break;
        default:
          suffix = "TH";
          break;
      }

      return (
        <>
          {`${date?.month?.name?.long} ${date?.day?.name?.number}`}
          <sup
            style={{
              fontSize: "0.65rem",
            }}
          >
            {suffix}
          </sup>
        </>
      );
    }
  };

  const getPrizeContestData = async () => {
    try {
      let contest, wallet, profile;
      setIsLoading(true);

      contest = await axios.post(`prize_draw/game`);
      wallet = await axios.get(`/wallet`);
      profile = await axios.get(
        `/me?include=address,roles,permissions&server-time`
      );

      setPrizeContest(contest.data.data);
      setWallet(wallet.data.data);
      setProfile(profile.data.data);
      setIsLoading(false);
    } catch ({ response }) {
      handleError(response);
    }
  };

  return (
    <>
      {!nonUkUser && prizeContest && !Array.isArray(prizeContest) && wallet && (
        <StyledWrapper>
          <StyledBanner>
            <StyledBannerText>
              {prizeContest?.descriptions?.short}
            </StyledBannerText>
            <StyledPrizeImg src={prizeContest?.image?.url} alt="prize" />
          </StyledBanner>
          <StyledPrizeDescription>
            {prizeContest?.descriptions?.long}
          </StyledPrizeDescription>
          <StyledDateCounter>
            <PrizeTimeLeft date={prizeContest.date.end.string} />
            {/*<TicketsLeft initial={prizeContest?.tickets?.remaining}></TicketsLeft>*/}
          </StyledDateCounter>
          <BannerDescription>
            PRIZE DRAW ENDS {getEndDate(prizeContest?.date?.end?.date_time)}
          </BannerDescription>
          <StyledBannerSmallPrint>
            For postal entry route see general terms{" "}
            <a target="_blank" href="https://winme.tv/terms/">
              here
            </a>
          </StyledBannerSmallPrint>
          <BannerControls>
            <PrizeButton
              disabled={amount === 1}
              minus
              marginRight={10}
              callback={() => setAmount((prev) => (prev === 1 ? 1 : prev - 1))}
            />
            <StyledAmount marginRight={10}>{amount}</StyledAmount>
            <PrizeButton
              disabled={amount === prizeContest.maximum_per_user}
              plus
              marginRight={10}
              callback={() => setAmount((prev) => prev + 1)}
            />
            <StyledTotal>
              total <br />
              cost
              <StyledTotalIndex marginRight={10} marginLeft={10}>
                {formatter.format(finalPrice)}
              </StyledTotalIndex>
            </StyledTotal>
            <PrizeButton
              caretRight
              callback={() => {
                setIsOpenQuestion(true);
                window.xtremepush(
                  "tag",
                  "prizedraw_question",
                  JSON.stringify({
                    AppName: process.env.REACT_APP_TITLE,
                  })
                );
              }}
            />
          </BannerControls>
          <PrizeDrawer
            isOpen={isOpenQuestion}
            toggleDrawer={setIsOpenQuestion}
            amount={amount}
            setAmount={setAmount}
            contest={prizeContest}
            wallet={wallet}
            finalPrice={finalPrice}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
          />
        </StyledWrapper>
      )}
    </>
  );
};

export default PrizeDraw;
