import React from "react";
import PropTypes from "prop-types";
import styled, { css, keyframes } from "styled-components";

const shimmerAnimation = keyframes`
  0% { transform: translateX(-100%) translateY(-50%) rotate(15deg);  }
  100% { transform: translateX(100%) translateY(-50%) rotate(15deg); }
`;

const shimmer = css`
  &::after {
    content: "";
    position: absolute;
    z-index: 3;
    top: 50%;
    left: 0;
    width: 280px;
    height: 1200px;
    background: #e2e5e8;
    animation: ${shimmerAnimation} 1500ms linear infinite;
  }
`;

const StyledShimmerSvg = styled.svg`
  position: absolute;
  animation: ${shimmerAnimation} 1500ms linear infinite;
`;

const StyledShimmerPath = styled.path`
  fill: #e2e5e8;
`;

const StyledSkeleton = styled.span`
  display: ${(props) => props.display};
  position: relative;
  overflow: hidden;
  border-radius: ${(props) =>
    props.isCircle ? "50%" : props.skeletonBorderRadius};
  background: ${(props) =>
    props.isDarker ? props.theme.colorSkeleton : props.theme.colorLightGrey};
  width: ${(props) => props.skeletonWidth};
  height: ${(props) => props.skeletonHeight};
  margin: ${(props) => props.margin};
  -webkit-mask-image: -webkit-radial-gradient(white, black);

  ${"" /* ${(props) => !props.noShimmer && shimmer}; */}
`;

const Skeleton = ({
  noShimmer,
  className,
  skeletonWidth,
  skeletonHeight,
  skeletonBorderRadius,
  isCircle,
  isDarker,
  margin,
  display,
}) => (
  <StyledSkeleton
    className={className}
    skeletonWidth={skeletonWidth}
    skeletonHeight={skeletonHeight}
    skeletonBorderRadius={skeletonBorderRadius}
    isCircle={isCircle}
    isDarker={isDarker}
    margin={margin}
    display={display}
    noShimmer={noShimmer}
  >
    {!noShimmer && (
      <StyledShimmerSvg
        width="500"
        height="400"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <StyledShimmerPath d="M151 0.5H431L280 1200H0L151 0.5Z" />
      </StyledShimmerSvg>
    )}
  </StyledSkeleton>
);

Skeleton.defaultProps = {
  skeletonWidth: "100px",
  skeletonHeight: "10px",
  skeletonBorderRadius: process.env.REACT_APP_BORDER_RADIUS,
  isCircle: false,
  isDarker: false,
  margin: "0",
  display: "inline-block",
};

Skeleton.propTypes = {
  skeletonWidth: PropTypes.string,
  skeletonHeight: PropTypes.string,
  skeletonBorderRadius: PropTypes.string,
  isCircle: PropTypes.bool,
  isDarker: PropTypes.bool,
  margin: PropTypes.string,
  display: PropTypes.string,
};

export default Skeleton;
