import React from "react";
import styled from "styled-components";
import { createImageCdnLink } from "utils";

const StyledImage = styled.div`
  height: 120px;
  width: 100%;
  background-repeat: no-repeat;
  background-image: url("${(props) => props.image}");
  background-position: center top;
  background-size: contain;
  margin-bottom: 1rem;
`;

const ContestPicksAvatar = ({ avatar }) => {
  return (
    <StyledImage
      image={
        avatar
          ? avatar
          : process.env.REACT_APP_CLUB_LOGO === "true"
          ? createImageCdnLink(process.env.REACT_APP_CLUB_LOGO)
          : process.env.REACT_APP_NAV_ICON === "true"
          ? createImageCdnLink(process.env.REACT_APP_NAV_ICON)
          : null
      }
    />
  );
};

export default ContestPicksAvatar;
