import { useEffect } from "react";
import styled, { css, keyframes } from "styled-components";

const activeStyles = css`
  visibility: visible;
  opacity: 1;
`;

const StyledWrapper = styled.div`
  overflow: hidden;
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  background: white;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.875rem;

  visibility: hidden;
  opacity: 0;

  ${(p) => p.isActive && activeStyles};

  transition-property: visibility, opacity;
  transition-duration: 0.25s;
`;

const slide = keyframes`
  0% { transform: translate(-50%, -50%) scale(0.5);}
  50% { transform: translate(-50%, -50%) scale(1);; }
  100% { transform: translate(-50%, -50%) scale(0.5);; }
`;

const StyledContent = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0.5);
  transform-origin: 50% 50%;
  animation: ${slide} linear 3 1.05s;
`;

const Wrapper = ({ children, isActive, onAnimationEnd }) => {
  return (
    <StyledWrapper isActive={isActive}>
      <StyledContent onAnimationEnd={onAnimationEnd}>{children}</StyledContent>
    </StyledWrapper>
  );
};

export default Wrapper;
