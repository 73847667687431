import { useSelector } from "react-redux";
import styled from "styled-components";
import ContestBannerHelp from "./ContestBannerHelp";
import ContestBannerFixture from "./ContestBannerFixture";
import ContestBannerPrizepool from "./ContestBannerPrizepool";
import ContestBannerPlayAgain from "./ContestBannerPlayAgain";
import MaxBetWarning from "./ContestBannerMaxBet";
import { Card } from "components/shared";

const StyledWrapper = styled.div`
  padding: ${(p) => (p.hasPrizeDraw ? "1rem" : "4rem")} 0 3rem;

  ${(p) => p.isMinimal && "padding: 0 0 3rem;"};
`;

const StyledCard = styled(Card)`
  ${(p) => p.isMinimal && "padding: 0; background: transparent;"};
  border-radius: ${(p) => p.theme.borderRadius};
`;

const ContestBanner = ({
  homeTeamLogo,
  homeTeamName,
  awayTeamLogo,
  awayTeamName,
  prizepool,
  split,
}) => {
  const { activePickem, activeBet } = useSelector((s) => s.toolbar);

  const hasPrizeDraw = process.env.REACT_APP_PRIZE_DRAW === "true";

  const isMinimal = process.env.REACT_APP_PICKS_BANNER_IS_MINIMAL === "true";

  return (
    <StyledWrapper isMinimal={isMinimal} hasPrizeDraw={hasPrizeDraw}>
      <StyledCard isMinimal={isMinimal}>
        {!isMinimal && (
          <>
            <ContestBannerFixture
              homeTeamName={homeTeamName}
              homeTeamLogo={homeTeamLogo}
              awayTeamLogo={awayTeamLogo}
              awayTeamName={awayTeamName}
            />
            {process.env.REACT_APP_HIDE_MONEY_REFERENCES !== "true" && (
              <ContestBannerPrizepool prizepool={prizepool} />
            )}
            {activePickem?.status === "APPROVED" &&
              activePickem?.maxBetsPerUser > 1 &&
              activeBet !== 0 &&
              activeBet && (activePickem?.usersBets?.length !== activePickem?.maxBetsPerUser) && <ContestBannerPlayAgain />}
            {(activePickem?.usersBets?.length === activePickem?.maxBetsPerUser &&
              activePickem?.maxBetsPerUser > 1 &&
              activePickem?.status === "APPROVED") && <MaxBetWarning />}
          </>
        )}

        <ContestBannerHelp prizepool={prizepool} split={split} />
      </StyledCard>
    </StyledWrapper>
  );
};

export default ContestBanner;
