import React from "react";
import { useSelector } from "react-redux";
import { FloatingHeader, Alert } from "components/shared";

const ContestFloatingHeaderVerify = () => {
  const { isScrolledDown } = useSelector((state) => state.app);
  return (
    <FloatingHeader isScrolledDown={isScrolledDown} justifyContent="center">
      <Alert isDanger style={{ padding: "0.5rem 0.75rem" }}>
        Warning: Verify Phone in Profile to make picks
      </Alert>
    </FloatingHeader>
  );
};

export default ContestFloatingHeaderVerify;
