import React from "react";
import styled from "styled-components";
import { MatIcon, Low6Logo } from "../";
import { createImageCdnLink } from "utils";
import { rgba } from "polished";
import { isIOS } from "react-device-detect";

const StyledMainButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 50px;
  padding: 0 0.5rem 0 0.75rem;
  border-radius: 0.5rem;
  background: ${(props) =>
    props.isRed ? props.theme.colorDanger : props.theme.colorPrimary};
  color: ${(props) => props.theme.colorPrimaryText};

  ${(p) =>
    process.env.REACT_APP_COLOR_PRIMARY_IS_WHITE &&
    `border: 2px solid ${p.theme.colorAccent}; color: ${p.theme.colorAccent}`};
`;

const StyledEndPiece = styled.div`
  flex: 0 0 50px;
  overflow: hidden;
`;

const StyledButtonText = styled.span`
  font-size: 0.875rem;
`;

const StyledCtaIcon = styled.img`
  height: 32px;
`;

const Low6Button = (props) => (
  <StyledMainButton
    href={
      process.env.REACT_APP_CTA_URL ||
      (isIOS
        ? "https://apps.apple.com/gb/app/low6/id1438093860"
        : "https://play.google.com/store/apps/developer?id=low6+LTD&hl=en")
    }
    target="_blank"
  >
    <StyledEndPiece>
      {process.env.REACT_APP_CTA_ICON ? (
        <StyledCtaIcon
          src={createImageCdnLink(process.env.REACT_APP_CTA_ICON)}
        />
      ) : (
        <Low6Logo />
      )}
    </StyledEndPiece>
    <StyledButtonText>
      {process.env.REACT_APP_CTA_TEXT || "Bet On More Sports"}
    </StyledButtonText>
    <StyledEndPiece style={{ textAlign: "right" }}>
      <MatIcon>arrow_forward_ios</MatIcon>
    </StyledEndPiece>
  </StyledMainButton>
);

export default Low6Button;
