import { HelmetProvider, Helmet } from "react-helmet-async";
import { createImageCdnLink } from "@low6dev/react-utils/dist";

const Wrapper = () => {
  const isEnabled = process.env.REACT_APP_HAS_GOOGLE_TAG_MANAGER === "true";

  return isEnabled ? (
    <HelmetProvider>
      <Helmet>
        <>
          <script
            async
            src="https://www.googletagmanager.com/gtag/js?id=G-V904C3CJEC"
          ></script>
          <script
            async
            src={createImageCdnLink("scripts/google-tag-manager.js")}
            id="gtm-script"
          ></script>
        </>
      </Helmet>
    </HelmetProvider>
  ) : null;
};

export default Wrapper;
