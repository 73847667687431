import styled from "styled-components";
import { createImageCdnLink } from "utils";

const StyledImage = styled.img`
  width: 35%;
  display: block;
  margin: 0 auto 1.5rem;
`;

const Wrapper = () => {

  return (
    <StyledImage
    src={createImageCdnLink(process.env.REACT_APP_SPORTSRADAR_BANNER)}
  />
  )
};

export default Wrapper;
