import React from "react";
import styled from "styled-components";

const StyledPoints = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 58px;
  border-radius: ${(p) => p.theme.borderRadius};
  font-size: 0.75rem;

  background: ${(p) => p.theme.colorPrimary};
  color: ${(p) => p.theme.colorPrimaryText};

  ${(p) =>
    p.isCorrect &&
    `background: ${p.theme.colorSuccess}; color: ${p.theme.colorSuccessText}`};
  ${(p) =>
    p.isIncorrect &&
    `background: ${p.theme.colorDanger}; color: ${p.theme.colorDangerText}`};

  ${(p) =>
    process.env.REACT_APP_COLOR_PRIMARY_IS_WHITE &&
    `border: 2px solid ${p.theme.colorLightGrey}`};
`;

const StyledPointsNumber = styled.div`
  margin-bottom: 0.25rem;
  font-size: 1.25rem;
  font-weight: 700;
  text-align: center;
`;

const ContestPicksPoints = ({
  availablePoints,
  correctAnswerId,
  myAnswerId,
  isLocked,
  showIcon,
}) => {
  const isAnswered = myAnswerId !== -1;

  return (
    <StyledPoints
      isCorrect={
        isAnswered && isLocked && showIcon && correctAnswerId === myAnswerId
      }
      isIncorrect={
        isAnswered && isLocked && showIcon && correctAnswerId !== myAnswerId
      }
    >
      <StyledPointsNumber>{availablePoints}</StyledPointsNumber>
      {availablePoints === 1 ? "point" : "points"}
    </StyledPoints>
  );
};

export default ContestPicksPoints;
