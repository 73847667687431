import { useState, useEffect } from "react";
import styled from "styled-components";
import { Skeleton } from "fiver-react-ui-components";
import blink from "assets/images/blinker.gif";

const StyledWrapper = styled.span`
  position: relative;
  display: flex;
  line-height: 1;
`;

const StyledBlinker = styled.img`
  position: absolute;
  top: 1px;
  right: -2px;
  width: 1.5px;
  height: 3px;
`;

const Wrapper = ({ matchTime }) => {
  return (
    <>
      {matchTime > -1 ? (
        <StyledWrapper>
          {matchTime}
          <StyledBlinker src={blink} />
        </StyledWrapper>
      ) : (
        <Skeleton skeletonWidth="30px" skeletonHeight="7px" margin="1px 0 0" />
      )}
    </>
  );
};

export default Wrapper;
