import React from "react";
import styled from "styled-components";
import { FaYoutube } from 'react-icons/fa';
import { createImageCdnLink } from "utils";

const StyledWrapper = styled.div`
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-top: solid 2px;
  white-space: nowrap;
  border-color: ${(props) => props.theme.colorLightGrey};
`;
const Header = styled.div`
  font-size: 5rem;
  color: ${(props) => props.theme.colorSuccess};
  @media(max-width:450px){
    font-size: 3.6rem;

  }

  text-transform: uppercase;
`;
const Image = styled.img`
  height: 180px;
  margin-top: -50px;

  @media(max-width:450px){
    height:140px;
    margin-top: -40px;

  }
`;
const SubHeader = styled.div`
  font-size: 2rem;
  color: ${(props) => props.theme.colorText};
  text-transform: uppercase;
  @media(max-width:450px){
    font-size: 1.4rem;

  }
`;
const Winner = styled.div`
  color: ${(props) => props.theme.colorSuccess};
  font-size: 2rem;
  text-transform: uppercase;
  @media(max-width:450px){
    font-size: 1.4rem;

  }
`;
const SmallText = styled.div`
  font-size: 1.2rem;
  color: ${(props) => props.theme.colorText};
  margin-top: 0.5rem;
  @media(max-width:450px){
    font-size: 0.8rem;

    
  }
`;
const Button = styled.a`
background-color: ${(props) => props.theme.colorSuccess};
height:60px;


color: white;
text-transform: uppercase;
font-size: 1.5rem;
border-radius:0.5rem;
text-align: center;
display: flex;
flex-direction: row;
justify-content: center; 
align-items: center;
.youtube{
    font-size:2rem;
    margin: 0 1rem;
}
@media(max-width:450px){
    font-size: 1.2rem;

    .youtube{
    font-size:1.6rem;
    margin: 0 0.5rem;
}
  }
`
const StyledA = styled.a`
  outline: none;
  text-decoration: none;
  width:95%;
height:60px;
margin-top: 1rem;
margin-bottom: 1rem;
`
const BannerPrizeDraw = () => {

  return (
    <StyledWrapper>
      <Header> live draw </Header> <Image src={createImageCdnLink(
          "m4soldout.png"
        )} />
      <SubHeader>its time to announce our</SubHeader>
      <Winner>Winner!</Winner>
      <SmallText>Join us on March 10th, 2021 at 6pm</SmallText>
 <StyledA  href="https://www.youtube.com/channel/UCEsewrvf9Ds_bzhgMgeKSCw" target="_blank">   <Button><FaYoutube className="youtube"/> watch live draw here</Button></StyledA>
    </StyledWrapper>
  );
};

export default BannerPrizeDraw;
