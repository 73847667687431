import {
  SET_AUTH,
  FORGOT_PASSWORD,
  CHANGE_PASSWORD,
  CHANGE_PIN,
  SET_IS_GEOCHECKED,
  SET_COOLING_OFF_DURATION,
  SET_IS_IN_BLOCKED_TERRITORY,
} from "./actions";

const initialState = {
  emailExists: undefined,
  emailLoading: undefined,
  userNameExists: undefined,
  userNameLoading: undefined,
  postCodeLoading: undefined,
  postCodeOptions: undefined,
  changePasswordError: undefined,
  changePinError: undefined,
  gdprRequired: undefined,
  isGeoChecked: undefined,
  location: undefined,
  coolingOffData: undefined,
  isInBlockedTerritory: undefined,
  isShowingConsentPopup: undefined,
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case SET_AUTH:
      return {
        ...state,
        ...payload,
      };
    case FORGOT_PASSWORD:
      return {
        ...state,
        ...payload,
      };
    case CHANGE_PASSWORD:
      return {
        ...state,
        changePasswordError: payload,
      };
    case SET_IS_IN_BLOCKED_TERRITORY:
      return {
        ...state,
        isInBlockedTerritory: payload,
      };
    case CHANGE_PIN:
      return {
        ...state,
        changePinError: payload,
      };
    case SET_IS_GEOCHECKED:
    case SET_COOLING_OFF_DURATION:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
}
