import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { MatIcon, BlockButton } from "fiver-react-ui-components";
import { getLeaderboard } from "../actions";

const StyledWrapper = styled.div`
  background: white;
`;

const StyledInner = styled.div`
  padding: 0.5rem 0 1rem;
`;

const StyledRow = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  margin: 0 1rem 1rem;
  border-radius: ${(p) => p.theme.borderRadius};
  background: #fbfbfb;

  @media (min-width: ${(props) => props.theme.screenWidthMd}) {
    gap: 2rem;
  }
`;

const StyledQuestion = styled.div`
  flex: 1 1 40%;
  font-size: 0.875rem;
  line-height: 1.35;
`;

const StyledQuestionAnswer = styled.div`
  flex: 1 1 30%;
  font-size: 0.875rem;
`;

const StyledIcon = styled(MatIcon)`
  flex: 0 0 26px;
  height: 26px;
  font-weight: 700;
  font-size: 1.75rem;
  text-align: center;
  margin-left: 1rem;
  color: ${(props) =>
    props.hasPoints ? props.theme.colorSuccess : props.theme.colorDanger};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledPoints = styled.span`
  font-size: 1.25rem;
  color: ${(p) => (p.hasPoints ? p.theme.colorText : "#ccd2da")};
`;

const StyledButtonWrapper = styled.div`
  padding: 0 1rem 1rem;
`;

const Wrapper = () => {
  const dispatch = useDispatch();

  const { activeUserBet, isShowingMyBet } = useSelector((s) => s.leaderboard);
  const { activePickem, activeBet } = useSelector((s) => s.toolbar);
  const { questions } = activePickem;

  const selections = activeUserBet ? activeUserBet.selections : {};

  const getQuestionAnswer = (options, id) => {
    const answerId = selections?.[id];
    return answerId ? options?.find((obj) => obj.id === answerId)?.label : "-";
  };

  const hasPoints = (options, id) => {
    const answerId = selections?.[id];
    return answerId
      ? options?.find((obj) => obj.id === answerId)?.points > 0
      : false;
  };

  const handleStopFilteringMyBetClicked = () => {
    window.scrollTo(0, 0);
    dispatch(getLeaderboard(activePickem, activeBet, true));
  };

  return (
    <StyledWrapper>
      <StyledInner>
        {questions?.map((q) => (
          <StyledRow>
            <>
              <StyledQuestion>{q.label}</StyledQuestion>

              <StyledQuestionAnswer>
                {getQuestionAnswer(q.options, q.id)}
              </StyledQuestionAnswer>
              <StyledIcon hasPoints={hasPoints(q.options, q.id)}>
                {hasPoints(q.options, q.id) ? "check_circle" : "cancel"}
              </StyledIcon>
              <StyledPoints hasPoints={hasPoints(q.options, q.id)}>
                {q.availablePoints}
              </StyledPoints>
            </>
          </StyledRow>
        ))}
      </StyledInner>

      {isShowingMyBet && (
        <StyledButtonWrapper>
          <BlockButton isAccent onClick={handleStopFilteringMyBetClicked}>
            Full Leaderboard
          </BlockButton>
        </StyledButtonWrapper>
      )}
    </StyledWrapper>
  );
};

export default Wrapper;
