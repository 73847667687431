import {
  CHECK_LOGIN,
  SET_IS_LOGGING_IN,
  SET_GDPR_ACCEPTED,
  CLEAR_LOGIN,
} from "./actions";

const initialState = {
  isLoggingIn: undefined,
  gdprAccepted: undefined,
  loginSuccess: undefined,
  loginError: undefined,
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case CHECK_LOGIN:
      return {
        ...state,
        ...payload,
      };
    case SET_IS_LOGGING_IN:
      return {
        ...state,
        isLoggingIn: payload,
      };
    case SET_GDPR_ACCEPTED:
      return {
        ...state,
        gdprAccepted: payload,
      };
    case CLEAR_LOGIN:
      return {
        ...state,
        gdprAccepted: payload,
        isLoggingIn: payload,
      };
    default:
      return state;
  }
}
