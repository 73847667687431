import styled, { keyframes } from "styled-components";

import { useSelector, useDispatch } from "react-redux";

import { IconBlock } from "./middle/shared";

import {
  Skeleton,
  DateTime,
  PenceToPounds,
  MatIcon,
} from "fiver-react-ui-components";

import { setToolbar } from "./actions";

const StyledWrapper = styled.button`
  position: relative;
  overflow: hidden;
  width: 100%;
  padding: 0;
`;

const StyledWrapperContent = styled.span`
  position: relative;
  z-index: 2;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  height: 3rem;
  width: 100%;
  padding: 0 0.5rem;
`;

const shimmerKeyframes = keyframes`
  0% {
    transform: skewX(-20deg) translateX(-800px);
  }
  100% {
    transform: skewX(-20deg) translateX(5000px);
  }
`;

const StyledWrapperShimmer = styled.span`
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 0;
  width: 300px;
  height: 5rem;
  margin-top: -2.5rem;
  background: ${(p) => p.theme.colorSkeleton};
  transform: skewX(-20deg);
  animation: ${shimmerKeyframes} 5s infinite linear;
`;

const StyledTitleBlock = styled.div`
  min-width: 0;
  flex: 1 1 auto;
  position: relative;
  text-transform: uppercase;
`;

const StyledTitleBlockTitle = styled.div`
  font-size: 0.875rem;
  color: ${(p) => p.theme.colorText};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StyledTitleBlockSubtitle = styled.div`
  color: ${(p) =>
    process.env.REACT_APP_COLOR_EVENT_SUBTITLE || p.theme.colorAccent};
  font-size: 0.75rem;
`;

const StyledDropdownIcon = styled(MatIcon)`
  display: grid;
  place-items: center;
  font-size: 19px;
  width: 28px;
  min-width: 28px;
  max-width: 28px;
  height: 28px;
  min-height: 28px;
  max-height: 28px;
  color: ${(p) => p.theme.colorPrimaryText};
  border-radius: 50%;
  background: ${(p) => p.theme.colorPrimary};
`;

const Wrapper = () => {
  const dispatch = useDispatch();

  const { activePickem, activePickemList, isBetsOpen } = useSelector(
    (s) => s.toolbar
  );

  const handleDropdownClicked = () => {
    if (activePickemList?.length > 1) {
      dispatch(
        setToolbar({
          isBetsOpen: !isBetsOpen,
        })
      );
    }
  };

  const isPromoPrize = activePickem?.promoPrizeFund > 0;

  const prizeLabel =
    activePickem?.pickemTexts?.betBarPrizeLabel ||
    process.env.REACT_APP_TEXT_PRIZEPOOL ||
    (isPromoPrize ? "Promo Prize" : "Prize Pool");

  const customPrize =
    activePickem?.pickemTexts?.betBarPrizeText ||
    process.env.REACT_APP_TEXT_PRIZE;

  return (
    <StyledWrapper
      disabled={activePickemList?.length < 2}
      onClick={handleDropdownClicked}
    >
      <StyledWrapperContent>
        {activePickemList?.length > 1 && (
          <StyledDropdownIcon>
            {isBetsOpen ? "close" : "expand_more"}
          </StyledDropdownIcon>
        )}
        {activePickem?.name ? (
          <StyledTitleBlock>
            <StyledTitleBlockTitle>{activePickem?.name}</StyledTitleBlockTitle>
            <StyledTitleBlockSubtitle>
              {activePickem?.featuredTitle && (
                <span>{activePickem?.featuredTitle}</span>
              )}
              {/* <DateTime format="D MMMM">{activePickem?.start}</DateTime> */}
            </StyledTitleBlockSubtitle>
          </StyledTitleBlock>
        ) : (
          <Skeleton skeletonHeight="1.5rem" skeletonWidth="108px" />
        )}

        {activePickem?.prizeFund ||
        process.env.REACT_APP_TEXT_PRIZE ||
        customPrize ? (
          <div>
            <IconBlock
              isSuccess
              label={prizeLabel}
              icon="emoji_events"
              value={
                customPrize || (
                  <PenceToPounds showCurrencySymbol>
                    {activePickem?.prizeFund}
                  </PenceToPounds>
                )
              }
            />
          </div>
        ) : (
          <Skeleton skeletonHeight="1.5rem" skeletonWidth="108px" />
        )}
      </StyledWrapperContent>
    </StyledWrapper>
  );
};

export default Wrapper;
