import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { rgba } from "polished";
import { MatIcon, Spinner, PenceToPounds } from "fiver-react-ui-components";

const translateY = (props) =>
  props.isOpen ? (props.hasLogoBottombar ? "0" : "0") : "110%";

const StyledWrapper = styled.button`
  position: fixed;
  z-index: 6;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: ${(props) =>
    props.hasLogoBottombar ? "2.5rem 1rem 3.5rem" : "1.5rem 1rem"};

  box-shadow: 0px -4px 15px ${rgba("black", 0.08)};
  background: ${(props) => props.theme.colorSuccess};
  color: white;
  opacity: ${(props) => (props.isOpen ? 1 : 0)};
  transform: translateY(${(p) => translateY(p)});
  transition-property: transform, opacity;
  transition-duration: 0.45s;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  @media (min-width: ${(props) => props.theme.screenWidthMd}) {
    left: 50%;
    width: ${(props) => props.theme.railWidth};
    margin: 0 auto;
    bottom: 0;
    transform: translateY(${(p) => translateY(p)}) translateX(-50%);
  }
`;

const StyledButton = styled.span`
  justify-content: center;
  background: ${(p) => p.theme.colorSuccessText};
  color: ${(p) => p.theme.colorSuccess};
  border: 4px solid ${(props) => props.theme.colorSuccessText};
  border-radius: 2rem;
  min-width: 75%;
  height: 4rem;
  white-space: nowrap;
  font-size: 1.5rem;
  padding: ${(props) => (props.isLoading ? 0 : "0 1rem 0 0")};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const StyledMatIcon = styled(MatIcon)`
  font-size: 44px;
`;

const StyledText = styled.div`
  flex: 1;
  text-align: center;
  padding: 0 1rem 0 2rem;
`;

const StyledSpinner = styled(Spinner)`
  border-color: ${(p) => rgba(p.theme.colorSuccess, 0.15)};
  border-top-color: ${(p) => p.theme.colorSuccess};
`;

const ContestBetNow = ({ isOpen, isLoading, placeBet, activePickemId }) => {
  const { picks } = useSelector((s) => s.contest);
  const { activePickem } = useSelector((s) => s.toolbar);

  return (
    <StyledWrapper
      onClick={() => placeBet(activePickemId, picks)}
      isOpen={isOpen}
      hasLogoBottombar={process.env.REACT_APP_NAV_ICON}
    >
      <StyledButton isLoading={isLoading}>
        {isLoading ? (
          <StyledSpinner forDark={false} lineWidth={6} />
        ) : (
          <>
            <StyledText>
              {activePickem?.stake > 0 ? (
                <>
                  Place £<PenceToPounds>{activePickem?.stake}</PenceToPounds>{" "}
                  Bet
                </>
              ) : (
                `Submit ${process.env.REACT_APP_TEXT_PICKS || "Picks"}`
              )}
            </StyledText>
            <StyledMatIcon>chevron_right</StyledMatIcon>
          </>
        )}
      </StyledButton>
    </StyledWrapper>
  );
};

export default ContestBetNow;
