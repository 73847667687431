import { useSelector } from "react-redux";
import styled from "styled-components";
import { Skeleton, PenceToPounds, Alert } from "fiver-react-ui-components";

const StyledPrizePoolLabel = styled.div`
  text-align: center;
  font-size: 1rem;
  margin-bottom: 0.5rem;
`;

const StyledPrizepool = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: 2rem;
  font-size: ${(p) => (p.isMoney ? "3.25rem" : "2.25rem")};
  color: ${(props) => props.theme.colorSuccess};
`;

const StyledAlert = styled(Alert)`
  width: 90%;
  margin: 0 auto 1rem;
`;

const ContestBannerPrizepool = () => {
  const {
    activePickem,
    isActivePickemApproved,
    isActivePickemInPlay,
  } = useSelector((s) => s.toolbar);
  const { walletBalance, walletPromoBalance } = useSelector((s) => s.app);

  const customPrize =
    activePickem?.pickemTexts?.bannerBarPrizeText ||
    activePickem?.activePickem?.pickemTexts?.betBarPrizeText ||
    process.env.REACT_APP_TEXT_PRIZE ||
    activePickem?.prizeText;

  const prizePool = activePickem?.prizeFund || activePickem?.promoPrizeFund;

  const hasPromoPrize = activePickem?.promoPrizeFund > 0;

  const labelText =
    (activePickem?.pickemTexts?.bannerBarPrizeLabel ||
      activePickem?.pickemTexts?.betBarPrizeLabel || (hasPromoPrize ? "Total Promo Prize" : "Total Prize Pool"));

  return (
    <>
      <StyledPrizePoolLabel>
        {prizePool === undefined && (
          <Skeleton
            skeletonHeight="16px"
            skeletonWidth="60px"
            skeletonBorderRadius="0.25rem"
          />
        )}
        {prizePool !== undefined && labelText}
      </StyledPrizePoolLabel>

      {customPrize ? (
        <StyledPrizepool>{customPrize}</StyledPrizepool>
      ) : (
        <>
          <StyledPrizepool isMoney>
            {prizePool !== undefined && (
              <>
                <span style={{ fontSize: "50%", transform: "translateY(4px)" }}>
                  £
                </span>
                <PenceToPounds>{prizePool}</PenceToPounds>
              </>
            )}
            {prizePool === undefined && (
              <Skeleton skeletonHeight="40px" skeletonWidth="90px" />
            )}
          </StyledPrizepool>

          {isActivePickemInPlay && activePickem?.stake > 0 && (
            <StyledAlert isDanger margin="0 0 1rem">
              This contest had a £
              <PenceToPounds>{activePickem?.stake}</PenceToPounds> entry fee
            </StyledAlert>
          )}

          {isActivePickemApproved &&
            activePickem?.stake > 0 &&
            walletBalance + walletPromoBalance < activePickem?.stake && (
              <StyledAlert isDanger margin="0 0 1rem">
                Add funds to place £
                <PenceToPounds>{activePickem?.stake}</PenceToPounds> bet
              </StyledAlert>
            )}

          {isActivePickemApproved &&
            (!activePickem?.userBets?.length ||
              activePickem?.userBets?.length < activePickem?.maxBetsPerUser) &&
            activePickem?.stake > 0 &&
            walletBalance + walletPromoBalance >= activePickem?.stake && (
              <StyledAlert margin="0 0 1rem">
                You can place{" "}
                {activePickem?.maxBetsPerUser - activePickem?.usersBets?.length}
                x £<PenceToPounds>{activePickem?.stake}</PenceToPounds> bets
              </StyledAlert>
            )}
        </>
      )}
    </>
  );
};

export default ContestBannerPrizepool;
