import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const StyledSpinner = styled.span`
  display: inline-block;
  border-width: ${(props) => props.lineWidth}px;
  border-style: solid;

  border-color: ${(props) =>
    props.forDark
      ? props.theme.colorSpinnerBackgroundForDark
      : props.theme.colorSpinnerBackground};

  border-top-color: ${(props) =>
    props.primaryColor || props.isAccentBackground
      ? props.theme.colorAccentText
      : props.forDark
      ? props.theme.colorPrimaryText
      : props.theme.colorAccent};

  flex: 0 0 ${(props) => Math.floor(props.lineWidth * 7)}px;
  width: ${(props) => Math.floor(props.lineWidth * 7)}px;
  height: ${(props) => Math.floor(props.lineWidth * 7)}px;
  border-radius: 50%;
  animation: spin 1.5s infinite linear;

  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }
`;

const Spinner = ({
  className,
  lineWidth,
  forDark,
  primaryColor,
  isAccentBackground,
}) => (
  <StyledSpinner
    className={className}
    lineWidth={lineWidth}
    primaryColor={primaryColor}
    forDark={forDark}
    isAccentBackground={isAccentBackground}
  />
);

Spinner.defaultProps = {
  lineWidth: 7,
  forDark: false,
};

Spinner.propTypes = {
  lineWidth: PropTypes.number,
  forDark: PropTypes.bool,
  primaryColor: PropTypes.string,
};

export default Spinner;
