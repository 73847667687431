import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import LeaderboardRowHeader from "components/feature/leaderboard/shared/LeaderboardRowHeader";
import history from "router/history";
import {
  getLeaderboard,
  setLeaderboard,
} from "components/feature/leaderboard/actions";
import { getMyBet, setToolbar } from "./actions";

const StyledBottomRow = styled.div`
  display: flex;
  align-items: center;
  border-top: 2px solid #f4f4f4;
`;

const Wrapper = ({}) => {
  const dispatch = useDispatch();

  const {
    hadBet,
    activeBet,
    activePickem,
    isBetsOpen,
    isActivePickemInPlay,
    isActivePickemCompleted,
  } = useSelector((s) => s.toolbar);

  const { table, isShowingMyBet } = useSelector((s) => s.leaderboard);

  const { loggedInUserName, loggedInUserImage } = useSelector((s) => s.app);

  const isInPlayOrCompleted =
    (isActivePickemInPlay && (activePickem?.hasLiveScoring ?? true)) ||
    isActivePickemCompleted;

  let row = {
    hasWinTieBreaker: hadBet && activeBet?.hasWinTieBreaker,
    rank: (hadBet && activeBet?.rank) || "-",
    points: hadBet && activeBet?.points > -1 ? activeBet?.points : "",
    prize: (hadBet && activeBet?.prize) || "",
    user: {
      username: loggedInUserName,
      image: loggedInUserImage,
    },
  };

  const handleMyBetClicked = () => {
    if (!isInPlayOrCompleted) return;

    window.scrollTo(0, 0);
    dispatch(getMyBet(activeBet, !isShowingMyBet));
    history.push("/leaderboard");
  };

  return (
    <StyledBottomRow>
      <LeaderboardRowHeader
        showLabels={hadBet}
        isPageHeader
        isPreMatchLeaderboard={!isInPlayOrCompleted}
        hasPrimaryBackground={
          process.env.REACT_APP_BETBAR_HAS_PRIMARY_MY_BET === "true"
        }
        row={row}
        onRowClicked={handleMyBetClicked}
      />
    </StyledBottomRow>
  );
};

export default Wrapper;
