import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { MatIcon } from "../";

const StyledWrapper = styled.label`
  display: flex;
  align-items: ${(props) => props.alignItems || "flex-start"};
`;

const StyledInput = styled.input`
  position: absolute;
  left: -9999px;
`;

const StyledCheckbox = styled.span`
  flex: 0 0 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 24px;
  border-radius: 0.25rem;
  background: ${(props) =>
    props.isChecked && props.isDisabled
      ? props.theme.colorDisabledBackground
      : props.isChecked
      ? props.theme.colorAccent
      : props.theme.colorLightGrey};
`;

const StyledCheckboxCheck = styled(MatIcon)`
  font-size: 16px;
  color: ${(props) =>
    props.isDisabled
      ? props.theme.colorDisabledText
      : props.theme.colorAccentText};
  opacity: ${(props) => (props.isChecked ? 1 : 0)};
  transition: opacity 0.25s;
`;

const StyledText = styled.span`
  margin-left: 0.675rem;
  line-height: 1.25;
  font-weight: 400;
  font-size: 0.75rem;
  color: ${(props) =>
    props.isChecked ? props.theme.colorText : props.theme.colorLabel};
`;

const FormCheckbox = ({
  alignItems,
  checked,
  name,
  onChange,
  label,
  disabled,
  style,
}) => (
  <StyledWrapper alignItems={alignItems} style={style}>
    <StyledInput
      disabled={disabled}
      checked={checked}
      name={name}
      onChange={onChange}
      type="checkbox"
    />
    <StyledCheckbox isDisabled={disabled} isChecked={checked}>
      <StyledCheckboxCheck isDisabled={disabled} isChecked={checked}>
        check
      </StyledCheckboxCheck>
    </StyledCheckbox>
    <StyledText isChecked={checked} isDisabled={disabled}>
      {label}
    </StyledText>
  </StyledWrapper>
);

FormCheckbox.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
};

export default FormCheckbox;
