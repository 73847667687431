import React from "react";
import styled from "styled-components";

const StyledOverlay = styled.div`
  position: fixed;
  z-index: ${(props) => props.zIndex || 9};
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.45);
  opacity: ${(props) => (props.isVisible ? 1 : 0)};
  visibility: ${(props) => (props.isVisible ? "visible" : "hidden")};
  transition-property: opacity, visibility;
  transition-duration: 0.35s;
`;

const Overlay = ({ onClick, isVisible, zIndex }) => (
  <StyledOverlay onClick={onClick} zIndex={zIndex} isVisible={isVisible} />
);

export default Overlay;
