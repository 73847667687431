import React from "react";
import styled from "styled-components";

const StyledSvg = styled.svg`
  pointer-events: none;
`;

const StyledPath = styled.path`
  fill: ${(props) => props.theme.colorDark};
`;

const BottombarBg = ({}) => (
  <StyledSvg
    height="80"
    viewBox="0 0 376 90"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <StyledPath
      d="M0 0V90H376V0H260C243.984 0 231 12.9837 231 29V27.75V27H230.992C230.997 27.2494 231 27.4994 231 27.75C231 49.3965 211.972 66.9444 188.5 66.9444C165.028 66.9444 146 49.3965 146 27.75C146 27.4994 146.003 27.2494 146.008 27H146V27.75V29C146 12.9837 133.016 0 117 0H0Z"
      fill="#1B1B1B"
    />
  </StyledSvg>
);

export default BottombarBg;
