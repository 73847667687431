import React from "react";
import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import { Spinner, MatIcon } from "components/shared";

const sharedStyles = css`
  position: relative;
  width: 100%;
  line-height: 1;
  text-align: center;
  border: 0;
  border-radius: ${(p) => p.theme.borderRadius};
  white-space: nowrap;
  background: ${(props) => props.theme.colorPrimary};
  color: ${(props) => props.theme.colorPrimaryText};
  transition: background 0.25s;

  ${(p) =>
    (!p.default &&
      !p.disabled &&
      !p.success &&
      process.env.REACT_APP_COLOR_PRIMARY_IS_WHITE) ||
    (process.env.REACT_APP_COLOR_PRIMARY_IS_WHITE && p.isLoading)
      ? `border: 2px solid ${p.theme.colorAccent}; color: ${p.theme.colorAccent};`
      : ""};
`;

const extraSmallButton = css`
  height: 34px;
  padding: 0 0.75rem;
  font-size: 0.75rem;
`;

const smallButton = css`
  height: 50px;
  padding: 0 1rem;
  font-size: 0.875rem;
`;

const mediumButton = css`
  height: 60px;
  padding: 0 1.5rem;
  font-size: 1rem;
`;

const defaultStyles = css`
  background: ${(props) => props.theme.colorLightGrey};
  color: ${(props) => props.theme.colorText};
`;

const accentStyles = css`
  background: ${(props) => props.theme.colorAccent};
  color: ${(p) => p.theme.colorAccentText};
`;

const successStyles = css`
  background: ${(props) => props.theme.colorSuccess};
  color: ${(p) => p.theme.colorSuccessText};
`;

const dangerStyles = css`
  background: ${(props) => props.theme.colorDanger};
  color: white;
`;

const disabledStyles = css`
  background: ${(props) => props.theme.colorDisabledBackground};
  color: ${(props) => props.theme.colorDisabledText};
`;

const disabledSuccessStyles = css`
  background: ${(p) => p.theme.colorAlertSuccessBackground};
  color: ${(p) => p.theme.colorAlertSuccessText};
  opacity: 0.65;
`;

const StyledButton = styled.button`
  ${sharedStyles};

  ${mediumButton}
  ${(props) => props.size === "sm" && smallButton};
  ${(props) => props.size === "xs" && extraSmallButton};

  ${(props) => props.success && successStyles};
  ${(props) => props.accent && accentStyles};
  ${(props) => (props.danger || props.hasError) && dangerStyles};
  ${(props) => props.default && defaultStyles};

  &:disabled {
    ${(props) =>
      !props.isLoading && !props.noDisabledStyles && props.disabledSuccess
        ? disabledSuccessStyles
        : !props.isLoading
        ? disabledStyles
        : null};
  }

  & > .icon {
    position: absolute;
    top: 50%;
    left: 1rem;
    transform: translateY(-50%);
  }
`;

const StyledLink = styled(Link)`
  ${sharedStyles};

  ${mediumButton}
  ${(props) => props.size === "sm" && smallButton};
  ${(props) => props.size === "xs" && extraSmallButton};

  display: flex;
  align-items: center;
  justify-content: center;

  ${(props) => props.success && successStyles};
  ${(props) => (props.danger || props.hasError) && dangerStyles};
  ${(props) => props.default && defaultStyles};
`;

const BlockButton = ({
  children,
  to,
  onClick,
  size,
  isLoading,
  isAccentBackground,
  disabledSuccess,
  disabledSuccessIcon,
  ...rest
}) => {
  return to ? (
    <StyledLink to={to} size={size} {...rest}>
      {children}
    </StyledLink>
  ) : (
    <StyledButton
      onClick={onClick}
      size={size}
      disabledSuccess={disabledSuccess}
      isLoading={isLoading}
      {...rest}
    >
      {!isLoading && rest.disabled && disabledSuccess && (
        <MatIcon className="icon">
          {disabledSuccessIcon || "verified_user"}
        </MatIcon>
      )}

      {!isLoading ? (
        children
      ) : (
        <Spinner
          isAccentBackground={isAccentBackground}
          forDark={!process.env.REACT_APP_COLOR_PRIMARY_IS_LIGHT}
          lineWidth={size === "sm" ? 4 : 5}
        />
      )}
    </StyledButton>
  );
};

export default BlockButton;
