import { useSelector } from "react-redux";
import styled from "styled-components";
import { Skeleton } from "fiver-react-ui-components";

import { MoneyText } from "components/shared";

import { IconBlock } from "./middle/shared";

const StyledWrapper = styled.div``;

const Wrapper = () => {
  const { activePickem, activePickemList } = useSelector((s) => s.toolbar);

  const isPromoPrize = activePickem?.promoPrizeFund > 0;

  const prizeLabel =
    activePickem?.pickemTexts?.betBarPrizeLabel ||
    process.env.REACT_APP_TEXT_PRIZEPOOL ||
    (isPromoPrize ? "Promo Prize" : "Prize Pool");

  const customPrize =
    activePickem?.pickemTexts?.betBarPrizeText ||
    process.env.REACT_APP_TEXT_PRIZE;

  const prizeValue = activePickem?.prizeFund || activePickem?.promoPrizeFund;

  return !activePickemList?.length ? (
    <Skeleton skeletonWidth="100px" skeletonHeight="32px" />
  ) : process.env.REACT_APP_HIDE_MONEY_REFERENCES !== "true" && !customPrize ? (
    <IconBlock
      isSuccess
      label={prizeLabel}
      icon={process.env.REACT_APP_ICON_PRIZE || "emoji_events"}
      value={<MoneyText showPoundSign>{prizeValue}</MoneyText>}
    />
  ) : (
    <IconBlock
      isSuccess
      label={prizeLabel}
      icon={process.env.REACT_APP_ICON_PRIZE || "emoji_events"}
      value={customPrize}
      hideIcon={customPrize?.length > 7}
    />
  );
};

export default Wrapper;
