import React from "react";
import { FormRow, FormCheckbox, FormRowText } from "components/shared";

const GdprCheckboxes = ({
  selectAllMessage,
  inAppNotificationsAccepted,
  smsNotificationsAccepted,
  emailNotificationsAccepted,
  handleCheckboxChanged,
  agreedPrivacy,
  agreedTerms,
  handleCheckAllChanged,
  allChecked,
}) => {
  return (
    <>
      <FormRowText isNormal>
        Opting in to marketing will allow us to notify you of exclusive offers,
        promotions and bonuses.
      </FormRowText>
      <FormRow>
        <FormCheckbox
          alignItems="center"
          checked={inAppNotificationsAccepted}
          label="Push Notification"
          onChange={handleCheckboxChanged}
          name="inAppNotificationsAccepted"
        />
      </FormRow>
      <FormRow>
        <FormCheckbox
          alignItems="center"
          checked={smsNotificationsAccepted}
          label="SMS"
          onChange={handleCheckboxChanged}
          name="smsNotificationsAccepted"
        />
      </FormRow>
      <FormRow>
        <FormCheckbox
          alignItems="center"
          checked={emailNotificationsAccepted}
          label="Email"
          onChange={handleCheckboxChanged}
          name="emailNotificationsAccepted"
        />
      </FormRow>
      <FormRowText isNormal>
        Where you have given consent to receive marketing from Low6 or our
        associates, we may use your personal details to identify products and
        services which may be of interest to you. You can update your
        preferences at any time from your account settings.
      </FormRowText>
      <FormRow>
        <FormCheckbox
          disabled={
            inAppNotificationsAccepted &&
            smsNotificationsAccepted &&
            agreedPrivacy &&
            agreedTerms &&
            emailNotificationsAccepted
          }
          alignItems="center"
          checked={
            allChecked ||
            (inAppNotificationsAccepted &&
              smsNotificationsAccepted &&
              agreedPrivacy &&
              agreedTerms &&
              emailNotificationsAccepted)
          }
          label={
            selectAllMessage ||
            `Confirm consent for the above marketing mediums`
          }
          onChange={handleCheckAllChanged}
        />
      </FormRow>
    </>
  );
};

export default GdprCheckboxes;
