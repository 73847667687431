import React, { useState } from "react";
import styled from "styled-components";
import {
  Overlay,
  FormRow,
  BlockButton,
  GdprCheckboxes,
} from "components/shared";

const StyledDialog = styled.div`
  overflow: auto;
  position: fixed;
  z-index: 10;
  left: 0;
  top: 56px;
  width: 100%;
  height: calc(100% - 56px);
  background: white;
  padding: 0 2rem 2rem;
  transform: translateY(${(props) => (props.isVisible ? "0" : "100%")});
  transition: transform 0.35s;

  @media (min-width: ${(props) => props.theme.screenWidthMd}) {
    left: 50%;
    width: ${(props) => props.theme.railWidth};
    margin: 0 auto;
    transform: translateX(-50%)
      translateY(${(props) => (props.isVisible ? "0" : "100%")});
  }
`;

const StyledTitle = styled.div`
  text-align: center;
  font-weight: 700;
  padding: 2rem 0;
`;

const StyledText = styled.div`
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.375;
  color: ${(props) => props.theme.colorLabel};
  margin-bottom: 1rem;

  & > a {
    font-size: inherit;
    color: ${(props) => props.theme.colorAccent};
  }
`;

const LoginGdpr = ({ isVisible, updateGdpr }) => {
  const [state, setState] = useState({
    emailNotificationsAccepted: false,
    inAppNotificationsAccepted: false,
    smsNotificationsAccepted: false,
    allChecked: false,
  });

  const {
    emailNotificationsAccepted,
    inAppNotificationsAccepted,
    smsNotificationsAccepted,
    allChecked,
  } = state;

  const handleCheckboxChanged = (e) => {
    const { target } = e;
    setState({
      ...state,
      allChecked: false,
      [target.name]: target.checked,
    });
  };

  const handleCheckAllChanged = (e) => {
    setState({
      ...state,
      allChecked: true,
      inAppNotificationsAccepted: true,
      emailNotificationsAccepted: true,
      smsNotificationsAccepted: true,
    });
  };

  return (
    <>
      <Overlay zIndex={9} isVisible={isVisible} />
      <StyledDialog isVisible={isVisible}>
        <StyledTitle>Contact Permissions</StyledTitle>
        <StyledText>
          We've made some changes to how we communicate with our players.
        </StyledText>

        <GdprCheckboxes
          inAppNotificationsAccepted={inAppNotificationsAccepted}
          smsNotificationsAccepted={smsNotificationsAccepted}
          emailNotificationsAccepted={emailNotificationsAccepted}
          handleCheckAllChanged={handleCheckAllChanged}
          handleCheckboxChanged={handleCheckboxChanged}
          allChecked={allChecked}
        />

        <StyledText>
          Where you have given your consent to receive marketing from Low6 or
          our associates, we may use your personal details to identify products
          and services which may be of interest to you.
        </StyledText>
        <StyledText>
          Depending on your chosen contact methods, we may contact you by
          letter, telephone, text or email. This includes informing you of
          products and services of our commercial partners. You can update your
          contact preferences at any time by contacting us at{" "}
          <a href="mailto:privacy@low6.co.uk">privacy@low6.co.uk</a>
        </StyledText>
        <BlockButton onClick={() => updateGdpr(state)}>
          Save & Continue
        </BlockButton>
      </StyledDialog>
    </>
  );
};

export default LoginGdpr;
