import React from "react";
import styled from "styled-components";
import { Card, AppIcon, Low6Button } from "../";

const StyledWrapper = styled.div`
  padding: 2rem 0 0;
`;

const StyledHeaderRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0 1rem;
  margin-bottom: 1rem;
  border-bottom: 2px solid ${(props) => props.theme.colorBorder};
`;

const StyledLogoWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StyledVersion = styled.div`
  padding: 0.5rem 0;
  margin-left: 1rem;
  font-size: 0.875rem;
  color: ${(props) => props.theme.colorLabel};
`;

const StyledRulesButton = styled.button`
  font-size: 0.75rem;
  border-radius: 0.5rem;
  padding: 0.5rem 1rem;
  background: ${(props) => props.theme.colorLightGrey};
`;

const StyledInfoText = styled.div`
  margin-bottom: 1rem;
  font-size: 0.625rem;
  font-weight: 400;
  line-height: 1.35;
  text-align: center;
  color: ${(props) => props.theme.colorLabel};
`;

const Low6Footer = ({ getPage }) => (
  <StyledWrapper>
    <Card>
      <StyledHeaderRow>
        <StyledLogoWrapper>
          <AppIcon width="60px" />
          <StyledVersion>v 1.0.0</StyledVersion>
        </StyledLogoWrapper>
        <StyledRulesButton
          onClick={() =>
            getPage(process.env.REACT_APP_RULES_SLUG, "Game Rules")
          }
        >
          Rules
        </StyledRulesButton>
      </StyledHeaderRow>
      <StyledInfoText>
        Did you know you can bet on Premier League Football, Darts, Horse Racing
        and other sports on the Low6 Sports Pool Betting App?
      </StyledInfoText>
      <Low6Button />
    </Card>
  </StyledWrapper>
);

export default Low6Footer;
