import React, { useState } from "react";
import styled, { css } from "styled-components";
import {
  isSafari,
  isMobileSafari,
  isIE,
  isIOS,
  isChrome,
  isAndroid,
} from "react-device-detect";
import moment from "moment";
import { FormTextbox, FormRow, FormRowMessage } from "components/shared";

const StyledWrapper = styled.label`
  position: relative;
`;

const StyledLabel = styled.div`
  height: 40px;
  display: flex;
  align-items: flex-end;
  color: ${(p) => p.theme.colorLabel};
`;

const sharedStyles = css`
  width: 100%;

  font-size: 1rem;
  border: 0;
  border-bottom: 2px solid ${(props) => props.theme.colorBorder};
  background: transparent;
`;

const StyledInput = styled.input`
  ${sharedStyles};

  line-height: 82px;
  height: 82px;

  &:focus {
    border-color: ${(props) => props.theme.colorAccent};
  }

  &:focus + span {
    display: none;
  }

  ${(props) =>
    props.isError ||
    (props.isRequired && props.value && props.value.length > 10)
      ? `border-color: ${props.theme.colorDanger} !important;`
      : ""};
`;

const StyledPlaceholder = styled.span`
  position: absolute;
  top: 50%;
  left: 0;
  font-size: 1rem;
  transform: translateY(-50%);
  color: ${(props) => props.theme.colorLabel};
`;

const FormDatePicker = ({
  name,
  value,
  placeholder,
  handleDateChanged,
  onChange,
  onFocus,
  onBlur,
  isError,
  isRequired,
}) => {
  const [focused, setFocused] = useState(false);

  const showPlaceholder = isMobileSafari || isIOS || (isIOS && isChrome);

  const showFallbackDatePicker =
    (isSafari && !isMobileSafari) || isIE || isAndroid;

  const momentDate = moment(value);

  const dateValid = () => true;

  const handleFallbackOnChange = (e) => {
    const { target } = e;
    const { value } = target;

    if (value.length === 10) {
      const date = moment(value, "DD/MM/YYYY", true);

      if (date.toString() != "Invalid date") {
        e.target.value = date.format("DD/MM/YYYY");
      }
    }

    onChange(e);
  };

  return (
    <StyledWrapper>
      {showFallbackDatePicker ? (
        <>
          <StyledLabel>Date Of Birth</StyledLabel>
          <FormRow>
            <FormTextbox
              isRequired
              isError={isError}
              onChange={handleFallbackOnChange}
              name={name}
              value={value}
              placeholder="dd/mm/yyyy"
            />
          </FormRow>
        </>
      ) : (
        <>
          {!showPlaceholder && <StyledLabel>Date Of Birth</StyledLabel>}
          <StyledInput
            type="date"
            value={value}
            onChange={onChange}
            onFocus={onFocus}
            onBlur={onBlur}
            name={name}
            isError={isError}
            isRequired={isRequired}
          />
        </>
      )}
      {!value && showPlaceholder && (
        <StyledPlaceholder>{placeholder}</StyledPlaceholder>
      )}
    </StyledWrapper>
  );
};

export default FormDatePicker;
