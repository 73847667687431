import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { darken } from "polished";
import { MatIcon, Spinner } from "../";
import { PrizeDepositFormSchema } from "../../feature/prizedraw/PrizeDepositFormSchema";
import FormikEffect from "../../feature/prizedraw/FormikEffect";
import { Formik, Form, Field, useFormikContext } from "formik";

const StyledButton = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  padding: ${(props) =>
    props.isSmall ? "0.25rem" : "0.5rem 1rem 0.5rem 0.5rem"};
  margin: 0 auto;
  border-radius: 0.5rem;
  background: ${(props) =>
    props.isError
      ? props.theme.colorDanger
      : props.step === 2
      ? darken(0.15, props.theme.colorSuccess)
      : props.theme.colorSuccess};
  color: ${(p) => p.theme.colorSuccessText};
  cursor: pointer;
  transition: background 0.25s;
`;
/* const fastDeposit = async () => {

  setIsLoading(true)

  await axios.post(`/wallet/deposit`, {
    amount: depositParams.amount * 100,
    password: depositParams.password,
  })
  .then(async res => {
    toast.success(`You successfully deposited ${formatter.format(depositParams.amount)}.`)
    await axios.post(
        `https://api.low6dev.co.uk/prize_draw/game/ticket/buy?answer_array[${contest.game_id}]=${selected}&qty=${amount}`
    ).then(
        res => {
          setAmount(1)
          toggleDrawer(false)
          setActiveStep(1)
          toast.success('You successfully bought tickets.')
        }).catch(err => {
      if(err?.response?.data?.code === 'insufficientFunds') {
        setActiveStep(2)
      } else if (err?.response?.data?.code === 'max_user_tickets') {
        toast.error(err?.response?.data?.message)
        setAmount(1)
        toggleDrawer(false)
      } else if (err?.response?.data?.message){
        toast.error(err?.response?.data?.message)
      } else {
        toast.error(err?.message)
      }
    })
  }).catch(err => {
    if (err?.response?.data?.message) {
      toast.error(err?.response?.data?.message)
    } else {
      toast.error(err?.message)
    }
  })
  setIsLoading(false)
  setConfirmDeposit(false)
}; */
const StyledStep1 = styled.span`
  display: flex;
  align-items: center;
  justify-content: space-between;
  transform: translateY(${(props) => (props.step === 1 ? "0" : "66px")});
  transition: transform 0.25s;
`;

const StyledStep2 = styled.div`
  position: absolute;
  z-index: 3;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding-left: ${(props) => (props.isSmall ? "0.5rem" : "1.5rem")};
  display: flex;
  align-items: center;
  justify-content: space-between;
  transform: translateY(${(props) => (props.step === 1 ? "-66px" : "0")});
  transition: transform 0.25s;
`;

const StyledStep3 = styled.div`
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${(props) => (props.isSmall ? "0.75rem" : "1rem")};
  opacity: ${(props) => (props.step === 3 ? 1 : 0)};
  transition: opacity 0.25s;
`;

const StyledPassword = styled.input`
  flex: 1 1 auto;
  color: white;
  background: transparent;
  border: 0;
  min-width: 1px;
  height: 100%;
  line-height: 100%;
  font-size: ${(props) => (props.isSmall ? "0.75rem" : "1rem")};
  transform: translateX(${(props) => (props.step === 3 ? "-100%" : "0")});
  transition: transform 0.25s;

  &::placeholder {
    color: white;
  }
`;

const StyledCancelButton = styled.button`
  position: absolute;
  top: 50%;
  right: ${(props) => (props.isSmall ? "0" : "1rem")};
  color: white;
  transform: translateY(-50%);
`;

const StyledSubmitButton = styled.button`
  width: 108px;
  height: 100%;
  font-size: ${(props) => (props.isSmall ? "0.625rem" : "0.875rem")};
  color: ${(p) => p.theme.colorSuccessText};
  background: ${(props) => props.theme.colorSuccess};
  transform: translateX(
    ${(props) => (props.isValid && props.step === 2 ? "0" : "108px")}
  );
  transition: transform 0.25s;
`;

const StyledAddIcon = styled(MatIcon)`
  font-size: ${(props) => (props.isSmall ? "20px" : "30px")};
  padding: ${(props) => (props.isSmall ? "5px" : "10px")};
  margin: 0;
  border-radius: 0.5rem;
  background: rgba(0, 0, 0, 0.3);
`;

const StyledFastDeposit = styled.div`
  font-weight: 700;
  font-size: 1rem;
  text-align: left;
  margin: 0 1rem;
`;

const StyledDepositValue = styled.div`
  display: flex;
  align-items: flex-start;
  margin: ${(props) => (props.isSmall ? "0 0.75rem" : "0")};
  font-size: ${(props) => (props.isSmall ? "20px" : "30px")};
`;

/* const FastDepositButton = ({ onConfirmClick, setDepositParams,
  setDepositErrors, isSmall ,}) => {
  const [initialValues, setInitialValues] = useState({
    isLoading: false,
    isError: false,
    amount: "",
    password: "",
  });
/*   useEffect(() => {
    if (step < 2) {
      setStep( step + 1);
    }

  }, [initialValues]);
const {isError, isLoading, password} = initialValues;

const [step,setStep] = useState(1);

  const nextStep = () => {

    if (step < 2) {
      setStep( step + 1);
    }
  };



  const handleDepositClick = (e) => {
    e.stopPropagation();
    setStep( 3);


    setInitialValues({ ...initialValues,isError: false, isLoading: true });
      onConfirmClick(10, password, true);

      setTimeout(() => {
        setInitialValues({ ...initialValues, isLoading: false});
        setStep( 3);

      }, 1500);


    setTimeout(() => {
      setInitialValues({
        ...initialValues,
        password: "",
        isError: false,
      });
      setStep( 1);

    }, 3500);
  };


  const { step, password, isError, isLoading } = state;

  const nextStep = (e) => {
    e.stopPropagation();
    if (step < 2) {
      const nextStep = step + 1;
      setState({ ...state, step: nextStep });
    }
  };

  const handlePasswordChange = (e) => {
    const { value } = e.target;
    setState({ ...state, password: value });
  };

  const handleDepositClick = (e) => {
    e.stopPropagation();
    setState({ ...state, step: 3 });

      setState({ ...state, step: 3, isError: false, isLoading: true });
      onConfirmClick(10, password);

      setTimeout(() => {
        setState({ ...state, isLoading: false, step: 3 });
      }, 1500);


    setTimeout(() => {
      setState({
        ...state,
        step: 1,
        password: "",
        isError: false,
      });
    }, 3500);
  };

  const isValid = password.length > 3;
  const isValid = password.length > 3;

  return (
    <Formik   initialValues={initialValues}
    validationSchema={PrizeDepositFormSchema()}
    enableReinitialize={true}>
        {({
            errors,
            values,
            touched,
            handleChange,
            handleBlur,
            setFieldValue,
          }) => {
          return (
    <StyledButton
      step={step}
      onClick={ () => setFieldValue('amount', 10), nextStep}
      isSmall={isSmall}
    >
                    <Form >


      <StyledStep1 step={step}>
        <StyledAddIcon isSmall={isSmall}>add</StyledAddIcon>
        {!isSmall && (
          <StyledFastDeposit>
            Fast <br />
            Deposit
          </StyledFastDeposit>
        )}
        <StyledDepositValue isSmall={isSmall}>
          <span style={{ fontSize: "60%", marginRight: "2px" }}>£</span>
          10.00
        </StyledDepositValue>
        <MatIcon size={isSmall ? "18px" : "24px"}>arrow_forward_ios</MatIcon>
      </StyledStep1>
      <StyledStep2 isSmall={isSmall} step={step}>
        {(step === 2 || step === 3) && (
          <>
            <StyledPassword
              step={step}
              onChange={handleChange}
              autoFocus
              isSmall={isSmall}
              placeholder={isSmall ? "Password" : "Enter Your Password"}
              type="password"
              name="password"
            />
           {!isError && !isValid && (
              <StyledCancelButton
                isSmall={isSmall}
                onClick={() =>
                  setInitialValues({
                    ...initialValues,
                    password: "",
                    isValid: false,
                    isError: false,
                  })
                }
              >
                <MatIcon>close</MatIcon>
              </StyledCancelButton>
            )}

            <StyledSubmitButton
              step={step}
              onClick={handleDepositClick}
              isValid={isValid}
              isSmall={isSmall}
            >
              Deposit
            </StyledSubmitButton>

          </>
        )}
      </StyledStep2>
      {step !== 1 && (
        <StyledStep3 isSmall={isSmall} step={step}>
          {isLoading && <Spinner forDark lineWidth={isSmall ? 4 : 6} />}
          {!isLoading && !isError && "Deposit Success"}
          {!isLoading && isError && "Password Invalid"}
        </StyledStep3>
      )}
                  </Form>

    </StyledButton>)}
  }</Formik>
  );
};

FastDepositButton.defaultProps = {
  isSmall: false,
};

FastDepositButton.propTypes = {
  isSmall: PropTypes.bool,
};

export default FastDepositButton; */

const FastDepositButton = ({ isSmall, onConfirmClick }) => {
  const [state, setState] = useState({
    step: 1,
    password: "",
    isError: false,
    isLoading: false,
  });

  const { step, password, isError, isLoading } = state;

  const nextStep = (e) => {
    e.stopPropagation();
    if (step < 2) {
      const nextStep = step + 1;
      setState({ ...state, step: nextStep });
    }
  };

  const handlePasswordChange = (e) => {
    const { value } = e.target;
    setState({ ...state, password: value });
  };

  const handleDepositClick = (e) => {
    e.stopPropagation();
    onConfirmClick(25, state.password);
    // setState({ ...state, step: 3 });
    //
    // if (password !== "1485") {
    //   setState({ ...state, step: 3, isError: true });
    // } else {
    //   setState({ ...state, step: 3, isError: false, isLoading: true });
    //   onConfirmClick(10, password, true);
    //
    //   setTimeout(() => {
    //     setState({ ...state, isLoading: false, step: 3 });
    //   }, 1500);
    // }
    //
    // setTimeout(() => {
    //   setState({
    //     ...state,
    //     step: 1,
    //     password: "",
    //     isError: false,
    //   });
    // }, 3500);
  };

  const isValid = password.length > 3;

  return (
    <StyledButton
      step={step}
      onClick={nextStep}
      isError={isError}
      isSmall={isSmall}
    >
      <StyledStep1 step={step}>
        <StyledAddIcon isSmall={isSmall}>add</StyledAddIcon>
        {!isSmall && (
          <StyledFastDeposit>
            Fast <br />
            Deposit
          </StyledFastDeposit>
        )}
        <StyledDepositValue isSmall={isSmall}>
          <span style={{ fontSize: "60%", marginRight: "2px" }}>£</span>
          25.00
        </StyledDepositValue>
        <MatIcon size={isSmall ? "18px" : "24px"}>arrow_forward_ios</MatIcon>
      </StyledStep1>
      <StyledStep2 isSmall={isSmall} step={step}>
        {(step === 2 || step === 3) && (
          <>
            <StyledPassword
              step={step}
              onChange={handlePasswordChange}
              autoFocus
              isSmall={isSmall}
              placeholder={isSmall ? "Password" : "Enter Your Password"}
              type="password"
            />
            {!isError && !isValid && (
              <StyledCancelButton
                isSmall={isSmall}
                onClick={() =>
                  setState({
                    ...state,
                    password: "",
                    step: 1,
                    isValid: false,
                    isError: false,
                  })
                }
              >
                <MatIcon>close</MatIcon>
              </StyledCancelButton>
            )}

            <StyledSubmitButton
              step={step}
              onClick={handleDepositClick}
              isValid={isValid}
              isSmall={isSmall}
            >
              Deposit
            </StyledSubmitButton>
          </>
        )}
      </StyledStep2>
      {step !== 1 && (
        <StyledStep3 isSmall={isSmall} step={step}>
          {isLoading && <Spinner forDark lineWidth={isSmall ? 4 : 6} />}
          {!isLoading && !isError && "Deposit Success"}
          {!isLoading && isError && "Password Invalid"}
        </StyledStep3>
      )}
    </StyledButton>
  );
};

FastDepositButton.defaultProps = {
  isSmall: false,
};

FastDepositButton.propTypes = {
  isSmall: PropTypes.bool,
};

export default FastDepositButton;
