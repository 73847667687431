import React, { useState } from "react";
import { connect } from "react-redux";
import {
  Form,
  FormRow,
  FormRowMessage,
  FormTextbox,
  BlockButton,
  Card, Alert,
} from "components/shared";
import { changePassword } from "./actions";
import { validatePassword } from "utils";

const ForgotPassword = ({ changePassword, error }) => {
  const [state, setState] = useState({
    oldPassword: undefined,
    newPassword: undefined,
    newPasswordConfirm: undefined,
  });

  const { oldPassword, newPassword, newPasswordConfirm } = state;

  const oldPasswordValid = oldPassword !== "";

  const newPasswordValid =
    (newPassword === undefined || validatePassword(newPassword));

  const passwordsMatch = newPasswordConfirm === newPassword;

  const passwordsSimilar = oldPassword === newPassword;

  const formIsValid =
    oldPassword &&
    newPassword &&
    validatePassword(newPassword) &&
    newPasswordConfirm &&
    validatePassword(newPasswordConfirm) &&
    passwordsMatch &&
    !passwordsSimilar;

  const handleTextboxChanged = (e) => {
    const target = e.target;
    setState({
      ...state,
      [target.name]: target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    changePassword({ old: state.oldPassword, new: state.newPassword });
  };

  return (
    <Card>
      <Alert margin="0 0 1rem">
        Please note that you can't use old password value for new password field.
      </Alert>
      <Form>
        <FormRow isSmall>
          <FormTextbox
            isSmall
            isError={!oldPasswordValid}
            onChange={handleTextboxChanged}
            name="oldPassword"
            type="password"
            placeholder="Enter Old Password"
          />
        </FormRow>
        <FormRowMessage isVisible={!oldPasswordValid}>
          Password must be at least 8 characters long and contain 1 uppercase
          character and a number
        </FormRowMessage>
        <FormRow isSmall>
          <FormTextbox
            isSmall
            onChange={handleTextboxChanged}
            name="newPassword"
            type="password"
            placeholder="Enter New Password"
          />
        </FormRow>
        <FormRowMessage isVisible={!newPasswordValid}>
          Password must be at least 8 characters long and contain 1 uppercase
          character and a number
        </FormRowMessage>
        <FormRow isSmall>
          <FormTextbox
            isSmall
            onChange={handleTextboxChanged}
            name="newPasswordConfirm"
            type="password"
            placeholder="Confirm New Password"
          />
        </FormRow>
        <FormRowMessage
          margin="0 0 1rem"
          isVisible={
            oldPasswordValid &&
            newPassword &&
            newPasswordConfirm &&
            !passwordsMatch
          }
        >
          Passwords must match
        </FormRowMessage>
        <FormRowMessage margin="0 0 1rem" isVisible={error}>
          Old password could be incorrect
        </FormRowMessage>
        <FormRow>
          <BlockButton disabled={!formIsValid} onClick={handleSubmit}>
            Change Password
          </BlockButton>
        </FormRow>
      </Form>
    </Card>
  );
};

const mapStateToProps = (state) => ({
  error: state.auth.changePasswordError,
});

const mapActionsToProps = {
  changePassword,
};

export default connect(mapStateToProps, mapActionsToProps)(ForgotPassword);
