import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { rgba } from "polished";

const StyledWrapper = styled.div`
  position: fixed;
  z-index: 7;
  top: 0;
  left: 0;
  display: flex;
  align-items: ${(p) => p.alignItems || "center"};
  justify-content: ${(props) => props.justifyContent};
  width: 100%;
  height: ${(p) => p.theme.floatingBarHeight};
  padding: 0 1rem;
  box-shadow: 0px 0px 10px ${rgba("black", 0.1)};
  background: white;
  transform: translateY(
    ${(props) => (props.isScrolledDown ? props.theme.topbarHeight : "0")}
  );
  transition: transform 0.325s;

  @media (min-width: ${(props) => props.theme.screenWidthMd}) {
    left: 50%;
    width: ${(props) => props.theme.railWidth};
    transform: translateY(
        ${(props) => (props.isScrolledDown ? props.theme.topbarHeight : "0")}
      )
      translateX(-50%);
  }
`;

const FloatingHeader = ({
  children,
  justifyContent,
  alignItems,
  isScrolledDown,
}) => {
  return (
    <StyledWrapper
      alignItems={alignItems}
      justifyContent={justifyContent}
      isScrolledDown={isScrolledDown}
    >
      {children}
    </StyledWrapper>
  );
};

FloatingHeader.defaultProps = {
  heightToAppear: 1,
  justifyContent: "center",
};

FloatingHeader.propTypes = {
  heightToAppear: PropTypes.number,
  justifyContent: PropTypes.string,
};

export default FloatingHeader;
