import axios from "axios";
import { handleError, createLegalPage } from "utils";

export const TOGGLE_DRAWER = "drawer:TOGGLE_DRAWER";
export const GET_PAGE = "drawer:GET_PAGE";
export const CLEAR_PAGE = "drawer:CLEAR_PAGE";

export const toggleDrawer = (isOpen, title) => (dispatch) => {
  dispatch({
    type: TOGGLE_DRAWER,
    payload: {
      isOpen,
      title,
    },
  });
};

export function clearPage() {
  return {
    type: CLEAR_PAGE,
    payload: undefined,
  };
}

const instance = axios.create();

export const getPage = (page, pageTitle, isLegalPage) => (dispatch) => {
  dispatch(clearPage());
  dispatch(toggleDrawer(true));

  if (isLegalPage) {
    instance
      .get(createLegalPage(page))
      .then(({ data }) => {
        dispatch({
          type: GET_PAGE,
          payload: {
            content: data,
            title: pageTitle,
            isLegalPage: true,
          },
        });
      })
      .catch(({ response }) => {
        handleError(response);
      });
  } else {
    axios
      .get(`/pages/${page}`)
      .then(({ data }) => {
        dispatch({
          type: GET_PAGE,
          payload: {
            content: data.data.content,
            title: pageTitle || data.data.title,
          },
        });
      })
      .catch(({ response }) => {
        handleError(response);
      });
  }
};
