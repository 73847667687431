import styled from "styled-components";
import { createImageCdnLink } from "@low6dev/react-utils/dist";

const StyledImage = styled.img`
  display: block;
  width: 200px;
  margin: 0 auto;
`;

const Wrapper = () => {
  return (
    <StyledImage
      src={createImageCdnLink(process.env.REACT_APP_AFFILIATE_LOGO)}
    />
  );
};

export default Wrapper;
