import styled from "styled-components";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Card, FormRow, FormTextbox, Alert } from "fiver-react-ui-components";
import { setContest } from "components/feature/contest/actions";

const StyledWrapper = styled.div`
  padding: 1rem 0;
`;

const StyledCard = styled(Card)`
  padding: 1.5rem 1rem;
  margin: 0 1rem;
`;

const StyledTitle = styled.div`
  font-size: 1.25rem;
  margin-bottom: 1rem;
  text-transform: capitalize;
`;

const StyledQuestion = styled.div`
  margin-bottom: 1rem;
  font-size: 1rem;
  line-height: 1.35;
  color: ${(p) => p.theme.colorLabel};
`;

const Wrapper = () => {
  const dispatch = useDispatch();

  const {
    activePickem,
    activeBet,
    isActivePickemCompleted,
    isActivePickemInPlay,
  } = useSelector((s) => s.toolbar);
  const { tiebreaker } = useSelector((s) => s.contest);

  useEffect(() => {
    dispatch(setContest({ tiebreaker: activeBet?.tieBreakerAnswer }));
  }, [activeBet?.id]);

  const handleTextboxChanged = (e) => {
    dispatch(setContest({ tiebreaker: e.target.value }));
  };

  return (
    <StyledWrapper>
      <StyledCard>
        <StyledTitle>
          {process.env.REACT_APP_TIEBREAKER_LABEL || "Tiebreaker"}
        </StyledTitle>
        <StyledQuestion>{activePickem?.tieBreakerQuestion}</StyledQuestion>
        <FormRow>
          <FormTextbox
            disabled={
              activeBet?.tieBreakerAnswer ||
              isActivePickemCompleted ||
              isActivePickemInPlay
            }
            type="tel"
            onChange={handleTextboxChanged}
            pattern="[0-9]*"
            inputMode="numeric"
            placeholder="Enter your answer here"
            isError={!tiebreaker}
            value={tiebreaker === undefined ? "" : tiebreaker}
          />
        </FormRow>
        <Alert textAlign="left">
          In the event of a tie, the player with the closest or correct answer
          will win!
        </Alert>
      </StyledCard>
    </StyledWrapper>
  );
};

export default Wrapper;
