import { PenceToPounds } from "fiver-react-ui-components";

const Wrapper = ({ children, hideDecimals }) => {
  return (
    <>
      £<PenceToPounds hideDecimals={hideDecimals}>{children}</PenceToPounds>
    </>
  );
};

export default Wrapper;
