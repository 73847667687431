import { useSelector } from "react-redux";
import styled from "styled-components";
import { createImageCdnLink } from "utils";

const StyledLink = styled.a`
  display: flex;
  cursor: pointer;
`;
const StyledImage = styled.img`
  width: 35%;
  margin-bottom: ${(p) => p.hasBottomMargin && "4rem"};
  margin: 0 auto;
`;
const StyledImageWrapper = styled.div`
  width: 80%;
  margin: 0 auto;
  padding: 0 0 0.5rem;
  font-size: 1.5rem;
  text-align: center;
  color: ${(p) => p.theme.colorLabel};
  background: transparent;
`;
const StyledWrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 0 0 0.5rem;
  font-size: 1rem;
  text-align: center;
  color: ${(p) => p.theme.colorLabel};
  background: transparent;
`;
const openLink = (url) => {
  window.xtremepush("tag", `PresentedByBanner.Click.Top`,
    JSON.stringify({
      AppName: process.env.REACT_APP_TITLE
    }));

  window.open(url, '_blank').focus()
}

const Wrapper = () => { 
  return (
    <>
    <StyledWrapper>PRESENTED BY</StyledWrapper>
    <StyledImageWrapper>
      <StyledLink onClick={() => openLink(process.env.REACT_APP_PRESENTEDBY_BANNER_LINK)}>
        <StyledImage src={createImageCdnLink(process.env.REACT_APP_PRESENTEDBY_BANNER_LOGO)}/>
      </StyledLink>
    </StyledImageWrapper>
    </>
  )
};

export default Wrapper;
