import { SET_APP, SET_SCROLLED_DOWN, SET_GEO_LOCATION, SET_TENANT_DETAILS } from "./actions";

const initialState = {
  isScrolledDown: false,
  geolocation: undefined,
  isIdentityPassed: undefined,
  isMobileNumberVerified: undefined,
  isNonUkUser: undefined,
  loggedInUserId: undefined,
  loggedInUserName: undefined,
  loggedInUserImage: undefined,
  mobileNumber: undefined,
  tenantDetails: undefined
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case SET_APP:
      return {
        ...state,
        ...payload,
      };
      break;
    case SET_SCROLLED_DOWN:
      return {
        ...state,
        isScrolledDown: payload,
      };
    case SET_GEO_LOCATION:
      return {
        ...state,
        geolocation: payload,
      };
    case SET_TENANT_DETAILS:
      return {
        ...state,
        tenantDetails: payload,
      }
    default:
      return state;
  }
}
