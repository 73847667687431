import styled from "styled-components";

import { useDispatch, useSelector } from "react-redux";

import { BlockButton } from "fiver-react-ui-components";

import { setContest } from "components/feature/contest/actions";
import { setToolbar } from "components/layout/toolbar/actions";

const StyledWrapper = styled.div`
  margin: 0 auto;
  width: 90%;
  margin-bottom: 1rem;
`;

const Wrapper = () => {
  const dispatch = useDispatch();

  const { activePickem } = useSelector((s) => s.toolbar);

  const handleClick = () => {
    dispatch(
      setToolbar({
        isBetsOpen: false,
        activePickem,
        activeBet: undefined,
        isActivePickemInPlay: activePickem?.status === "IN_PLAY",
        isActivePickemCompleted: activePickem?.status === "COMPLETED",
        isActivePickemApproved: activePickem?.status === "APPROVED",
      })
    );
    dispatch(setContest({ picks: [] }));
  };

  return (
    <StyledWrapper>
      <BlockButton onClick={handleClick} isSuccess>
        Play Again
      </BlockButton>
    </StyledWrapper>
  );
};

export default Wrapper;
