import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { rgba } from "polished";
import styled, { css } from "styled-components";
import { createImageCdnLink } from "utils";

const backgroundImage = () => {
  return css`
    url(${createImageCdnLink(process.env.REACT_APP_BACKGROUND_IMAGE)});
  `;
};

const StyledWrapper = styled.div`
  ${(p) => !p.noPadding && `padding: ${p.theme.topbarHeight} 0 130px;`};

  ${(p) =>
    p.needsPaddingTop &&
    `padding-top: calc(${p.theme.topbarHeight} + ${
      p.hasBetbar ? (p.needsExtraPaddingTop ? "6rem" : "3rem") : "1rem"
    })`};

  background: ${(p) =>
    p.hasPrimaryBackground
      ? p.theme.colorPrimaryGradient
        ? "transparent"
        : p.theme.colorPrimary
      : p.theme.colorContentBackgroundMobile};

  @media (min-width: ${(p) => p.theme.screenWidthMd}) {
    width: ${(p) => p.theme.railWidth};
    flex: 1 1 auto;
    margin: 0 auto;
    box-shadow: 0 0 14px ${rgba("black", 0.18)};

    background: ${(p) =>
      p.hasPrimaryBackground
        ? p.theme.colorPrimaryGradient || p.theme.colorPrimary
        : process.env.REACT_APP_BACKGROUND_IMAGE
        ? backgroundImage()
        : p.theme.colorContentBackground};
  }
`;

const Content = ({
  children,
  noPadding,
  needsPaddingTop,
  needsExtraPaddingTop,
  hasPrimaryBackground,
  hasBetbar,
  style,
}) => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    document.getElementById("preloader").classList.add("loaded");
  }, []);

  return (
    <StyledWrapper
      hasPrimaryBackground={hasPrimaryBackground}
      noPadding={noPadding}
      needsPaddingTop={needsPaddingTop}
      needsExtraPaddingTop={needsExtraPaddingTop}
      hasBetbar={hasBetbar}
      style={style}
    >
      {children}
    </StyledWrapper>
  );
};

Content.defaultp = {
  noPadding: false,
};

Content.propTypes = {
  noPadding: PropTypes.bool,
};

export default Content;
