import { useSelector } from "react-redux";
import styled from "styled-components";
import { Alert } from "components/shared"
import { Skeleton } from "fiver-react-ui-components";

const StyledWrapper = styled.div`
    margin: 0 auto;
    width: 90%;
    margin-bottom: 1rem;
`;

const MaxBetWarning = () => {
  const { activePickem } = useSelector((s) => s.toolbar);

  return (
    <StyledWrapper>
      <>
        {activePickem?.maxBetsPerUser === undefined ? (
          <Skeleton
            skeletonHeight="16px"
            skeletonWidth="90%"
            skeletonBorderRadius="0.25rem"
          />
        ) : (
          <Alert isDanger style={{ padding: "0.5rem 0.75rem" }}>
            Warning: You have placed the maximum amount of bets allowed for this match
          </Alert>
        )}
      </>
    </StyledWrapper>
  );
}

export default MaxBetWarning;