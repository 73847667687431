import React from "react";
import styled from "styled-components";

import PropTypes from "prop-types";

const StyledCard = styled.div`
  position: relative;
  min-height: ${(props) => props.minHeight || "auto"};
  padding: 1.5rem 1rem;
  border-radius: ${(p) => p.theme.borderRadius};
  background: #ffffff;
  margin: ${(props) => props.margin || "0 1rem"};
`;

const Card = ({ children, noBottomMargin, ...rest }) => (
  <StyledCard {...rest} noBottomMargin={noBottomMargin}>
    {children}
  </StyledCard>
);

Card.propTypes = {
  minHeight: PropTypes.string,
  noBottomMargin: PropTypes.bool,
};

export default Card;
