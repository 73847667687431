import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { setIsInBlockedTerritory } from "components/feature/auth/actions";
import axios from "axios";

const useCheckLogin = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    const checkToken = async () => {
      try {
        const profile = await axios.get(`/me`);
        history.push(process.env.REACT_APP_PICKS_SLUG || "/picks");
      } catch (error) {
        if (!error.response) {
          dispatch(setIsInBlockedTerritory(true));
          history.push("/");
        }
      }
    };

    checkToken();
  }, []);
};

export default useCheckLogin;
