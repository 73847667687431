import React from "react";
import styled from "styled-components";

const StyledWrapper = styled.span`
  font-size: 0.675rem;
  font-weight: 400;
  color: ${(props) => props.theme.colorLabel};
`;

const SmallPrint = ({ children }) => <StyledWrapper>{children}</StyledWrapper>;

export default SmallPrint;
