import styled from "styled-components";

import { useSelector } from "react-redux";

import { rgba } from "polished";

import { Skeleton, DateTime, PenceToPounds } from "fiver-react-ui-components";

import { createImageCdnLink } from "@low6dev/react-utils/dist";

const StyledWrapper = styled.div`
  position: relative;
  overflow: hidden;
  transform: translateZ(0);
  display: flex;
  align-items: center;
  padding: 0 2rem;
  height: 300px;
  background-size: cover;
  margin: 0 0 3rem;
`;

const StyledOverlay = styled.div`
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90deg,
    ${rgba("#000000", 0.7)},
    ${rgba("#000000", 0)} 90%
  );
`;

const StyledContent = styled.div`
  position: relative;
  z-index: 1;
  text-transform: uppercase;
  color: ${rgba("white", 0.96)};
  padding-right: 4rem;

  @media (min-width: ${(props) => props.theme.screenWidthMd}) {
    padding-right: 10rem;
  }
`;

const StyledEvent = styled.div`
  margin-bottom: 1rem;
`;

const StyledEventDate = styled.div`
  color: ${(p) =>
    process.env.REACT_APP_CONTEST_BANNER_DATE_COLOR || p.theme.colorAccent};
`;

const StyledEventDay = styled.div``;

const StyledJackpot = styled.div`
  font-size: 2rem;
  font-weight: 700;
  letter-spacing: 3px;
  margin-bottom: 1rem;
`;

const StyledTopScores = styled.div`
  letter-spacing: 2px;
`;

const Wrapper = () => {
  const { activePickem } = useSelector((s) => s.toolbar);

  const isPromoPrize = activePickem?.promoPrizeFund > 0;

  const prizeLabel =
    activePickem?.pickemTexts?.bannerBarPrizeLabel ||
    activePickem?.pickemTexts?.betBarPrizeLabel ||
    process.env.REACT_APP_TEXT_PRIZEPOOL ||
    (isPromoPrize ? "Promo Prize" : "Prize Pool");

  const customPrize =
    activePickem?.pickemTexts?.bannerBarPrizeText ||
    activePickem?.pickemTexts?.betBarPrizeText ||
    activePickem?.prizeText ||
    process.env.REACT_APP_CONTEST_BANNER_JACKPOT;

  return (
    <StyledWrapper
      style={{
        backgroundImage: `url(${activePickem?.media?.promotedImage})`,
      }}
    >
      <StyledOverlay />
      <StyledContent>
        {!activePickem ? (
          <>
            <Skeleton
              display="block"
              skeletonWidth="100px"
              skeletonHeight="24px"
              margin="10px 0 30px"
            />
            <Skeleton
              display="block"
              skeletonWidth="240px"
              skeletonHeight="44px"
              margin="10px 0 30px"
            />
            <Skeleton
              display="block"
              skeletonWidth="170px"
              skeletonHeight="24px"
              margin="10px 0 10px"
            />
          </>
        ) : (
          <>
            <StyledEvent>
              <StyledEventDay>{activePickem?.name}</StyledEventDay>
              <StyledEventDate>
                {activePickem?.featuredTitle && (
                  <span>{activePickem?.featuredTitle}</span>
                )}
                {/* <DateTime format="D MMMM">{activePickem?.start}</DateTime> */}
              </StyledEventDate>
            </StyledEvent>
            <StyledJackpot>
              {customPrize || (
                <PenceToPounds showCurrencySymbol hideDecimals>
                  {activePickem?.prizeFund}
                </PenceToPounds>
              )}

              <br />
              {prizeLabel}
            </StyledJackpot>

            <StyledTopScores>
              {process.env.REACT_APP_CONTEST_BANNER_JACKPOT_SUBTITLE || (
                <>
                  Top scores win{" "}
                  <PenceToPounds showCurrencySymbol hideDecimals>
                    {activePickem?.prizeFund}
                  </PenceToPounds>{" "}
                  Pool
                </>
              )}
            </StyledTopScores>
          </>
        )}
      </StyledContent>
    </StyledWrapper>
  );
};

export default Wrapper;
