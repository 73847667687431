import { handleError } from "utils";
import axios from "axios";
import { isAndroid } from "react-device-detect";

export const SET_APP = "app:SET_APP";
export const SET_SCROLLED_DOWN = "app:SET_SCROLLED_DOWN";
export const SET_GEO_LOCATION = "app:SET_GEO_LOCATION";
export const SET_TENANT_DETAILS = "app:SET_TENANT_DETAILS";

export const setApp = (app) => {
  return {
    type: SET_APP,
    payload: app,
  };
};

export const loadAppStuff = (profile) => async (dispatch) => {
  try {
    let mobileNumber,
      mobileNumberVerified,
      identityPassed,
      nonUkUser,
      username,
      image;

    // if (!profile) {
    const profileRes = await axios.get("/me");
    profile = { ...profile, ...profileRes?.data?.data };
    // }

    if (process.env.REACT_APP_HAS_GOOGLE_TAG_MANAGER === "true") {
      window.gtag("set", { user_id: profile.id });
    }

    mobileNumber = profile?.mobileNumber;
    mobileNumberVerified = profile.mobileNumberVerified;
    identityPassed = profile.identityPassed;
    nonUkUser = profile.nonUkUser;
    username = profile.username;
    image = profile.image;
    const loggedInUserId = profile.id;

    // get wallet
    const walletRes = await axios.get(`/wallet`);

    const walletBalance = walletRes?.data?.data?.balance;
    const walletPromoBalance = walletRes?.data?.data?.promoBalance;

    // check if dismissed betbar help tooltip
    const hasReadBetbarHelp = localStorage.getItem(
      "fiver_has_read_betbar_help"
    );

    const appStuff = {
      mobileNumber,
      showAffiliateBanners:
        process.env.REACT_APP_SHOW_AFFILIATE_BANNERS === "true",
      affiliateBannerUrl: process.env.REACT_APP_AFFILIATE_BANNER_URL,
      showAffiliatePopup: process.env.REACT_APP_SHOW_AFFILIATE_POPUP === "true",
      showSubmissionPopup: false,
      isDevEnvironment: process.env.NODE_ENV === "development",
      isMobileNumberVerified: mobileNumberVerified,
      isIdentityPassed: identityPassed,
      isNonUkUser: nonUkUser,
      isAndroid,
      loggedInUserName: username,
      loggedInUserImage: image,
      loggedInUserId,
      walletBalance,
      walletPromoBalance,
      hasReadBetbarHelp: hasReadBetbarHelp ? true : false,
    };

    dispatch(setApp(appStuff));
  } catch (response) {
    handleError(response);
  }
};

export function setScrolledDown(value) {
  return {
    type: SET_SCROLLED_DOWN,
    payload: value,
  };
}

export function setGeoLocation(data) {
  return {
    type: SET_GEO_LOCATION,
    payload: data,
  };
}

export function setTenantDetails(data) {
  return {
    type: SET_TENANT_DETAILS,
    payload: data,
  };
}
