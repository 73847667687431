import axios from "axios";
import history from "router/history";
import { handleError } from "utils";

import {
  osVersion,
  osName,
  browserVersion,
  browserName,
} from "react-device-detect";

import { loadAppStuff } from "components/feature/app/actions";

export const CHECK_LOGIN = "login:CHECK_LOGIN";
export const CLEAR_LOGIN = "login:CLEAR_LOGIN";
export const SET_IS_LOGGING_IN = "login:SET_IS_LOGGING_IN";
export const SET_GDPR_ACCEPTED = "login:SET_GDPR_ACCEPTED";

export function setIsLoggingIn(val) {
  return {
    type: SET_IS_LOGGING_IN,
    payload: val,
  };
}

export function setGdprAccepted(val) {
  return {
    type: SET_GDPR_ACCEPTED,
    payload: val,
  };
}

export const checkLogin = (username, password) => (dispatch, getState) => {
  localStorage.removeItem("fiver_react_access_token");
  delete axios.defaults.headers.common["Authorization"];

  dispatch(setIsLoggingIn(true));
  axios
    .post(`/auth/login`, { username, password })
    .then(({ data }) => {
      const currentDate = new Date().toLocaleString();
      const { access_token, refresh_token } = data;
      localStorage.setItem("fiver_react_access_token", access_token);
      localStorage.setItem("fiver_react_refresh_token", refresh_token);

      axios.defaults.headers.common["Authorization"] = `Bearer ${access_token}`;

      dispatch({
        type: CHECK_LOGIN,
        payload: { loginSuccess: undefined },
      });

      axios
        .get(`/me`)
        .then(({ data }) => {
          const profile = data.data;

          dispatch(loadAppStuff(profile));

          if (profile.gdprAccepted) {
            dispatch(setIsLoggingIn(undefined));
            dispatch(setGdprAccepted(true));
            localStorage.removeItem("fiver_react_gdpr_required");

            const dateArray = profile.birthDate.split("-");

            window.xtremepush(
              "event",
              "LandingPage.Login",
              JSON.stringify({
                AppName: process.env.REACT_APP_TITLE,
              })
            );

            window.xtremepush("set", "user_id", profile.id);

            window.xtremepush("user", "update", {
              user_id: profile.id,
              email: profile.email,
              mobile_number: profile.mobileNumber,
              first_name: profile.firstName,
              last_name: profile.lastName,
              birth_date: profile.birthDate,
              tenant_id: process.env.REACT_APP_TENANT,
              username: profile.username,
              sms_subscription:
                profile.contactPermissions.smsNotificationsAccepted,
              email_subscription:
                profile.contactPermissions.emailNotificationsAccepted,
              push_subscription:
                profile.contactPermissions.inAppNotificationsAccepted,
            });

            if (window?.webkit?.messageHandlers?.appInterface) {
              window.webkit.messageHandlers.appInterface.postMessage(
                JSON.stringify({
                  profileId: profile.id,
                })
              );
            }

            if (window?.appInterface) {
              window.appInterface.postMessage(
                JSON.stringify({
                  profileId: profile.id,
                })
              );
            }

            history.push(process.env.REACT_APP_PICKS_SLUG || "/picks");

            // const data = {
            //   tenant: [process.env.REACT_APP_TENANT],
            //   login_date_time: [currentDate],
            //   login: [profile.username],
            //   low6_id: [`${profile.id}`],
            //   location: [`${getState()?.app?.geolocation?.city || ""}`],
            //   age: [
            //     `${Math.floor(
            //       (new Date() -
            //         new Date(dateArray[0], dateArray[1] - 1, dateArray[2])) /
            //         (1000 * 60 * 60 * 24 * 365)
            //     )}`,
            //   ],
            //   // geolocation_city: [location],
            //   operating_system: [`${osName} ${osVersion}`],
            //   browser: [`${browserName} ${browserVersion}`],
            // };
            //
            // window[`lotame_${process.env.REACT_APP_LOTAME_CLIENT_ID}`].cmd.push(
            //   function () {
            //     window[`lotame_${process.env.REACT_APP_LOTAME_CLIENT_ID}`].page(
            //       {
            //         behaviorIds: [+process.env.REACT_APP_BEHAVIOUR_LOGIN_ID],
            //         ruleBuilder: data,
            //       }
            //     );
            //   }
            // );
          } else {
            dispatch(setGdprAccepted(false));
          }
        })
        .catch(({ response }) => {
          handleError(response);
        });
    })
    .catch(({ response }) => {
      dispatch(setIsLoggingIn(undefined));
      localStorage.removeItem("fiver_react_access_token");
      if (
        response?.data?.code === "userIsSelfExcluded" ||
        response?.data?.code === "userNeedsToBeReactivated" ||
        response?.data?.code === "userDisabled"
      ) {
        dispatch({
          type: CHECK_LOGIN,
          payload: { loginSuccess: false, loginError: response?.data?.message },
        });
      } else {
        dispatch({
          type: CHECK_LOGIN,
          payload: {
            loginSuccess: false,
            loginError: "Invalid username or password",
          },
        });
      }
    });
};

export function clearLogin() {
  return {
    type: CLEAR_LOGIN,
    payload: undefined,
  };
}

export const updateGdpr = (params) => (dispatch) => {
  axios
    .get(`/me?include=address`)
    .then(({ data }) => {
      const profile = data.data;

      profile.contactPermissions = params;
      profile.gdprAccepted = true;

      axios
        .put(`/me`, profile)
        .then(({ data }) => {
          localStorage.removeItem("fiver_react_gdpr_required");
          history.push(process.env.REACT_APP_PICKS_SLUG || "/picks");
        })
        .catch(({ response }) => {
          handleError(response);
        });
    })
    .catch(({ response }) => {
      handleError(response);
    });
};
