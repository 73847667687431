import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {Formik, Form, Field, useFormikContext} from "formik";
import DatePicker from "react-modern-calendar-datepicker";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import {FormRowMessage} from "../../shared";
import {PrizeDepositFormSchema} from "./PrizeDepositFormSchema";
import FormikEffect from "./FormikEffect";
import CurrencyInput from "react-currency-input-field";


const StyledField = styled(Field)`
  border-bottom: 3px solid #696971;
  border-left: none;
  border-right: none;
  border-top: none;
  width: 100%;
  padding: 0.5rem 0;
  font-size: 1.125rem;
  margin-bottom: 1rem;
  background: transparent;
  outline: none;
`;

const StyledCurrencyInput = styled(CurrencyInput)`
  border-bottom: 3px solid #696971;
  border-left: none;
  border-right: none;
  border-top: none;
  width: 100%;
  padding: 0.5rem 0;
  font-size: 1.125rem;
  margin-bottom: 1rem;
  background: transparent;
  outline: none;
`;

const StyledFormikWraper = styled.div`
  width: 100%;
  margin-bottom: 1rem;
`;

const StyledDepositPicker = styled.div`
  display: flex;
  width: 100%;
  margin-top: 2rem;
  margin-bottom: 1.25rem;
  padding: 0.5rem 0;
  background: rgba(118, 118, 128, 0.12);
  border-radius: 0.35rem;
`;

const StyledSum = styled.div`
  width: 33.3%;
  font-size: 0.75rem;
  text-align: center;
  cursor: pointer;
  &:not(:last-child) {
    border-right: 1px solid #8E8E93;
  }
`;

const PrizeDepositForm = ({
  setDepositParams,
  setDepositErrors,
}) => {
  const [initialValues, setInitialValues] = useState({
    amount: 5,
    password: "",
  });
  
  return (
    <StyledFormikWraper>
      <Formik
        initialValues={initialValues}
        validationSchema={PrizeDepositFormSchema()}
        enableReinitialize={true}
      >
        {({
            errors,
            values,
            touched,
            handleChange,
            handleBlur,
            setFieldValue,
            setFieldTouched,
          }) => {
          return (
            <>
              <StyledDepositPicker>
                <StyledSum onClick={() => setFieldValue('amount', 5..toFixed(2))} >5</StyledSum>
                <StyledSum onClick={() => setFieldValue('amount', 10..toFixed(2))}>10</StyledSum>
                <StyledSum onClick={() => setFieldValue('amount', 15..toFixed(2))}>15</StyledSum>
              </StyledDepositPicker>
  
              <Form >
                <FormikEffect onChange={(values, errors) => {
                  setDepositParams(values)
                  setDepositErrors(errors)
                }}  />
                <StyledCurrencyInput
                    id="input-example"
                    name="amount"
                    placeholder="Amount"
                    prefix="£"
                    decimalScale={2}
                    decimalsLimit={2}
                    fixedDecimalLength={0}
                    allowNegativeValue={0}
                    defaultValue={values?.amount}
                    value={values?.amount}
                    onValueChange={(value) => {
                      setFieldValue('amount', value)
                      setFieldTouched('amount', true)
                    }}
                    onBlur={handleBlur}
                />
                {/*<StyledField*/}
                {/*  name="amount"*/}
                {/*  placeholder="Amount"*/}
                {/*  onBlur={handleBlur}*/}
                {/*  onChange={handleChange}*/}
                {/*/>*/}
                <FormRowMessage isVisible={(touched.amount && errors.amount)}>
                  {errors.amount}
                </FormRowMessage>
    
                <StyledField
                  type="password"
                  name="password"
                  placeholder="Password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                <FormRowMessage isVisible={(touched.password && errors.password)}>
                  {errors.password}
                </FormRowMessage>
              </Form>
            </>
          );
        }}
      </Formik>
    </StyledFormikWraper>
    
  );
};


export default PrizeDepositForm;