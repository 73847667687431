import { useDispatch, useSelector } from "react-redux";
import styled, { css, keyframes } from "styled-components";
import { rgba } from "polished";
import { setApp } from "components/feature/app/actions";
import { BlockButton } from "components/shared";

const visibleStyles = css`
  visibility: visible;
  opacity: 1;
`;

const StyledWrapper = styled.div`
  position: fixed;
  z-index: 1001;
  top: ${(p) =>
    p.isLeaderboardBarVisible &&
    process.env.REACT_APP_IS_EVENT_BASED_SPORT !== "true"
      ? "10.25rem"
      : process.env.REACT_APP_IS_EVENT_BASED_SPORT === "true"
      ? p.isApproved
        ? "3.5rem"
        : "6.5rem"
      : "7.25rem"};
  left: 0;
  width: 100%;
  visibility: hidden;
  opacity: 0;
  padding: 0 0.5rem;

  ${(p) => p.isVisible && visibleStyles};

  transition-property: visibility, opacity;
  transition-duration: 0.7s;

  @media (min-width: ${(props) => props.theme.screenWidthMd}) {
    left: 50%;
    width: ${(props) => props.theme.railWidth};
    transform: translateX(-50%);
  }
`;

const appear = keyframes`
  0% { transform: translateY(1.5rem);}
  100% { transform: translateY(0);}
`;

const StyledCard = styled.div`
  width: 300px;
  padding: 1rem 1rem 1.5rem;
  line-height: 1.25;
  border-radius: ${(p) => p.theme.borderRadius};
  box-shadow: 0 0 20px ${rgba("black", 0.12)};
  background: white;
  animation: ${(p) => (p.isVisible ? appear : null)} 0.7s;
`;

const StyledTitle = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
`;

const StyledBadge = styled.div`
  padding: 0.25rem;
  background: ${(p) => p.theme.colorAlertSuccessText};
  color: white;
  border-radius: 0.25rem;
  font-size: 0.635rem;
  margin-left: 0.5rem;
`;

const StyledText = styled.div`
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.15rem;
  margin-bottom: 1rem;
`;

const Wrapper = ({ isLeaderboard }) => {
  const dispatch = useDispatch();

  const { hasReadBetbarHelp, isDevEnvironment } = useSelector((s) => s.app);
  const { activePickemList, isActivePickemApproved } = useSelector(
    (s) => s.toolbar
  );

  const handleHelpDismissed = () => {
    //if (!isDevEnvironment) {
    localStorage.setItem("fiver_has_read_betbar_help", "true");
    //}
    dispatch(
      setApp({
        hasReadBetbarHelp: true,
      })
    );
  };

  const isVisible = activePickemList?.length > 1 && !hasReadBetbarHelp;

  return (
    <StyledWrapper
      isVisible={isVisible}
      isApproved={isActivePickemApproved}
      isEventBasedSport={process.env.REACT_APP_IS_EVENT_BASED_SPORT === "true"}
      isLeaderboardBarVisible={!isActivePickemApproved && isLeaderboard}
    >
      <StyledCard isVisible={isVisible}>
        <StyledTitle>
          {process.env.REACT_APP_HIDE_GAMBLING_REFERENCES !== "true" && "Low6 "}
          {process.env.REACT_APP_TEXT_BETBAR || "Bet Bar"}
          <StyledBadge>New</StyledBadge>
        </StyledTitle>
        <StyledText>
          This feature allows you to check Scores,{" "}
          {process.env.REACT_APP_TEXT_PICKS || "Picks"} and enter Contests!
        </StyledText>
        <BlockButton onClick={handleHelpDismissed} size="xs">
          Ok, got it!
        </BlockButton>
      </StyledCard>
    </StyledWrapper>
  );
};

export default Wrapper;
