import * as Yup from "yup";
import React from "react";
import {validateAge} from "../../../utils";
import moment from "moment";


export const PrizeDepositFormSchema = () => {
  return Yup.object().shape({
    amount: Yup.string()
        .required('Amount is required.')
        .test('number', "Amount can only contain numbers", function (value) {
          return /^[0-9.,]*$/.test(value)
        })
        .test('min', "Amount must be at least £5.00.", function (value) {
          return +value >= 5;
        }),
    password: Yup.string()
        .required('Password is required.')
        .min(8,'Password must be at least 8 characters.')
        .test('pass', 'At least 8 characters and contain 1 uppercase character, 1 lowercase character and 1 number', function (value, context) {
          return /[A-Z]/g.test(value) && /[0-9]/g.test(value) && /[a-z]/g.test(value)
        }),
  });
}

