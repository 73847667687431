import styled from "styled-components";
import { NavLink } from "react-router-dom";

const StyledNav = styled.nav`
  position: fixed;
  z-index: 2;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: space-between;
  justify-content: space-between;
  pointer-events: none;
  background: #ffffff;
  box-shadow: rgb(0 0 0 / 12%) -6px 0px 8px;

  @media (min-width: ${(props) => props.theme.screenWidthMd}) {
    left: 50%;
    width: ${(props) => props.theme.railWidth};
    margin: 0 auto;
    transform: translateX(-50%);
  }
`;

const StyledNavLink = styled(NavLink)`
  position: relative;
  flex: 1 1 50%;
  display: flex;
  height: 80px;
  padding-top: 12px;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  text-align: center;
  transition: transform 0.25s;
  pointer-events: all;
  text-decoration: none;
  color: ${(p) => p.theme.colorNavbarLink};

  &.active {
    color: ${(p) => p.theme.colorPrimary};
  }
`;

const StyledNavLinkIcon = styled.span`
  margin-bottom: 0.325rem;
`;

const StyledNavLinkText = styled.span`
  font-size: 0.675rem;
  font-family: ${(p) => p.theme.fontFamilyDefault};
`;

const items = [];

items.push({
  to: process.env.REACT_APP_PICKS_SLUG || "/picks",
  text: process.env.REACT_APP_TEXT_PICKS || "Picks",
  icon: "format_list_numbered",
});
items.push({ to: "/leaderboard", text: "Leaderboard", icon: "emoji_events" });

if (process.env.REACT_APP_HIDE_GAMBLING_REFERENCES !== "true") {
  items.push({
    to: "/wallet",
    text: "Wallet",
    icon: "account_balance_wallet",
  });
}

items.push({ to: "/profile", text: "Profile", icon: "account_circle" });

const Wrapper = () => {
  return (
    <StyledNav>
      {items.map((item) => (
        <StyledNavLink key={item.text} to={item.to}>
          <StyledNavLinkIcon className="material-icons">
            {item.icon}
          </StyledNavLinkIcon>
          <StyledNavLinkText>{item.text}</StyledNavLinkText>
        </StyledNavLink>
      ))}
    </StyledNav>
  );
};

export default Wrapper;
