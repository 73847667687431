import styled from "styled-components";

const StyledButtonWrapper = styled.div`
  width: 50px;
  min-width: 50px;
  max-width: 50px;
`;

const StyledButton = styled.button`
  width: 100%;
  height: 24px;
  border-radius: 12px;

  font-size: 0.7rem;
  background: ${(p) =>
    p.disabled
      ? p.theme.colorDisabledBackground
      : p.isSuccess
      ? p.theme.colorAlertSuccessTextAlt
      : p.theme.colorPrimary};
  color: ${(p) =>
    p.disabled
      ? p.theme.colorDisabledText
      : p.isSuccess
      ? p.theme.colorSuccessText
      : p.theme.colorPrimaryText};

  ${(p) => p.disabled && "cursor: not-allowed"};
`;

const Wrapper = ({ children, disabled, onClick, index, isSuccess }) => {
  return (
    <StyledButtonWrapper>
      <StyledButton disabled={disabled} onClick={onClick} isSuccess={isSuccess}>
        {children}
      </StyledButton>
    </StyledButtonWrapper>
  );
};

export default Wrapper;
