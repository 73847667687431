import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Form, FormRow, BlockButton, Card, Alert } from "components/shared";
import { setSelfExclusionDuration } from "./actions";
import FormSelect from "../../shared/form/FormSelect";
import { StyledMatIcon } from "./TakeBreak";

const SelfExclusion = () => {
  const dispatch = useDispatch();
  const [state, setState] = useState(6);

  const handleSelectChanged = (e) => {
    setState(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(setSelfExclusionDuration({ duration: state }));
  };

  return (
    <Card style={{zIndex: 0}}>
      <Alert margin="0 0 1rem">
        Self exclusion completely blocks access to low6.
        <br />
        <br />
        Please note that once you have set a self-exclusion period, it cannot be
        removed for the selected duration.
      </Alert>
      <Form>
        <FormRow isSmall>
          <FormSelect
            autoFocus
            isRequired
            onChange={handleSelectChanged}
            name="duration"
            placeholder="1 day"
            value={state}
          >
            <option value="6">6 months</option>
            <option value="12">1 year</option>
            <option value="24">2 years</option>
            <option value="36">3 years</option>
            <option value="48">4 years</option>
            <option value="60">5 years</option>
          </FormSelect>
          <StyledMatIcon>expand_more</StyledMatIcon>
        </FormRow>

        <BlockButton onClick={handleSubmit}>SET SELF EXCLUSION</BlockButton>
      </Form>
    </Card>
  );
};

export default SelfExclusion;
