import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const StyledWrapper = styled.div`
  overflow: hidden;
  position: relative;
  background: #fdeeee;
  color: #ea5454;
  border-radius: 0.5rem;
  padding: 1rem;
  font-size: 0.875rem;
  margin-bottom: 1rem;

  & div:last-child {
    margin-bottom: 0 !important;
  }
`;

const FormValidationSummary = ({ children }) => (
  <StyledWrapper>{children}</StyledWrapper>
);

FormValidationSummary.defaultProps = {
  isSuccess: false,
};

FormValidationSummary.propTypes = {
  isSuccess: PropTypes.bool,
};

export default FormValidationSummary;
