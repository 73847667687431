import { useSelector } from "react-redux";
import styled from "styled-components";

import ContestPicksSkeleton from "./ContestPicksSkeleton";
import ContestPicksTiebreak from "./ContestPicksTiebreak";

import QuestionTypeDefault from "./questionTypes/default/Wrapper";
import QuestionTypeBlenheim from "./questionTypes/blenheim/Wrapper";
import QuestionTypeGolf from "./questionTypes/golf/Wrapper";

import { Card, AffiliateBanner } from "components/shared";

const StyledWrapper = styled.div`
  ${(props) => props.needsPaddingTop && "padding-top: 1rem;"};
`;

const StyledCard = styled(Card)`
  border-radius: ${(p) => p.theme.borderRadius};
`;

const StyledBlock = styled.div`
  padding: 0 3rem 2rem;
  border-top: 2px solid ${(props) => props.theme.colorBorder};
  background: white;
  margin-bottom: 4rem;
`;

const StyledNumber = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  margin: 0 auto;
  font-weight: 700;
  border-radius: 50%;
  border: 5px solid
    ${(props) =>
      process.env.REACT_APP_BACKGROUND_IMAGE
        ? "white"
        : props.theme.colorContentBackground};
  background: ${(props) => props.theme.colorAccent};
  color: ${(props) => props.theme.colorAccentText};
  transform: translateY(-25px);
`;

const ContestPicks = ({ updatePicks, isLocked }) => {
  const { showAffiliateBanners } = useSelector((s) => s.app);
  const { activePickem, activeBet, hadBet } = useSelector((s) => s.toolbar);
  const { picks } = useSelector((s) => s.contest);
  const questions = activePickem?.questions;
  const selections = activeBet?.selections;

  // const indexToShowBanner = Math.round(questions?.length / 2);
  let indexesToShowBanner = [2];

  if (process.env.REACT_APP_AFFILIATE_BANNER_INDEXES) {
    indexesToShowBanner = process.env.REACT_APP_AFFILIATE_BANNER_INDEXES.split(
      ","
    ).map((i) => +i);
  }

  const showIcon =
    hadBet &&
    ((activePickem?.status.includes("IN_PLAY") &&
      activePickem.hasLiveScoring) ||
      activePickem?.status.includes("COMPLETE"));

  return (
    <StyledWrapper needsPaddingTop={process.env.REACT_APP_HIDE_CONTEST_BANNER}>
      {!questions && <ContestPicksSkeleton />}
      {questions &&
        questions.map(
          (
            {
              id,
              label,
              subtitle,
              options,
              data,
              availablePoints,
              popularSelection,
              status,
              tips,
            },
            index
          ) => {
            const correctAnswerId =
              !hadBet || !options.some((obj) => obj.points)
                ? -1
                : options.find((obj) => obj.points > 0).id;

            const myAnswerId = picks?.[id];

            let popularSelectionString = "";

            const popularSelectionLabel = options.find(
              (obj) => obj.id === popularSelection?.optionId
            )?.label;

            if (popularSelection && popularSelectionLabel) {
              popularSelectionString = `${popularSelectionLabel} - ${popularSelection.percentage}%`;
            } else {
              popularSelectionString = "N/A";
            }

            let tipsterString = "";
            if (tips && tips[0]) {
              tipsterString = options.find(
                (obj) => obj.id === tips[0].option.id
              ).label;
            }

            return (
              <>
                {indexesToShowBanner.includes(index) &&
                  showAffiliateBanners && <AffiliateBanner hasBottomMargin />}

                {indexesToShowBanner.includes(index) &&
                (process.env.REACT_APP_AFFILIATE_BANNER ||
                  process.env.REACT_APP_AFFILIATE_BANNER_IFRAME) ? (
                  <AffiliateBanner
                    index={index}
                    hasBottomMargin
                    iframeUrl={process.env.REACT_APP_AFFILIATE_BANNER_IFRAME}
                    imageUrl={process.env.REACT_APP_AFFILIATE_BANNER}
                    ctaUrl={process.env.REACT_APP_AFFILIATE_URL}
                  />
                ) : null}

                <StyledCard
                  key={id}
                  style={{
                    marginBottom: index === questions.length - 1 ? 0 : "3rem",
                    paddingTop: 0,
                  }}
                >
                  <StyledNumber>{index + 1}</StyledNumber>

                  {process.env.REACT_APP_IS_BLENHEIM !== "true" &&
                    process.env.REACT_APP_IS_EUROTOUR !== "true" && (
                      <QuestionTypeDefault
                        label={label}
                        subtitle={subtitle}
                        data={data}
                        popularSelectionString={popularSelectionString}
                        correctAnswerId={correctAnswerId}
                        myAnswerId={myAnswerId}
                        isLocked={isLocked}
                        showIcon={showIcon}
                        availablePoints={availablePoints}
                        tips={tips}
                        tipsterString={tipsterString}
                        id={id}
                        updatePicks={updatePicks}
                        options={options}
                      />
                    )}
                  {process.env.REACT_APP_IS_BLENHEIM === "true" && (
                    <QuestionTypeBlenheim
                      questionTitle={label}
                      questionSubtitle={subtitle}
                      label={label}
                      subtitle={subtitle}
                      data={data}
                      popularSelectionString={popularSelectionString}
                      correctAnswerId={correctAnswerId}
                      myAnswerId={myAnswerId}
                      isLocked={isLocked}
                      showIcon={showIcon}
                      availablePoints={availablePoints}
                      tips={tips}
                      tipsterString={tipsterString}
                      id={id}
                      updatePicks={updatePicks}
                      options={options}
                    />
                  )}
                  {process.env.REACT_APP_IS_EUROTOUR === "true" && (
                    <QuestionTypeGolf
                      id={id}
                      label={label}
                      subtitle={subtitle}
                      data={data}
                      options={options}
                      correctAnswerId={correctAnswerId}
                      myAnswerId={myAnswerId}
                      isLocked={isLocked}
                      showIcon={showIcon}
                      updatePicks={updatePicks}
                      availablePoints={availablePoints}
                      popularSelection={popularSelection}
                    />
                  )}
                </StyledCard>
              </>
            );
          }
        )}

      {activePickem?.tieBreakerQuestion?.length > 0 && <ContestPicksTiebreak />}
    </StyledWrapper>
  );
};

export default ContestPicks;
