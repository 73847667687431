import React from "react";
import styled from "styled-components";

const StyledSvg = styled.svg`
  & path,
  & ellipse {
    fill: ${(props) =>
      process.env.REACT_APP_COLOR_PRIMARY_IS_WHITE
        ? props.theme.colorAccent
        : props.theme.colorPrimaryText};
  }
`;

const Low6Logo = ({ height, width }) => (
  <StyledSvg
    id="Group_2102"
    data-name="Group 2102"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 139.336 54.143"
  >
    <g id="Group_196" data-name="Group 196" transform="translate(0 0)">
      <path
        id="Path_5987"
        data-name="Path 5987"
        d="M16.9,6.565h8.9v43.58H16.9Zm31.4,43.79A16.509,16.509,0,1,1,64.517,33.848,16.365,16.365,0,0,1,48.3,50.355Zm0-7.481a9.028,9.028,0,1,0-8.871-9.026A8.946,8.946,0,0,0,48.3,42.874Zm18-24.518h8.994L80.309,37.6l6.009-19.244h7.465L99.83,37.6l1.876-6.7,5.435,9.624-3.024,9.816H96.079L90.208,30.707l-6.34,19.632H75.946Z"
        transform="translate(-16.895 -6.565)"
        fill="#1b295b"
      />
      <path
        id="Path_5988"
        data-name="Path 5988"
        d="M57.07,6.732H84.287L74.335,21.654a19.752,19.752,0,0,0-4.48,11.335L56.612,9.693S54.736,7.277,57.07,6.732Zm36.416,0L86.6,17.1s10.616-.311,15.108,11.428a14.56,14.56,0,0,1,.665,4.466l13.27-23.27s2.45-2.65-1.123-2.987Z"
        transform="translate(22.879 -6.395)"
        fill="#1b295b"
      />
      <ellipse
        id="Ellipse_1"
        data-name="Ellipse 1"
        cx="8.79"
        cy="8.946"
        rx="8.79"
        ry="8.946"
        transform="translate(100.381 18.232)"
        fill="#1b295b"
      />
      <path
        id="Path_5989"
        data-name="Path 5989"
        d="M67.13,27.37a16.734,16.734,0,0,0,15.043,0L75.742,38.9a1.245,1.245,0,0,1-2.1,0L67.13,27.37Z"
        transform="translate(34.332 14.651)"
        fill="#1b295b"
      />
    </g>
  </StyledSvg>
);

export default Low6Logo;
