import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const StyledAvatar = styled.img`
  flex: 0 0 ${(p) => p.size};
  object-fit: cover;
  width: ${(props) => props.size};
  min-width: ${(props) => props.size};
  max-width: ${(props) => props.size};
  height: ${(props) => props.size};
  min-height: ${(props) => props.size};
  max-height: ${(props) => props.size};
  border-radius: 50%;
`;

const Avatar = ({ src, size }) => (
  // <StyledAvatar
  //   style={{
  //     backgroundSize: "100%",
  //     backgroundImage: `url(${src})`,
  //     backgroundPosition: "center",
  //   }}
  //   size={size}
  // />
  <StyledAvatar src={src} size={size} />
);

Avatar.defaultProps = {
  size: "40px",
};

Avatar.propTypes = {
  src: PropTypes.string.isRequired,
  size: PropTypes.string,
};

export default Avatar;
