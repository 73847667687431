import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setIsGeoChecked } from "components/feature/auth/actions";
import Geocode from "react-geocode";
import axios from "axios";

Geocode.setApiKey(process.env.REACT_APP_GEOCODE_API_KEY);

const useGeolocationCheck = (isLandingPage, isGeoChecked) => {
  let history = useHistory();

  const [state, setState] = useState({
    geoError: undefined,
  });

  const dispatch = useDispatch();

  const { geoError } = state;

  const handleGeoChange = (position) => {
    if (position && position.coords) {
      Geocode.fromLatLng(
        position.coords.latitude,
        position.coords.longitude
      ).then(
        (response) => {
          const address = response.results[0].formatted_address;

          const locality = response.results[0].address_components.find((comp) =>
            comp.types.includes("locality")
          );

          const city = locality ? locality.short_name : "";

          dispatch(
            setIsGeoChecked({
              isGeoChecked: address.indexOf("UK") > -1,
              location: address.indexOf("UK") > -1 ? city : undefined,
            })
          );
          setState({
            geoError:
              address.indexOf("UK") === -1
                ? "You must be inside UK to play"
                : undefined,
          });
        },
        (error) => {
          dispatch(
            setIsGeoChecked({
              isGeoChecked: false,
            })
          );
          setState({
            geoError: undefined,
          });
        }
      );
    } else {
      dispatch(
        setIsGeoChecked({
          isGeoChecked: false,
        })
      );
      setState({
        geoError: undefined,
      });
    }
  };

  const handleGeoError = ({ message }) => {
    const niceMessage =
      message.toUpperCase() === "USER DENIED GEOLOCATION"
        ? "Location blocked in settings. Change settings on your device and try again."
        : message;

    dispatch(
      setIsGeoChecked({
        isGeoChecked: false,
      })
    );
    setState({
      geoError: niceMessage,
    });
  };

  useEffect(() => {
    if (!isLandingPage && !isGeoChecked) {
      history.push("/");
    }

    const checkToken = async () => {
      try {
        const profile = await axios.get(
          `/me?include=address,roles,permissions&server-time`
        );
        history.push(process.env.REACT_APP_PICKS_SLUG || "/picks");
      } catch (error) {}
    };

    const checkPermissions = async () => {
      if (navigator) {
        navigator.geolocation.getCurrentPosition(
          handleGeoChange,
          handleGeoError
        );
      }
    };

    if (process.env.REACT_APP_DISABLE_GEOLOCATION !== "true") {
      checkPermissions();
    } else {
      dispatch(
        setIsGeoChecked({
          isGeoChecked: true,
        })
      );
      setState({
        geoError: undefined,
      });
    }

    if (isGeoChecked) {
      checkToken();
    }
  }, []);

  return state;
};

export default useGeolocationCheck;
