import React, { useState } from "react";
import { useSelector } from "react-redux";
import styled, { css } from "styled-components";
import { NavLink } from "react-router-dom";
import { MatIcon } from "../../shared";
import { rgba, darken } from "polished";

const StyledWrapper = styled.div`
  position: fixed;
  z-index: 5;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  height: 80px;
  background: ${(props) => props.theme.colorDark};
  transition: transform 0.45s;

  @media (min-width: ${(props) => props.theme.screenWidthMd}) {
    left: 50%;
    width: ${(props) => props.theme.railWidth};
    margin: 0 auto;
    transform: translateX(-50%);
  }
`;

const sharedButtonStyles = css`
  flex: 1 0 25%;
  display: flex;
  height: 72px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  color: ${rgba("white", 0.5)};
  border-bottom: 3px solid transparent;
`;

const StyledButton = styled(NavLink)`
  ${sharedButtonStyles};

  ${(p) => p.disabled && "pointer-events: none"};

  &.active {
    color: white;
  }
`;

const StyledDisabledButton = styled.div`
  ${sharedButtonStyles};
  background: red;
`;

const StyledButtonText = styled.div`
  font-size: 0.75rem;
`;

const Bottombar = () => {
  const [items] = useState([
    {
      to: process.env.REACT_APP_PICKS_SLUG || "/picks",
      text: process.env.REACT_APP_TEXT_PICKS || "Picks",
      icon: "format_list_numbered",
    },
    {
      to: "/leaderboard",
      text: "Leaderboard",
      icon: process.env.REACT_APP_NAVBAR_ICON_LEADERBOARD || "emoji_events",
    },
    { to: "/wallet", text: "Wallet", icon: "account_balance_wallet" },
    { to: "/profile", text: "Profile", icon: "person" },
  ]);

  const { isRefreshingToken } = useSelector((state) => state.contest);

  return (
    <StyledWrapper>
      {items.map((item, index) =>
        isRefreshingToken || isRefreshingToken === undefined ? (
          <StyledDisabledButton>
            <MatIcon margin="0 0 0.325rem">{item.icon}</MatIcon>
            <StyledButtonText>{item.text}</StyledButtonText>
          </StyledDisabledButton>
        ) : (
          <StyledButton
            onClick={(e) => e.stopPropagation()}
            disabled={isRefreshingToken}
            to={item.to}
          >
            <MatIcon margin="0 0 0.325rem">{item.icon}</MatIcon>
            <StyledButtonText>{item.text}</StyledButtonText>
          </StyledButton>
        )
      )}
    </StyledWrapper>
  );
};

export default Bottombar;
