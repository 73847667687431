import styled from "styled-components";
import LeaderboardRowHeader from "./shared/LeaderboardRowHeader";
import { createImageCdnLink } from "utils";
import users from "./leaderboardEmptyData";
import LeaderboardEmptyModal from "./LeaderboardEmptyModal";

const StyledRow = styled.div`
  background: #fdfdfd;
  border-bottom: 3px solid #f4f4f4;
`;

const StyledWrapper = styled.div`
  position: relative;
  min-height: 250px;
`;

const StyledOverlay = styled.div`
  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.8),
    rgba(255, 255, 255, 1)
  );
`;

const Wrapper = ({ isInPlay }) => {
  const assetsCdn = (index) =>
    `${process.env.REACT_APP_IMAGE_CDN}leaderboard/user${index + 1}.png`;

  return (
    <StyledWrapper>
      {process.env.REACT_APP_HIDE_LEADERBOARD_EMPTY !== "true" && (
        <>
          <StyledOverlay />
          {users.map(({ id, username, rank, points, prize }, index) => (
            <StyledRow>
              <LeaderboardRowHeader
                row={{
                  prize:
                    process.env.REACT_APP_HIDE_MONEY_REFERENCES === "true" ||
                    process.env.REACT_APP_IS_EUROTOUR === "true"
                      ? ""
                      : prize,
                  rank,
                  points:
                    process.env.REACT_APP_IS_EUROTOUR === "true" ? 0 : points,
                  user: {
                    image: assetsCdn(index),
                    username,
                  },
                }}
              />
            </StyledRow>
          ))}
        </>
      )}
      <LeaderboardEmptyModal />
    </StyledWrapper>
  );
};

export default Wrapper;
