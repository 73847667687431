import React from "react";
import styled from "styled-components";
import { rgba, darken } from "polished";
import { createImageCdnLink } from "utils";

const StyledWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  border-radius: ${(p) => p.theme.borderRadius};
  border-top-left-radius: 2rem;
  border-bottom-left-radius: 2rem;
  font-size: 0.75rem;
  background: ${(p) =>
    process.env.REACT_APP_COLOR_TIPSTER || p.theme.colorSuccess};
  color: ${(p) =>
    process.env.REACT_APP_COLOR_TIPSTER_TEXT || p.theme.colorSuccessText};
  margin: 1rem 0 1rem;
`;

const StyledTipsterWrapper = styled.div`
  position: absolute;
  overflow: hidden;
  top: 50%;
  left: -1px;
  transform: translateY(-50%);
  border-radius: 50%;
  background: #fff;
  border: 4px solid
    ${(p) =>
      darken(
        0.0625,
        process.env.REACT_APP_COLOR_TIPSTER || p.theme.colorSuccess
      )};
`;

const StyledTipster = styled.div`
  width: 54px;
  height: 54px;
`;

const StyledTitle = styled.div`
  margin-left: 74px;
  font-size: 0.875rem;
  color: ${rgba("white", 0.75)};
`;

const StyledValue = styled.div`
  flex: 0 0 35%;
  text-align: center;
  font-size: 1.25rem;
  line-height: 18px;
  color: white;

  @media (min-width: ${(props) => props.theme.screenWidthXs}) {
    flex: 0 0 130px;
  }
`;

const ContestPicksTips = ({ tip, tipsterString }) => {
  return (
    <StyledWrapper>
      <StyledTipsterWrapper>
        <StyledTipster
          style={{
            backgroundImage: `url(${tip?.tipster?.avatar})`,
            backgroundSize: "110%",
            backgroundPosition: "center center",
            backgroundRepeat: "no-repeat",
          }}
        />
      </StyledTipsterWrapper>
      <StyledTitle>
        {tip.tipster.name}'s{" "}
        {process.env.REACT_APP_TEXT_PICKS_TO_CHOICE === "true"
          ? "Choice"
          : "Pick"}
      </StyledTitle>
      <StyledValue>{tipsterString}</StyledValue>
    </StyledWrapper>
  );
};

export default ContestPicksTips;
