import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { rgba } from "polished";
import { Overlay } from "fiver-react-ui-components";

import { setToolbar, getToolbar } from "components/layout/toolbar/actions";

import ToolbarUpper from "./ToolbarUpper";
import ToolbarUpperHelp from "./ToolbarUpperHelp";
import ToolbarUpperEvent from "./ToolbarUpperEvent";
import ToolbarMiddle from "./middle/ToolbarMiddle";
import ToolbarLower from "./ToolbarLower";

const StyledWrapper = styled.div`
  position: fixed;
  z-index: ${(p) => p.zIndex};
  top: ${(p) => p.theme.topbarHeight};
  left: 0;
  width: 100%;
  background: white;
  box-shadow: 0px 0px 10px ${rgba("black", 0.1)};

  @media (min-width: ${(props) => props.theme.screenWidthMd}) {
    width: ${(props) => props.theme.railWidth};
    left: 50%;
    transform: translateX(-50%);
  }
`;

const StyledLabel = styled.div`
  flex: 0 0 50px;
  font-size: 0.875rem;
  color: ${(p) => p.theme.colorLabel};
  text-align: ${(p) => (p.isRight ? "right" : "left")};
`;

const Wrapper = ({ isLeaderboard }) => {
  const dispatch = useDispatch();

  const {
    activePickem,
    activePickemList,
    activeBet,
    isBetsOpen,
    isActive,
    isActivePickemApproved,
    isPickemEmpty,
  } = useSelector((s) => s.toolbar);

  const { hasReadBetbarHelp } = useSelector((s) => s.app);

  // const isLoading = !activePickem?.id;
  // let isLoadingBet = !activeBet?.betId;

  useEffect(() => {
    if (!activePickem) {
      dispatch(getToolbar());
    }
  }, [activePickem]);

  return isPickemEmpty ? null : (
    <>
      {process.env.REACT_APP_HIDE_BETBAR_HELP !== "true" && (
        <ToolbarUpperHelp isLeaderboard={isLeaderboard} />
      )}
      <StyledWrapper
        zIndex={!hasReadBetbarHelp && activePickemList?.length > 1 ? 6 : 3}
      >
        {process.env.REACT_APP_BETBAR_SHOW_EVENT_TITLE_AND_DATE === "true" ? (
          <ToolbarUpperEvent />
        ) : (
          <ToolbarUpper />
        )}

        <ToolbarMiddle isActive={isBetsOpen} />
        {isLeaderboard &&
          !!activeBet &&
          isActivePickemApproved === false &&
          isBetsOpen === false && <ToolbarLower />}
      </StyledWrapper>
    </>
  );
};

export default Wrapper;
