import { useSelector } from "react-redux";
import styled from "styled-components";
import { MatIcon, ClubName } from "fiver-react-ui-components";
import { createImageCdnLink } from "utils";

const widthWrapperMobile = "144px";
const widthClub = "38px";
const widthVs = "24px";

const StyledWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: ${widthWrapperMobile};
  max-width: ${widthWrapperMobile};
  min-width: ${widthWrapperMobile};

  @media (min-width: ${(props) => props.theme.screenWidthMd}) {
    flex: 1 1 auto;
  }
`;

const StyledFixtureInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledFixtureInfoUpper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2px;
`;

const StyledEndBlock = styled.div`
  width: 54px;
  flex: 0 0 54px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledKitImage = styled.img`
  height: 22px;
  margin-bottom: 2px;
`;

const StyledIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.25rem;
  width: 24px;
  height: 24px;
  background: ${(p) =>
    p.isActive ? "transparent" : p.isSuccess ? p.theme.colorSuccess : null};
`;

const StyledLockIcon = styled(MatIcon)`
  font-size: ${(p) => (p.isSuccess && !p.isActive ? "16px" : "20px")};
  color: ${(p) =>
    p.isActive
      ? p.theme.colorDarkText
      : p.isSuccess
      ? p.theme.colorSuccessText
      : p.theme.colorText};
`;

const StyledClub = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 3px;
`;

const StyledClubName = styled(ClubName)`
  width: ${widthClub};
  max-width: ${widthClub};
  min-width: ${widthClub};
  text-align: center;
  font-size: 0.75rem;
  color: ${(p) => (p.isActive ? p.theme.colorDarkText : p.theme.colorText)};
`;

const StyledVs = styled.span`
  font-size: 0.875rem;
  width: ${widthVs};
  max-width: ${widthVs};
  min-width: ${widthVs};
  text-align: center;
  color: ${(p) => (p.isActive ? p.theme.colorDarkText : p.theme.colorText)};
`;

const Wrapper = ({ pickem, isActive, showLogos }) => {
  const isApproved = pickem?.status === "APPROVED";

  const prize = pickem?.activeBet?.prize || pickem?.activeBet?.promoPrize;

  return (
    <StyledWrapper>
      <StyledEndBlock>
        {!isApproved ? (
          <StyledIconWrapper isActive={isActive} isSuccess={prize}>
            <StyledLockIcon isActive={isActive} isSuccess={prize}>
              {prize
                ? "emoji_events"
                : pickem?.activeBet
                ? "cancel"
                : "horizontal_rule"}
            </StyledLockIcon>
          </StyledIconWrapper>
        ) : (
          <StyledLockIcon isActive={isActive}>
            {pickem?.activeBet ? "lock" : "horizontal_rule"}
          </StyledLockIcon>
        )}
      </StyledEndBlock>

      <StyledFixtureInfo>
        <StyledFixtureInfoUpper>
          <StyledClub>
            {showLogos && (
              <StyledKitImage
                src={
                  pickem?.sportMatch?.homeTeam?.logo ||
                  createImageCdnLink(process.env.REACT_APP_IMAGE_BLANK_LOGO)
                }
              />
            )}
            <StyledClubName
              isActive={isActive}
              isLetters
              isNormalLettersStyle={
                process.env.REACT_APP_IS_NORMAL_CLUB_LETTERS_STYLE === "true"
              }
            >
              {pickem?.sportMatch?.homeTeam?.name}
            </StyledClubName>
          </StyledClub>

          <StyledVs isActive={isActive}>vs</StyledVs>
          <StyledClub>
            {showLogos && (
              <StyledKitImage
                src={
                  pickem?.sportMatch?.awayTeam?.logo ||
                  createImageCdnLink(process.env.REACT_APP_IMAGE_BLANK_LOGO)
                }
              />
            )}
            <StyledClubName
              isActive={isActive}
              isLetters
              isNormalLettersStyle={
                process.env.REACT_APP_IS_NORMAL_CLUB_LETTERS_STYLE === "true"
              }
            >
              {pickem?.sportMatch?.awayTeam?.name}
            </StyledClubName>
          </StyledClub>
        </StyledFixtureInfoUpper>
      </StyledFixtureInfo>
    </StyledWrapper>
  );
};

export default Wrapper;
