import React from "react";
import styled from "styled-components";
import { BlockButton } from "components/shared";

const StyledWrapper = styled.div`
  background: ${(props) => props.theme.colorDark};
  color: white;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: ${(props) => (props.isVisible ? "100px" : "0")};
  transition: height 0.25s;
`;

const StyledInner = styled.div`
  padding: 1rem 1rem;
  font-size: 0.875rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledText = styled.div`
  flex: 1 1 auto;
  font-weight: 400;
  font-size: 0.675rem;
  line-height: 1.45;
  margin-left: 1rem;

  @media (min-width: ${(props) => props.theme.screenWidthMd}) {
    font-size: 0.75rem;
  }
`;

const StyledButton = styled.div`
  flex: 1 1 auto;
  margin-left: 1rem;
`;

const LandingGeoMessage = ({ isVisible, isWaiting, geoError }) => {
  return (
    <StyledWrapper isVisible={isVisible}>
      <StyledInner>
        <StyledText>
          {geoError && <>{geoError}</>}
          {!geoError && (
            <>
              Waiting for you to allow location. <br /> You must be inside the
              UK to play.
            </>
          )}
        </StyledText>
        <StyledButton>
          <BlockButton onClick={() => window.location.reload()}>
            Try Prompt Again
          </BlockButton>
        </StyledButton>
      </StyledInner>
    </StyledWrapper>
  );
};

export default LandingGeoMessage;
