import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import { createImageCdnLink } from "utils";
import { LegalLogos } from "../";

const sharedStyles = css`
  display: inline-block;
  padding: 0.75rem 0;
  color: ${(props) => props.theme.colorLabel};
  font-size: 0.75rem;
  width: 50%;
  text-decoration: underline;
  text-align: center;

  ${(props) =>
    props.isSmall &&
    "font-size: 0.625rem; font-weight: 400; width: 80%; line-height: 1.75;"};

  ${(props) =>
    props.isLarge && "font-size: 1rem; width: 80%; line-height: 1.75;"};
`;

const StyledButton = styled.button`
  ${sharedStyles};
`;

const StyledLink = styled.a`
  ${sharedStyles};
`;

const StyledWrapper = styled.div`
  text-align: center;
  padding-top: 2rem;
  ${"" /* ${(props) => !props.isDark && "padding-top: 2rem"}; */}

  ${(props) =>
    props.isDark &&
    `& ${StyledLink} { color: ${props.theme.colorPrimaryText}; }`};

  ${(props) =>
    props.isDark &&
    `& ${StyledButton} { color: ${props.theme.colorPrimaryText}; }`};
`;

const StyledLegalLogos = styled.div`
  margin: 0 10% 1rem;
`;

const StyledLinkList = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 0 1rem;
`;

const LegalFooter = ({ isDark, getPage }) => {
  const footerImage = isDark
    ? createImageCdnLink(process.env.REACT_APP_LEGAL_LOGOS)
    : createImageCdnLink(process.env.REACT_APP_LEGAL_LOGOS_GREY);

  return (
    <StyledWrapper isDark={isDark || process.env.REACT_APP_BACKGROUND_IMAGE}>
      <StyledLegalLogos>
        <LegalLogos isDark={isDark || process.env.REACT_APP_BACKGROUND_IMAGE} />
      </StyledLegalLogos>
      {/* <StyledLegalLogos src={footerImage} /> */}
      <StyledLinkList>
        <StyledButton
          onClick={() => getPage("privacy", "Privacy & Cookies", false)}
        >
          Privacy & Cookies
        </StyledButton>
        <StyledButton
          onClick={() =>
            getPage("responsible-gambling", "Responsible Gambling", false)
          }
        >
          Responsible Gambling
        </StyledButton>
        <StyledButton
          onClick={() => getPage("terms", "Terms & Conditions", false)}
        >
          Terms & Conditions
        </StyledButton>
        <StyledButton
          onClick={() => getPage("gambling-support", "Gambling Support", false)}
        >
          Gambling Support
        </StyledButton>
      </StyledLinkList>
      <StyledLink
        href="https://beta.gamblingcommission.gov.uk/public-register/business/detail/52153"
        target="_blank"
        isSmall
      >
        {process.env.REACT_APP_TITLE} is licensed and regulated under Low6
        Limited by the Gambling Commission (licence number 52153).
      </StyledLink>
      <StyledLink href="mailto:info@low6.co.uk" isLarge>
        Contact Support
      </StyledLink>
    </StyledWrapper>
  );
};

LegalFooter.defaultProps = {
  isDark: false,
};

LegalFooter.propTypes = {
  isDark: PropTypes.bool,
};

export default LegalFooter;
