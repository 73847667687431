import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const StyledInput = styled.select`
  width: 100%;
  height: ${(props) => (props.isSmall ? "60px" : "70px")};
  font-size: ${(props) => (props.isSmall ? "0.875rem" : "1rem")};
  border: 0;
  border-bottom: 2px solid ${(props) => props.theme.colorBorder};
  background: transparent;
  -webkit-appearance: none;
  -webkit-border-radius: 0px;
  line-height: 1.5;

  &:focus {
    border-color: ${(props) => props.theme.colorAccent};
  }

  &::placeholder {
    color: ${(props) => props.theme.colorLabel};
  }

  ${(props) =>
    (props.isError || props.isRequired) &&
    props.value === "" &&
    `border-color: ${props.theme.colorDanger} !important;`};
`;

const FormSelect = ({
  children,
  name,
  value,
  placeholder,
  onChange,
  onFocus,
  onBlur,
  isSmall,
  isError,
  isRequired,
}) => (
  <StyledInput
    value={value}
    name={name}
    onChange={onChange}
    onFocus={onFocus}
    onBlur={onBlur}
    placeholder={placeholder}
    isSmall={isSmall}
    isError={isError}
    isRequired={isRequired}
  >
    {children}
  </StyledInput>
);

FormSelect.defaultProps = {
  isSmall: false,
  placeholder: "Test",
};

FormSelect.propTypes = {
  placeholder: PropTypes.string,
  isSmall: PropTypes.bool,
};

export default FormSelect;
