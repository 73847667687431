import React from "react";
import styled, { css } from "styled-components";

const dangerAlert = css`
  border-radius: ${(p) => p.theme.borderRadius};
  background: ${(p) => p.theme.colorAlertDangerBackground};
  color: ${(p) => p.theme.colorAlertDangerText};
`;

const StyledAlert = styled.div`
  background: ${(p) => p.theme.colorAlertSuccessBackground};
  color: ${(p) => p.theme.colorAlertSuccessText};
  border-radius: ${(p) => p.theme.borderRadius};
  padding: 1rem 2rem;
  font-size: 0.75rem;
  line-height: 1.65;
  text-align: ${(p) => p.textAlign || "center"};
  margin: ${(props) => props.margin || 0};

  ${(p) => p.isDanger && dangerAlert};
`;

const Alert = ({ children, margin, isDanger, textAlign, ...rest }) => (
  <StyledAlert
    margin={margin}
    isDanger={isDanger}
    textAlign={textAlign}
    {...rest}
  >
    {children}
  </StyledAlert>
);

export default Alert;
