import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { createImageCdnLink } from "utils";

const StyledIcon = styled.img`
  width: ${(props) => props.size};
  height: ${(props) => props.size};
`;

const AppIcon = ({ size }) => (
  <StyledIcon
    size={size}
    src={createImageCdnLink(process.env.REACT_APP_MAIN_ICON)}
  />
);

AppIcon.defaultProps = {
  size: "56px",
};

AppIcon.propTypes = {
  size: PropTypes.string,
};

export default AppIcon;
