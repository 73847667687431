import React from "react";
import styled from "styled-components";
import { Skeleton } from "../";

const StyledKickoff = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${(p) => p.justifyContent || "center"};
  height: 56px;
  margin: ${process.env.REACT_APP_PRIZE_DRAW ? 0 : "0 0 1rem"};
  font-size: 0.875rem;
  color: ${(props) => props.theme.colorLabel};
  background: white;
`;

const ContentTopbar = ({ children, justifyContent }) => (
  <StyledKickoff justifyContent={justifyContent}>
    {children ? children : <Skeleton />}
  </StyledKickoff>
);

export default ContentTopbar;
