import {
  SELECT_PICKEM,
  SET_LEADERBOARD,
  CLEAR_LEADERBOARD,
  SET_LOADING,
  GET_CURRENT_SCORE,
} from "./actions";

const initialState = {
  pickemList: [],
  activeBet: undefined,
  activeBetId: undefined,
  activeUserBet: undefined,
  myPicksLoading: false,
  table: undefined,
  loading: true,
  hasMore: false,
  error: false,
  isShowingMyBet: undefined,
  isLeaderboardEmpty: undefined,
  isPickemEmpty: undefined,
  activePickem: undefined,
  activePickemId: undefined,
  activePickemStatus: undefined,
  currentMatch: undefined,
  hasGoalScored: false,
  hasGoalDisallowed: false,
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case SET_LEADERBOARD:
      return {
        ...state,
        ...payload,
      };
    case SET_LOADING:
      return {
        ...state,
        loading: payload,
      };
    case SELECT_PICKEM:
      return {
        ...state,
        activePickem: payload,
        activePickemId: payload?.id,
      };
    case CLEAR_LEADERBOARD:
      return {
        ...state,
        activeBet: undefined,
        activeBetId: undefined,
        activeUserBet: undefined,
        activePickem: undefined,
        myPicksLoading: false,
        filterByMe: false,
        filteredTable: [],
        table: undefined,
        isLeaderboardEmpty: undefined,
        isShowingMyBet: undefined,
        hasMore: false,
        error: false,
        hadBet: undefined,
        canBet: undefined,
        activePickemId: undefined,
        activePickemStatus: undefined,
      };
    default:
      return state;
  }
}
