import styled from "styled-components";
import LeaderboardRowHeader from "./shared/LeaderboardRowHeader";
import LeaderboardRowContent from "./shared/LeaderboardRowContent";
import LeaderboardRowContentEvent from "./shared/LeaderboardRowContentEvent";

const StyledWrapper = styled.div`
  background: #fdfdfd;
  border-bottom: 3px solid #f4f4f4;
`;

const Wrapper = ({
  row,
  isActive,
  isShowingMyBet,
  isPreMatchLeaderboard,
  onRowClicked,
}) => {
  return (
    <StyledWrapper>
      {!isShowingMyBet && (
        <LeaderboardRowHeader
          isPreMatchLeaderboard={isPreMatchLeaderboard}
          isActive={isActive}
          row={row}
          onRowClicked={onRowClicked}
        />
      )}
      {isActive && process.env.REACT_APP_IS_EUROTOUR === "true" ? (
        <LeaderboardRowContentEvent row={row} />
      ) : isActive ? (
        <LeaderboardRowContent row={row} />
      ) : null}
    </StyledWrapper>
  );
};

export default Wrapper;
